import React, { useEffect } from 'react';
import { ExtendedContentContainer, ExtendedContentType } from 'components/ExtendedContent/ExtendedContentContainer';
import VolumeOneWords from 'components/VolumeOneWords';
import { WordLearningContentWrapper } from 'components/WordLearningGrid/WordLearningContent';
import WordContentList from 'components/WordContentList';
import { ExtensionType } from 'constants/wordGrid';
import { useStore, StoreTypes } from 'context';
import * as actionTypes from 'constants/actionTypes';
import classnames from 'classnames'
import styles from './index.module.scss';

const convertIdiomResource = data =>
  Object.entries(data).map(([key, item]) => ({ label: item.label, value: item.relatedWords[0] }))

const MeaningContent = ({ data, list }) => {
  const [{
    word,
    extensionType
  }, wordGridDispatch] = useStore(StoreTypes.wordGrid);

  const onListChangeHandler = ({ word, relatedWords }) => {
    wordGridDispatch({
      type: actionTypes.SET_SUBCONTENT_SHOW,
      payload: {
        isShowSubContent: true,
        extensionType
      }
    })


    word && wordGridDispatch({
      type: actionTypes.SET_WORDS_VALUE,
      payload: {
        word
      }
    })

    wordGridDispatch({
      type: actionTypes.SET_RELATED_WORDS,
      payload: {
        relatedWords
      }
    })

  }

  useEffect(() => {
    const current = list.filter(item => item.value === word);
    if (current.length > 0) {
      onListChangeHandler({ relatedWords: current[0].relatedWords, word })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={classnames(styles.meaningContentWrapper, styles.zhuyin)}>
      <WordLearningContentWrapper data={data} />
      <WordContentList
        zhuyin
        word={word}
        className={styles.wordContentList}
        data={list.map(item => {
          return {
            label: item.label,
            value: item.value,
            relatedWords: [item.value]
          }
        })}
        onChange={onListChangeHandler}
      />
    </div>
  )
}


const WordLearningGridContainer = ({
  wordGridResource,
  defaultWord,
  wordsResource
}) => {
  const [{
    isShowSubContent,
    extensionType,
    extensionData,
  }, wordGridDispatch] = useStore(StoreTypes.wordGrid);


  const subContentCloseHandler = () => {
    wordGridDispatch({ type: actionTypes.SET_SUBCONTENT_SHOW, payload: { isShowSubContent: false } })
  }

  useEffect(() => {
    wordGridDispatch({
      type: actionTypes.SET_WORDS_VALUE,
      payload: {
        word: defaultWord
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <ExtendedContentContainer extendedContentType={ExtendedContentType.WordGrid}>
        <VolumeOneWords dataList={wordGridResource} defaultWord={defaultWord} />
      </ExtendedContentContainer>
      {
        isShowSubContent && (
          <ExtendedContentContainer
            extendedContentType={ExtendedContentType.WordGridSubContent}
            onClose={subContentCloseHandler}
          >
            <div className={styles.wordGridConentContainer} style={{ backgroundImage: 'url(assets/img/wordGridBg.jpg)' }}>
              {
                extensionType === ExtensionType.WordsSet && (
                  <MeaningContent data={extensionData[ExtensionType.WordsSet]} list={convertIdiomResource(wordsResource)} />
                )
              }
            </div>
          </ExtendedContentContainer>
        )
      }
    </>
  )
}

export const VolumeOneWordsModalComponent = ({
  wordGridResource,
  defaultWord,
  wordsResource
}) => {
  return (
    <WordLearningGridContainer
      wordGridResource={wordGridResource}
      defaultWord={defaultWord}
      wordsResource={wordsResource}
    />
  )
};
