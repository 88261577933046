/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect } from 'react';
import styles from './index.module.scss';
import Icon from 'components/Icon';
import * as actionTypes from 'constants/actionTypes';
import { EventBus } from 'events/EventBus';
import { UserEvent } from 'events/EventTypes';
import useSetState from 'customHooks/setState';
import { useStore, StoreTypes } from 'context';
import AvailableLanguages from 'constants/languages.json';
import { LANGUAGES, LANGUAGES_DISPLAY } from 'constants/languages';
import { LOGIN_POPUP, LOGIN_BTN } from 'constants/loginTypes';
import classnames from 'classnames';
import { useUpdateUserSettings } from 'customHooks/userSetting';

import { useTranslation } from 'react-i18next';
import Repository from 'repositories/Repository';
// import { useEffect } from '@storybook/addons';
const { BookshelfRepository } = Repository;

const menus = [
  {
    url: "http://mall.oneclass.com.tw/",
    title: "電子書城"
  },
  {
    url: "/",
    title: "我的書櫃"
  }
];

const Header = () => {
  let [{ isLogin, name }] = useStore(StoreTypes.user);
  const [{ loginBtn }, loginDispatch] = useStore(StoreTypes.login);
  const [displayLanguageMenu, setDisplayLanguageMenu] = useState(false);
  const { i18n } = useTranslation();
  const updateUserSettings = useUpdateUserSettings();
  const [{ headerMenuVisibility }, setState] = useSetState({ headerMenuVisibility: false });

  const showName = useCallback((_name) => {
    const bodyWidth = document.body.clientWidth;
    if (bodyWidth < 768) {
      return _name.slice(0, 1);
    } else {
      return _name + '▼';
    }
  }, []);

  useEffect(() => {
    (async () => {
      const headerMenuVisibility = await BookshelfRepository.getHeaderMenuVisibility()
      setState({ headerMenuVisibility })
    })()
  }, [])

  return (
    <div>
      <header>
        <div className={styles.top}>
          <div className={styles.container}>
            <div className={styles.logoBox}>
              <img className="logo" src="img/logo.png" alt="onebook" />
            </div>
            <div className={styles.linkBox}>
              {
                headerMenuVisibility && menus.map((list, index) => {
                  return (
                    <div className={styles.link} key={index}><a href={list.url} target="_blank" rel="noopener noreferrer">{list.title}</a></div>
                  );
                })
              }
            </div>
            <div className={styles.funBox}>
              {/* <div className={styles.search_box}>
                                <input className={styles.search} type="text" placeholder="Search…" />
                            </div> */}
              <div className={styles.iconBox}>
                {/* <div className={styles.book}></div> */}
                {/* <div className={styles.member}></div> */}
                {/* {<div className={styles.login} onClick={() => {
                                    EventBus.emit({ event: UserEvent.LoginEvent });
                                }}>{isLogin ? "登出" : "登入"}</div>} */}

                {
                  !window.electron && <div className={styles.loginBox}>
                    <div className={styles.dropdownBox}>
                      <div className={styles.loginInfo} onClick={e => {
                        if (isLogin) {
                          loginDispatch({ type: LOGIN_BTN, loginBtn: !loginBtn })
                        } else {
                          loginDispatch({ type: LOGIN_POPUP, popupState: true })
                        }
                      }}>
                        <span className={isLogin ? styles.show : ''}>{isLogin ? `${showName(name)}` : `登入`}</span>
                        {isLogin ? '' : <Icon type="text" name="user" />}
                      </div>
                      <div className={classnames(styles.dropdownMenu, styles[loginBtn ? 'active' : ''])} >
                        <div className={styles.dropdownItem} onClick={() => {
                          window.updatePersonalDataNaniOneClass();
                        }}>修改資料</div>
                        <div className={styles.dropdownItem} onClick={() => {
                          loginDispatch({ type: LOGIN_BTN, loginBtn: false })
                          EventBus.emit({ event: UserEvent.LoginEvent });
                        }}>登出</div>
                        <div className={`${styles.dropdownItem} ${styles.active}`}>Version: {process.env.REACT_APP_VERSION || '0.0.1'}</div>
                      </div>
                    </div>
                  </div>
                }
                {process.env.REACT_APP_SUPPORT_MULTI_LANGUAGES === 'true' &&
                  <div className={styles.loginBox}>
                    <div className={styles.dropdownBox}>
                      <div className={styles.languageInfo} onClick={e => {
                        setDisplayLanguageMenu(!displayLanguageMenu);
                      }}>
                        <span className={displayLanguageMenu ? styles.show : ''}>{`${LANGUAGES_DISPLAY[i18n.language] || LANGUAGES_DISPLAY[LANGUAGES.TRADITIONAL_CHINESE]}▼`}</span>
                      </div>
                      <div className={classnames(styles.dropdownMenu, styles[displayLanguageMenu ? 'active' : ''])} >
                        {Object.values(LANGUAGES).filter(languageKey => AvailableLanguages.includes(languageKey)).map((languageKey, i) => {
                          return (
                            <div key={i} className={styles.dropdownItem} onClick={() => {
                              i18n.changeLanguage(languageKey)
                                .then(() => setDisplayLanguageMenu(false));
                              updateUserSettings({ language: languageKey });
                            }}>{LANGUAGES_DISPLAY[languageKey]}</div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
        <div className={styles['version-info']}>Version: {process.env.REACT_APP_VERSION || '0.0.1'}</div>

        {/* {
          !window.electron && <div className={styles.bottom}>
            <div className={styles.container}>
              <ul className={styles.list_box}>
                {menus.map(list => {
                  return (
                    <li className={styles.list}><a href={list.url} target="_blank" rel="noopener noreferrer">{list.title}</a></li>
                  );
                })}
              </ul>
              <div className={styles.sopping_car}>
                            <div className={styles.car}></div>
                        </div>
            </div>
          </div>
        } */}
      </header >
      <div className={styles.mat}></div>
    </div>
  );
}
export default Header;
