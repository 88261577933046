export const oneClassFirebaseConfig = {
    appName:"oneclass",
    apiKey: process.env.REACT_APP_ONECLASS_API_KEY,
    authDomain: process.env.REACT_APP_ONECLASS_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_ONECLASS_DATABASE_URL,
    projectId: process.env.REACT_APP_ONECLASS_PROJECT_ID,
    storageBucket: process.env.REACT_APP_ONECLASS_STORAGE_BUCKET
};


export const oneTutorFirebaseConfig = {
    appName:"onetutor",
    apiKey: process.env.REACT_APP_ONE_TUTOR_API_KEY,
    authDomain: process.env.REACT_APP_ONE_TUTOR_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_ONE_TUTOR_DATABASE_URL,
    projectId: process.env.REACT_APP_ONE_TUTOR_PROJECT_ID,
    storageBucket: process.env.REACT_APP_ONE_TUTOR_STORAGE_BUCKET
};
