import { useCallback } from 'react';
import { useStore, StoreTypes } from 'context';
import { ReaderToolType } from 'constants/ReaderTools';
import { BaseReaderStrategy } from './BaseReaderStrategy';
import { useSyncClassPreparationDispatcher } from 'customHooks/syncClassPreparation';
import { InteractiveObjectContentType } from 'constants/interactiveObjectContentTypes';

const Blacklist = [
    ReaderToolType.Expression,
    ReaderToolType.SVG,
    ReaderToolType.Activity,
    ReaderToolType.OpenActivity,
    ReaderToolType.ExportInteractiveObject
];

const InteractiveObjectTriggerableWhitelist = [
    InteractiveObjectContentType.Video,
    InteractiveObjectContentType.Audio,
    InteractiveObjectContentType.GoPage,
    InteractiveObjectContentType.WordGrid,
    InteractiveObjectContentType.WordExample,
    InteractiveObjectContentType.WordGlyph,
    InteractiveObjectContentType.WordMeaning,
    InteractiveObjectContentType.Submenu
];

export const useParentStrategy = () => {
    const [{ isActive }] = useStore(StoreTypes.canvas);
    const { syncClassPreparation } = useSyncClassPreparationDispatcher();

    const getReaderToolComponents = useCallback((readerToolComponents) => {
        return BaseReaderStrategy.getFilteredReaderToolComponents(readerToolComponents, Blacklist);
    }, []);

    const isReaderSwipeable = useCallback(() => {
        return !isActive;
    }, [isActive]);

    const isReaderSwitchPageAvailable = useCallback(() => {
        return true;
    }, []);

    const syncAnnotation = useCallback((id) => {
        syncClassPreparation(id);
    }, [syncClassPreparation]);

    const isInteractiveObjectTriggerable = useCallback(({ contentType }) => {
        return InteractiveObjectTriggerableWhitelist.some((type) => type === contentType);
    }, []);

    return {
        name: 'ParentStrategy',
        getReaderToolComponents,
        isReaderSwipeable,
        isReaderSwitchPageAvailable,
        syncAnnotation,
        isInteractiveObjectTriggerable
    };
};
