import { useEnhanceReducer } from 'customHooks/enhanceReducer';
import * as types from 'constants/actionTypes';
import { LANGUAGES } from 'constants/languages';
import { SubtitlesPositionType, SubtitlesFontSizeType } from 'constants/videoSettingType';
import { DefaultReaderTools } from 'constants/ReaderTools';


export const initState = {
    language: LANGUAGES.TRADITIONAL_CHINESE,
    videoSetting: {
        subtitlesPosition: SubtitlesPositionType.DOWN,
        playbackRate: 1,
        subtitlesFontSize: SubtitlesFontSizeType.MEDIUM
    },
    audioSetting: {
        times: 1,
        timeGap: 2000,
        speed: 1,
        usingDefault: true
    },
    readerToolSetting: DefaultReaderTools
};

const updateUserSettings = (state, action) => {
    const {
        language = state.language,
        videoSetting = state.videoSetting,
        audioSetting = state.audioSetting,
        readerToolSetting = state.readerToolSetting
    } = action;
    return { ...state, language, videoSetting, audioSetting, readerToolSetting };
};

const userSettingsReducer = (state, action) => {
    switch (action.type) {
        case types.UPDATE_USER_SETTINGS:
            return updateUserSettings(state, action);
        default:
            return state;
    }
};

const useUserSettingsReducer = () => useEnhanceReducer(userSettingsReducer, initState);

export default useUserSettingsReducer;
