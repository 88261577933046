import React, { useEffect, useCallback, useLayoutEffect } from 'react';
import { useVideo } from 'customHooks/mediaElement';
import RadicalInfoBar from 'components/WordLearningGrid/RadicalInfoBar';
import WordsVideoControl from 'components/WordLearningGrid/WordsVideoControl';
import WordsMovieButtonGroup from 'components/WordLearningGrid/WordsMovieButtonGroup';
import WordsMeaningButtonGroup from 'components/WordLearningGrid/WordsMeaningButtonGroup';
import AudioButton from 'components/common/AudioButton';
import GridButtonGroup from 'components/WordLearningGrid/GridButtonGroup';
import Icon from 'components/Icon';
import useSetState from 'customHooks/setState';
import { mediaType } from 'constants/mediaType';
import { useStore, StoreTypes } from 'context';
import { EventBus, EventBusType } from 'events/EventBus';
import { ExtendedContentEvent, ReaderToolsEvent } from 'events/EventTypes';
import { LineType } from 'constants/wordGrid';
import classnames from 'classnames';
import { PinchPanContainer } from 'components/PinchPanContainer';
import * as actionTypes from 'constants/actionTypes';
import styles from 'components/WordLearningGrid/index.module.scss';
import volumeOneWordStyles from './index.module.scss';
import Repository from 'repositories/Repository';
const { ExtendedResourceRepository } = Repository;

const videoPointSetControlStateType = {
  PLAY: 'play',
  PAUSE: 'pause',
  NEXT: 'next',
  REPEAT: 'repeat',
  PREVIOUS: 'previous'
};

const EXTENDTEACH_WORDING_MAP = {
  wordsSet: '語詞教學'
};

const MOVIE_BUTTON_WORDING_MAP = ['筆順教學', '書寫提醒', '發音示範', '發音技巧']

const VolumeOneWordsComponent = ({
  data,
  wordIndex,
  goToNextWordIndex,
  isPausePlayAllWords,
  setPausePlayAllWords
}) => {
  const [{ bookId }] = useStore(StoreTypes.books);
  const [{ isShowSubContent }, wordGridDispatch] = useStore(StoreTypes.wordGrid);
  const [{ wordLearningGrid: {
    playbackRate
  } }] = useStore(StoreTypes.extendedContent);
  const [
    {
      Name: word,
      RadicalNumber: radicalNumber,
      AllNumber: allNumber,
      RestNumber: restNumber,
      WordMovieSet,
      WordsSet,
    },
    setDataState
  ] = useSetState(data);

  const [
    {
      videoAutoPlay,
      videoSrc,
      videoPointSet,
      currentPointSetIndex,
      currentVideoIndex,
      currentWordsMeaningIndex,
      isZoomVideo,
      isPlayAllWords,
      gridValue,
      isSwitching,
      videoPointSetControlState,
    },
    setState
  ] = useSetState({
    videoAutoPlay: false,
    videoSrc: WordMovieSet[0].Path || '',
    videoPointSet: WordMovieSet[0].MoviePointSet || [],
    currentPointSetIndex: 0,
    currentVideoIndex: 0,
    currentWordsMeaningIndex: null,
    isZoomVideo: false,
    isPlayAllWords: false,
    gridValue: LineType.Dotted,
    isSwitching: false,
    videoPointSetControlState: videoPointSetControlStateType.PAUSE,
  });

  const {
    element: video,
    state,
    controls
  } = useVideo(
    <video
      src={`${ExtendedResourceRepository.getDataSrc({
        bookId,
        pathName: videoSrc
      })}`}
      autoPlay={videoAutoPlay}
      playsInline
      crossOrigin="anonymous"
    />
  );

  const repeatButtonClickHandler = () => {
    setState({ videoPointSetControlState: videoPointSetControlStateType.REPEAT });
    const lastPoint = Math.max(currentPointSetIndex - 1, 0);
    controls.seek(videoPointSet[lastPoint]);
    controls.play();
  }

  // for 單筆重複
  useEffect(() => {
    if (videoPointSetControlState !== videoPointSetControlStateType.REPEAT) return;
    const endPointTime = videoPointSet[currentPointSetIndex];
    if (state.time >= endPointTime) {
      controls.pause();
    }
  }, [controls, currentPointSetIndex, setState, state.time, videoPointSet, videoPointSetControlState])


  useLayoutEffect(() => {
    // 需求為 延遲播放不跟音檔重疊
    setTimeout(() => {
      !isShowSubContent && !isPausePlayAllWords && isPlayAllWords && controls.play();
      setPausePlayAllWords(false)
    }, 2000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controls, isPlayAllWords, videoSrc]);

  // for 上下一筆的播放
  useEffect(() => {
    if (
      videoPointSetControlState !== videoPointSetControlStateType.NEXT &&
      videoPointSetControlState !== videoPointSetControlStateType.PREVIOUS
    ) return;
    const endPointTime = videoPointSet[currentPointSetIndex];
    if (state.isPlaying && state.time >= endPointTime) {
      controls.pause();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.time,
    state.isPlaying,
    currentPointSetIndex,
    videoPointSetControlState,
    videoPointSet
  ]);

  // 隨著播放時間 更新 point 的 index
  useEffect(() => {
    // for normal play
    if (
      videoPointSet.length < 1 ||
      !videoPointSetControlState ||
      (videoPointSetControlState !== videoPointSetControlStateType.PLAY &&
        videoPointSetControlState !== videoPointSetControlStateType.PAUSE)
    ) return;
    let pointIndex = currentPointSetIndex;
    let pointTime = videoPointSet[currentPointSetIndex];
    if (state.isPlaying && state.time >= pointTime) {
      pointIndex = Math.min(pointIndex + 1, videoPointSet.length - 1);
      setState({
        currentPointSetIndex: pointIndex,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.time,
    state.isPlaying,
    currentPointSetIndex,
    videoPointSet,
    videoPointSetControlState,
    setState
  ]);

  // 自動播放下一個字
  useEffect(() => {
    if (state.buffered.length < 1) return;
    if (state.time >= state.buffered[0].end && isPlayAllWords && !isSwitching) {
      setState({ isSwitching: true });
      goToNextWordIndex();
      // TODO: should implement useVideo onload listener to handle this
      setTimeout(() => {
        setState({ isSwitching: false });
      }, 500);
    }
  }, [
    state.time,
    state.buffered,
    wordIndex,
    isPlayAllWords,
    goToNextWordIndex,
    isSwitching,
    setState
  ]);

  const wordMovieButtonHandler = ({ index, value }) => {
    setState({
      videoAutoPlay: false,
      videoSrc: value,
      currentVideoIndex: index,
      videoPointSet: WordMovieSet[index].MoviePointSet,
      currentPointSetIndex: 0,
      videoPointSetControlState: videoPointSetControlStateType.PLAY
    });
  };

  const meaningButtonHandler = useCallback(({ type }) => {
    controls.pause();
    wordGridDispatch({
      type: actionTypes.SET_SUBCONTENT_SHOW,
      payload: {
        isShowSubContent: true,
        extensionType: type
      }
    })

  }, [controls, wordGridDispatch]);

  const pointSetChangeHandler = useCallback(
    type => {
      let pointIndex = currentPointSetIndex;
      if (type === mediaType.PREVIOUS) {
        pointIndex = Math.max(pointIndex - 1, 0);
      } else {
        pointIndex = (pointIndex + 1 >= videoPointSet.length) ? 0 : pointIndex + 1;
      }

      controls.seek(videoPointSet[Math.max(pointIndex - 1, 0)]);
      controls.play();
      setState({
        currentPointSetIndex: pointIndex,
        videoPointSetControlState: type === mediaType.PREVIOUS ? videoPointSetControlStateType.PREVIOUS : videoPointSetControlStateType.NEXT
      });
    },
    [currentPointSetIndex, controls, videoPointSet, setState]
  );

  const playbackRateChangeHandler = playbackRate => {
    EventBus.emit({
      event: ExtendedContentEvent.SetWordLearningGridPlaybackRateEvent,
      payload: { playbackRate }
    })
  }

  useEffect(() => {
    controls.playbackRate(playbackRate);
  }, [controls, playbackRate, videoSrc])

  const play = useCallback(() => {
    state.isPlaying ? controls.pause() : controls.play();
    setState({ videoPointSetControlState: videoPointSetControlStateType[state.isPlaying ? 'PLAY' : 'PAUSE'] });
  }, [controls, setState, state.isPlaying]);

  useEffect(() => {
    const extensionData = {
      wordsSet: data.WordsSet
    };
    setDataState(data);
    setState({
      videoAutoPlay: false,
      videoSrc: WordMovieSet[currentVideoIndex].Path || '',
      videoPointSet: WordMovieSet[currentVideoIndex].MoviePointSet || [],
      currentPointSetIndex: 0,
      currentVideoIndex,
      videoPointSetControlState: videoPointSetControlStateType.PAUSE,
      audioSrc: '',
      currentWordsMeaningIndex
    });
    wordGridDispatch({
      type: actionTypes.SET_EXTENSION_DATA,
      payload: {
        extensionData
      }
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [WordMovieSet, setState, data, setDataState, isPlayAllWords, wordIndex]);

  const onPlayAllWordsClick = status => {
    setState({ isPlayAllWords: status });
  };

  const toggleZoomVideoHandler = useCallback(() => {
    controls.pause();
    controls.seek(0);
    setState({ isZoomVideo: !isZoomVideo })
  }, [controls, isZoomVideo, setState])


  const isNoGridLine = currentVideoIndex > 1;

  return (
    <>
      <div className={classnames(styles.center, styles.images)}>
        <div className={classnames(styles.videoContainer, {
          [styles.fullSize]: isZoomVideo
        })}>
          <div className={classnames(styles.grid, volumeOneWordStyles.grid)}>
            <div className={classnames(styles.video, [styles[gridValue]], {
              [styles.noGrid]: isNoGridLine
            }, styles.volumeOneWord)}>
              {video}
              <div className={classnames(styles.zoomIcon, volumeOneWordStyles.zoomIcon)} onClick={toggleZoomVideoHandler}>
                <Icon type="text" name={isZoomVideo ? 'searchMinus' : 'searchPlus'} />
              </div>
            </div>
          </div>
        </div>
        {
          !isZoomVideo && (
            <RadicalInfoBar
              className={volumeOneWordStyles.radicalInfoBar}
              radicalNumber={radicalNumber}
              allNumber={allNumber}
              restNumber={restNumber}
            />
          )
        }
      </div>
      <div className={styles.right}>
        <section>
          <div className={styles.title}>
            <span>教學方式</span>
          </div>
          <WordsMovieButtonGroup
            wordingMap={MOVIE_BUTTON_WORDING_MAP}
            data={WordMovieSet}
            activeIndex={currentVideoIndex}
            onChange={wordMovieButtonHandler}
            onPlayAllWordsClick={onPlayAllWordsClick}
          />
          <WordsVideoControl
            videoState={state}
            playButtonClick={play}
            previousButtonClick={() =>
              pointSetChangeHandler(mediaType.PREVIOUS)
            }
            previousButtonDisabled={
              currentPointSetIndex === 0 || videoPointSet.length < 1
            }
            nextButtonClick={() => pointSetChangeHandler(mediaType.NEXT)}
            nextButtonDisabled={
              videoPointSet.length < 1
            }
            repeatDisabled={state.isPlaying || currentPointSetIndex < 1}
            repeatButtonClick={() => repeatButtonClickHandler()}
            playbackRate={playbackRate}
            onChangePlaybackRate={playbackRateChangeHandler}
          />
        </section>
        <section>
          <div className={styles.title}>
            <span>延伸教學</span>
          </div>
          <WordsMeaningButtonGroup
            className={styles.extendTeach}
            onChange={meaningButtonHandler}
            wordingMap={EXTENDTEACH_WORDING_MAP}
            data={{
              wordsSet: WordsSet
            }}
          />
        </section>
        <section>
          <div className={styles.title}>
            <span>十字格線</span>
          </div>
          <GridButtonGroup noGrid={isNoGridLine} onChange={value => setState({ gridValue: value })} value={gridValue} />
        </section>
      </div>
    </>
  );
};

const VolumeOneWords = ({ dataList = [], defaultWord }) => {
  const [{ bookId }] = useStore(StoreTypes.books);
  const [{ word, isShowSubContent }, wordGridDispatch] = useStore(StoreTypes.wordGrid);
  const [
    { list, currentWordIndex, isAudioAutoPlay, isPausePlayAllWords },
    setState
  ] = useSetState({
    list: [],
    currentWordIndex: 0,
    isAudioAutoPlay: true,
    isPausePlayAllWords: false,
  });

  useEffect(() => {
    Promise.all(
      dataList.map(word =>
        ExtendedResourceRepository.getJSONContent({
          bookId,
          pathName: word.Path
        })
      )
    ).then(list => {
      setState({ list: list.filter(item => item) });
    });
  }, [bookId, dataList, setState]);


  const goToNextWordIndex = useCallback(() => {
    const maxIndex = dataList.length - 1;
    const nextIndex = currentWordIndex + 1;
    if (maxIndex >= nextIndex) {
      const wordIndex = Math.min(nextIndex, maxIndex);
      wordGridDispatch({
        type: actionTypes.SET_WORDS_VALUE,
        payload: {
          word: list[wordIndex].Name
        }
      })
    }
  }, [dataList.length, currentWordIndex, wordGridDispatch, list]);

  useEffect(() => {
    const tempWord = word || defaultWord;
    if (!tempWord) return;
    const index = dataList.findIndex(item => {
      return item.Name === tempWord;
    });
    setState({ currentWordIndex: index > -1 ? index : 0 });
  }, [dataList, setState, word, defaultWord]);

  useEffect(() => {
    const eventBusType = EventBusType.ExtendedContent;
    EventBus.emit({
      eventBusType,
      event: ReaderToolsEvent.ClickEraseAllEvent
    });
  }, [currentWordIndex, isShowSubContent])

  useEffect(() => {
    if (isShowSubContent) {
      setState({ isAudioAutoPlay: false })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowSubContent])

  const setPausePlayAllWords = status => {
    setState({ isPausePlayAllWords: status })
  }

  const wordClickHandler = word => {
    wordGridDispatch({
      type: actionTypes.SET_WORDS_VALUE,
      payload: {
        word
      }
    })
    setState({ isPausePlayAllWords: true })
  }


  return (
    <>
      <PinchPanContainer>
        <div className={styles.wordLearningGrid} style={{ backgroundImage: 'url(assets/img/wordGridBg.jpg)' }}>
          <div className={styles.left}>
            <div className={styles.wordList}>
              {dataList.map((item, index) => (
                <div
                  key={item.Name}
                  className={classnames(styles.word, {
                    [styles.active]: index === currentWordIndex,
                    [styles.optional]: !!item.Optional
                  })}
                  onClick={() => wordClickHandler(item.Name)}
                >
                  {list[index] && (
                    <AudioButton
                      autoPlay={index === currentWordIndex && isAudioAutoPlay}
                      src={`${ExtendedResourceRepository.getDataSrc({
                        bookId,
                        pathName: list[index].WordAudioPath
                      })}`}
                    >
                      {item.Name}
                    </AudioButton>
                  )}
                </div>
              ))}
            </div>
          </div>
          {list && list.length > 0 && (
            <VolumeOneWordsComponent
              data={list[currentWordIndex]}
              wordIndex={currentWordIndex}
              goToNextWordIndex={goToNextWordIndex}
              isPausePlayAllWords={isPausePlayAllWords}
              setPausePlayAllWords={setPausePlayAllWords}
            />
          )}
        </div>
      </PinchPanContainer>
    </>
  );
};

export default VolumeOneWords;
