
import React, { useContext, useEffect, useState, useCallback, useRef } from 'react';
import styles from './index.module.scss';
import ChatRoom from 'components/ChatRoom';
import classnames from 'classnames';
import DialogModule from 'components/Dialogs/'
import StudentRaiseButton from './StudentRaiseButton'
import { Roles } from 'constants/role';
import { EmojiEvent, ToolsEvent, ReaderEvent } from 'events/EventTypes';
import { EventBus } from 'events/EventBus';
import { useStore, StoreTypes } from 'context';
import { DeviceType } from 'constants/chimeTypes'
import SelectComponent from 'components/SelectComponent'
import VolumeMeter from 'components/Tools/VolumeMeter'
import Icon from 'components/Icon';
import SynchronousReader from 'components/Tools/SynchronousReader';
import * as types from 'constants/actionTypes';
import { useChimeError } from 'customHooks/chimeError'
import { useSlackWebhook } from 'customHooks/slackWebHook'
import Button from '@material-ui/core/Button';
import {
  RemoteVideo,
  LocalVideo,
  useVideoInputs,
  useAudioInputs,
  useAudioOutputs,
  useLocalVideo,
  useToggleLocalMute,
  useLocalAudioOutput,
  useRosterState,
  useMeetingManager,
  useRemoteVideoTileState,
  useSelectVideoQuality,
  useAudioVideo,
  useContentShareControls,
  Notification,
  Severity,
  useNotificationState,
  useNotificationDispatch,
  NotificationProvider,
  NotificationGroup,
  ActionType,
  SignalStrength
} from 'amazon-chime-sdk-component-library-react';

export const StudentVideo = React.memo(({ isFull }) => {
  const [attendeeId, setAttendeeId] = useState('');
  const { roster } = useRosterState();
  const { attendeeIdToTileId } = useRemoteVideoTileState();
  const audioVideo = useAudioVideo();
  const [signalStrength, setSignalStrength] = useState('');
  const [tileId, setTileId] = useState('');
  const { sendSlackMeetingIssue } = useSlackWebhook();
  let enableConnectPoorNotify = true;
  const connectPoorTimeoutSec = 180;

  const bindAttendeeCallback = (audioVideo, attendeeId) => {

    audioVideo.realtimeSubscribeToVolumeIndicator(
      attendeeId,
      (attendeeId, volume, muted, signalStrength, externalUserId) => {
        if (signalStrength === null) {
          // signalStrength has not changed
          return;
        }

        if (signalStrength == 0 && enableConnectPoorNotify) {
          enableConnectPoorNotify = false;
          setTimeout(() => {
            enableConnectPoorNotify = true;
          }, connectPoorTimeoutSec * 1000);
          sendSlackMeetingIssue(`收到${externalUserId}訊號不良資訊`);
        }

        setSignalStrength(signalStrength);
      }
    );
  }


  useEffect(() => {

    //不明原因.會吐出undefined..因此過濾掉
    if (attendeeIdToTileId[attendeeId] !== undefined)
      setTileId(attendeeIdToTileId[attendeeId]);

  }, [attendeeId, attendeeIdToTileId])

  useEffect(() => {

    if (!audioVideo || !attendeeId) return;

    bindAttendeeCallback(audioVideo, attendeeId);

  }, [attendeeId, audioVideo])


  useEffect(() => {


    const student = Object.values(roster).find(item => item.externalUserId.split("$")[1] === Roles.TUTOR_USER);

    if (student) {
      setAttendeeId(student.chimeAttendeeId)
    }

  }, [roster, audioVideo])





  return attendeeId && tileId ?
    <>
      <RemoteVideo tileId={tileId} />
      {
        !isFull && <VolumeMeter className={styles.volumeMeter} width={50} height={15} attendeeId={attendeeId} />
      }

      <SignalStrength className={classnames(styles.signal, GetSignalStrengthCssName(signalStrength))} />

    </>
    : null
})

export const StudentFullVideo = React.memo(() => {
  const [attendeeId, setAttendeeId] = useState('');
  const { roster } = useRosterState();
  const { attendeeIdToTileId } = useRemoteVideoTileState();
  const audioVideo = useAudioVideo();
  const [signalStrength, setSignalStrength] = useState('');
  const [tileId, setTileId] = useState('');
  const bindAttendeeCallback = (audioVideo, attendeeId) => {
    audioVideo.realtimeSubscribeToVolumeIndicator(
      attendeeId,
      (attendeeId, volume, muted, signalStrength, externalUserId) => {
        if (signalStrength === null) {
          // signalStrength has not changed
          return;
        }

        setSignalStrength(signalStrength);
      }
    );
  }

  useEffect(() => {

    //不明原因.會吐出undefined..因此過濾掉
    if (attendeeIdToTileId[attendeeId] !== undefined)
      setTileId(attendeeIdToTileId[attendeeId]);

  }, [attendeeId, attendeeIdToTileId])

  useEffect(() => {

    const student = Object.values(roster).find(item => item.externalUserId.split("$")[1] === Roles.TUTOR_USER);

    if (student) {
      setAttendeeId(student.chimeAttendeeId)
    }
    if (!student || !audioVideo || !student.chimeAttendeeId) {
      return;
    }
    bindAttendeeCallback(audioVideo, student.chimeAttendeeId);

  }, [roster, audioVideo])

  return attendeeId && tileId ?
    <>
      <RemoteVideo tileId={tileId} />
      {/* <SignalStrength className={classnames(styles.signal, GetSignalStrengthCssName(signalStrength))} /> */}

    </>
    : null
})


const GetSignalStrengthCssName = (signalStrength) => {
  if (signalStrength == 1)
    return styles.signalHigh;
  else if (signalStrength == 0)
    return styles.signalLow;
  else
    return styles.signalMedium
};

const StudentToolsControlList = React.memo(() => {
  const [{ toolsIsControl }] = useStore(StoreTypes.reader);
  const { roster } = useRosterState();

  const [userId, setUserId] = useState('');
  const [teacher, setTeacherId] = useState('');

  const studentToolsControl = useCallback(({ userId }) => {
    EventBus.emit({ event: ToolsEvent.ToolsIsControl, payload: { userId } })
  }, [])

  useEffect(() => {
    const student = Object.values(roster).find(item => item.externalUserId.split("$")[1] === Roles.TUTOR_USER);
    const teacher = Object.values(roster).find(item => item.externalUserId.split("$")[1] === Roles.TUTOR);

    if (student) {
      setUserId(student.externalUserId)
    }

    if (teacher) {
      setTeacherId(teacher.externalUserId)
    }

  }, [roster])

  return userId ? (
    <div
      className={styles.toolSwitch}
      onClick={() => studentToolsControl({ userId: toolsIsControl ? userId : teacher })}>
      {
        toolsIsControl ? <Icon name='OpenTools' /> : <Icon name='CloseTools' />
      }
    </div>
  ) : null
})

const LiveStreamMasterPanel = ({ isShow, channelName, clientId, userId, role }) => {

  const [{ rewardInfo, isFullVideo }, courseDispatch] = useStore(StoreTypes.course);
  const { fireworks, good, like, trophy } = rewardInfo;

  const meetingManager = useMeetingManager();

  const { isVideoEnabled, toggleVideo } = useLocalVideo();
  const { devices: videoInputDevices } = useVideoInputs();
  const selectVideoQuality = useSelectVideoQuality();
  const audioVideo = useAudioVideo();
  const { devices: audioInputDevices } = useAudioInputs();
  const { devices: audioOutputDevices } = useAudioOutputs();

  const { muted, toggleMute } = useToggleLocalMute();
  const { isAudioOn, toggleAudio } = useLocalAudioOutput();

  const selfAttendeeId = meetingManager.configuration.credentials.attendeeId;

  const { roster } = useRosterState();
  const attendees = Object.values(roster);
  const studentFullVideoRef = useRef();

  const [students, setStudents] = useState(null);
  const sendEmojiCode = useCallback((code) => {
    EventBus.emit({ event: EmojiEvent.AddUpEmojiEvent, payload: { emoji: code } })
  }, [])
  const { processDeviceError, showNotification } = useChimeError();
  const { notifications } = useNotificationState();
  const dispatch = useNotificationDispatch();

  const [onCourseDialog, setOnCourseDialog] = useState(true);

  const notificationItems =
    notifications.map(({ id, ...rest }) => (
      <Notification
        key={id}
        {...rest}
        onClose={() => dispatch({ type: ActionType.REMOVE, payload: id })}
      />
    ));

  useEffect(() => {
    let audioError = false;
    let videoError = false;
    if (audioInputDevices.length == 0 || audioInputDevices.some(item => item.label.length == 0))
      audioError = true;
    else if (videoInputDevices.length == 0 || videoInputDevices.some(item => item.label.length == 0))
      videoError = true;

    if (audioError) {
      processDeviceError('麥克風', "PermissionDeniedError");
    }
    if (videoError) {
      processDeviceError('攝影機', "PermissionDeniedError");
    }
  }, [audioInputDevices, videoInputDevices])

  useEffect(() => {
    const attendeeChangedObserver = async (presentAttendeeId, present, externalUserId) => {
      let roleType;
      if (presentAttendeeId.includes("#content")) //表示為螢幕分享
        return;

      if (externalUserId)
        roleType = externalUserId.split("$")[1];
      if (roleType == Roles.TUTOR_USER) {
        if (present)
          showNotification(Severity.INFO, "學生已上線")
        else
          showNotification(Severity.WARNING, "學生已離線", true)
      }

    };
    if (audioVideo) {
      //  audioVideo.addObserver(myObserver);
      audioVideo.realtimeSubscribeToAttendeeIdPresence(attendeeChangedObserver);
    }
  }, [audioVideo]);

  useEffect(() => {
    const studentsInfo = Object.values(roster).find(item => item.externalUserId.split("$")[1] === Roles.TUTOR_USER);
    setStudents(studentsInfo)
  }, [students, roster])


  useEffect(() => {
    if (!audioVideo)
      return;

    isFullVideo ? selectVideoQuality('720p') : audioVideo.chooseVideoInputQuality(320, 240, 15, 500);

    //重起攝影機才會生效
    (async () => {
      if (isVideoEnabled) {
        await restartLocalVideoTile(audioVideo, meetingManager);
        return;
      }

      try {
        await toggleVideo();
      }
      catch (error) {
        processDeviceError(`攝影機`, error);
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFullVideo])

  const restartLocalVideoTile = async (audioVideo, meetingManager) => {
    const observer = {
      videoTileDidUpdate: tileState => {
        // Ignore a tile without attendee ID and other attendee's tile.
        if (!tileState.boundAttendeeId || !tileState.localTile) {
          return;
        }
        console.log("!tileState.active:" + !tileState.active);
        if (!tileState.active) {
          (async () => {
            await audioVideo.chooseVideoInputDevice(meetingManager.selectedVideoInputDevice);
            audioVideo.startLocalVideoTile();
            audioVideo.removeObserver(observer);
          })();
        }
      }
    };
    audioVideo.addObserver(observer);
    audioVideo.stopLocalVideoTile();
  };


  const formatDeviceValue = useCallback(devices => devices.map(item => (
    { label: item.label, value: item.deviceId }
  )
  ), [])

  const { toggleContentShare, isLocalUserSharing, isLocalShareLoading } = useContentShareControls();

  const videoFullScreenHandler = (isFull) => {
    EventBus.emit({ event: ReaderEvent.sendFullVideoEvent, payload: { isFullVideo: isFull } });
    if (isFull && (isLocalUserSharing || isLocalShareLoading)) {
      toggleContentShare();
    }
  }

  const toggleContentShareHandler = () => {
    setOnCourseDialog(false)
    toggleContentShare();
    if (isFullVideo && !(isLocalUserSharing || isLocalShareLoading)) {
      videoFullScreenHandler(false)
    }
  }

  const onVideoInputChange = async (deviceId) => {
    if (!audioVideo || !meetingManager)
      return;
    //先關閉再開啟
    try {
      await audioVideo.chooseVideoInputDevice(null);
      await audioVideo.chooseVideoInputDevice(deviceId);
      audioVideo.startLocalVideoTile();
    }
    catch (error) {
      processDeviceError('攝影機', error);
    }
  }

  const onAudioInputChange = async (deviceId) => {
    await meetingManager.selectAudioInputDevice(deviceId).catch((error) => {
      processDeviceError('麥克風', error);
    });
  }

  const dialogContent = <div className={styles.title}>
    <div>提醒</div>
    <div>教師建議使用「螢幕分享」功能上課，點選『確定』後開始「螢幕分享」，iPad 等行動裝置暫不支援，請點『取消』。</div>
  </div>;
  const dialogAction = <div className={styles.ok}>
    <Button
      style={{
        border: "solid",
        color: "white",
        backgroundColor: "cadetblue",
        borderWidth: 2,
        marginRight: "2%"
      }}
      onClick={() => {
        toggleContentShareHandler()
      }}
      color="primary"
    >
      確定
        </Button>
        <Button
      style={{
        border: "solid",
        color: "white",
        backgroundColor: "cadetblue",
        borderWidth: 2
      }}
      onClick={() => {
        setOnCourseDialog(false)
      }}
      color="primary"
    >
      取消
        </Button>
  </div>;

  return (
    <div className={classnames(styles.discussionPanel, styles.wrapper)}>
      <DialogModule
        style={{ paddingBottom: '20px', display: 'flex', justifyContent: 'center', zIndex: 10000 }}
        open={onCourseDialog}
        content={dialogContent}
        action={dialogAction}
      />
      <div id="content" className={styles.chatContent}>
        <div className={styles.toolsControls}>
          {
            videoInputDevices.length > 0 && (
              <div className={styles.localVideoSelect}>
                <div onClick={toggleVideo}>
                  {
                    isVideoEnabled ?
                      <Icon name="OpenVideo" /> :
                      <Icon name="CloseVideo" />
                  }
                </div>
                {
                  videoInputDevices.some(item => item.label.length == 0) ?
                    <span>請檢查攝影機權限</span>
                    :
                    <SelectComponent
                      type={DeviceType.VideoDevice}
                      optionsList={formatDeviceValue(videoInputDevices)}
                      defaultSelectText={videoInputDevices[0].label}
                      onChange={onVideoInputChange}
                    />
                }
              </div>
            )
          }
          {
            audioInputDevices.length > 0 &&
            <div className={styles.localSound}>
              <div onClick={toggleMute}>
                {
                  muted ?
                    <Icon name="CloseAudioInput" /> :
                    <Icon name="OpenAudioInput" />
                }
              </div>
              {
                audioInputDevices.some(item => item.label.length == 0) ?
                  <span>請檢查麥克風權限</span>
                  :
                  <SelectComponent
                    type={DeviceType.AudioInputDevice}
                    optionsList={formatDeviceValue(audioInputDevices)}
                    defaultSelectText={audioInputDevices[0] && audioInputDevices[0].label}
                    onChange={onAudioInputChange}
                  />
              }
            </div>
          }
          {
            audioOutputDevices.length > 0 &&
            <div className={styles.localAudio}>
              <div onClick={toggleAudio}>
                {
                  isAudioOn ?
                    <Icon name="OpenAudioOutput" /> :
                    <Icon name="CloseAudioOutput" />
                }
              </div>
              <SelectComponent
                type={DeviceType.AudioOutputDevice}
                optionsList={formatDeviceValue(audioOutputDevices)}
                defaultSelectText={audioOutputDevices[0] && audioOutputDevices[0].label}
                onChange={meetingManager.selectAudioOutputDevice}
              />
            </div>
          }
          <div className={styles.toolControl}>
            <StudentToolsControlList />
            {/* <SynchronousReader /> */}
            <button
              className={styles.shareButton}
              onClick={toggleContentShareHandler}
              disabled={isLocalShareLoading}
            >
              {
                isLocalShareLoading ?
                  '螢幕分享中...' : isLocalUserSharing ?
                    '停止螢幕分享' : '螢幕分享'
              }
            </button>
          </div>
        </div>

        <div className={classnames(styles.localVideo, { [styles.localVideoHide]: isFullVideo, [styles.localVideoBG]: isFullVideo })}>
          {
            !isFullVideo && <StudentVideo isFull={isFullVideo} />
          }
          {
            <div
              className={classnames(styles.offFullVideoBtn, { [styles.offFullVideoBtnHide]: !isFullVideo })}
              onClick={() => videoFullScreenHandler(false)}
            >
              <Icon name="OffFullVideoOver" />
              <Icon name="OffFullVideoOut" />
            </div>
          }
          {
            students &&
            <div className={classnames(styles.fullVideoBtn, { [styles.fullVideoBtnHide]: isFullVideo })}
              onClick={() => videoFullScreenHandler(true)}>
              <Icon name="FullVideoOver" />
              <Icon name="FullVideoOut" />
            </div>
          }
        </div>
        <div className={styles.localVideo}>
          <LocalVideo />
          <VolumeMeter className={styles.volumeMeter} width={50} height={15} attendeeId={selfAttendeeId} />
        </div>
        {
          role === Roles.TUTOR &&
          <div className={styles.emojiCounts}>
            <div className={styles.emojiCount}>
              <div><Icon name="FireworksIcon" /></div>
              <div className={styles.countText}>{fireworks && fireworks}</div>
            </div>
            <div className={styles.emojiCount}>
              <div><Icon name="GoodIcon" /></div>
              <div className={styles.countText}>{good && good}</div>
            </div>
            <div className={styles.emojiCount}>
              <div><Icon name="LikeIcon" /></div>
              <div className={styles.countText}>{like && like}</div>
            </div>
            <div className={styles.emojiCount}>
              <div><Icon name="TrophyIcon" /></div>
              <div className={styles.countText}>{trophy && trophy}</div>
            </div>
          </div>
        }
        <div className={classnames(styles.chatRoomWrapper, { [styles.tutor]: role === Roles.TUTOR })}>
          <ChatRoom />
        </div>
        <div >
          <NotificationGroup>
            {notificationItems}
          </NotificationGroup>
        </div>
      </div>
    </div>
  )
}

export default LiveStreamMasterPanel;
