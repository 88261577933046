import { useReaderEventHandler } from 'events/eventHandlers/ReaderEventHandler';
import { useUserEventHandler } from 'events/eventHandlers/UserEventHandler';
import { useExtendedContentEventHandler } from 'events/eventHandlers/ExtendedContentEventHandler'
import { useInitializeActivity } from 'components/AppService/ActivityInitializer';

export const AppService = () => {
    useReaderEventHandler();
    useUserEventHandler();
    useInitializeActivity();
    useExtendedContentEventHandler();
    return null;
};
