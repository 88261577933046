import { EventBus } from 'events/EventBus';
import { PainterMode } from 'constants/painterModes';
import { PainterToolType } from 'constants/painterTypes';
import {
  ActivityEvent,
  InteractiveObjectEvent,
  ReaderEvent,
  ReaderToolsEvent,
} from 'events/EventTypes';
import {
  ReaderToolType,
  ReaderZoomType,
  SideBarType,
  SVGCanvasSwitchType
} from 'constants/ReaderTools';
import { BookFlipType } from 'constants/flipTypes';

const closeToolbox = () => {
  EventBus.emit({
    event: ReaderToolsEvent.ClickReaderToolBoxEvent,
    payload: {
      isToolboxShow: false
    }
  })
}

const changePainterMode = ({ event, painterMode, painterToolType }) => {
  EventBus.emit({ event, payload: { painterMode, painterToolType } });
  closeToolbox();
};

const addImageClickHandler = e => {
  EventBus.emit({
    event: ReaderToolsEvent.ClickInsertImageEvent,
    payload: { painterMode: PainterMode.InsertImage }
  });
  setReaderToolType(ReaderToolType.Img);
};

const addTextClickHandler = e => {
  EventBus.emit({
    event: ReaderToolsEvent.ClickInsertTextEvent,
    payload: { painterMode: PainterMode.InsertText }
  });
  setReaderToolType(ReaderToolType.Text);
};

const toggleBookmarkHandler = e => {
  EventBus.emit({
    event: ReaderToolsEvent.ToggleBookmarkEvent
  });
  closeToolbox();
};

const toggleActivityPanel = () => {
  EventBus.emit({
    event: ActivityEvent.ToggleActivityPanelEvent,
    payload: {}
  });
  closeToolbox();
};

const setReaderToolType = type => {
  EventBus.emit({
    event: ReaderToolsEvent.SetReaderToolTypeEvent,
    payload: {
      readerToolType: type
    }
  });
  type !== ReaderToolType.Toolbox && closeToolbox();
}

const toggleSideBarHandler = sideBarType => e => {
  EventBus.emit({
    event: ReaderToolsEvent.ToggleSideBarEvent,
    payload: { sideBarType }
  });
  closeToolbox();
};

export default {
  [ReaderToolType.ResetLastAnnatation]: {
    title: '還原',
    clickHandler: () => {
      EventBus.emit({
        event: ReaderToolsEvent.ResetCourseAnnotationEvent
      });
    },
    icon: 'ResetLastAnnatation',
  },
  [ReaderToolType.Expression]: {
    title: '表情',
    clickHandler: () => {
      EventBus.emit({
        event: ReaderToolsEvent.ClickDragEvent
      });
      setReaderToolType(ReaderToolType.Drag);
    },
    icon: 'Expression',
  },
  [ReaderToolType.BackToCatalog]: {
    // title: '回目次',
    title: 'ReaderToolTitle_BackToCatalog',
    clickHandler: () => {
      EventBus.emit({ event: ReaderEvent.GoBackCatalogEvent });
      closeToolbox();
    },
    icon: 'ContentBack',
  },
  [ReaderToolType.StickyNote]: {
    title: '便利貼',
    clickHandler: () => {
      EventBus.emit({
        event: ReaderToolsEvent.CreateCourseInteractiveItemEvent,
        payload: { painterMode: ReaderToolType.StickyNote, contentType: ReaderToolType.StickyNote ,isNew:true}
      });
      setReaderToolType(ReaderToolType.StickyNote);
    },
    icon: 'StickyNote',
  },
  [ReaderToolType.MathTools]: {
    title: '尺規作圖',
    clickHandler: () => {
      EventBus.emit({ event: ReaderToolsEvent.ClickMathToolsEvent, payload: { isMathToolsShow: true } });
      closeToolbox();
    },
    icon: 'MathTool',
  },
  [ReaderToolType.Bookshelf]: {
    // title: '書櫃',
    title: 'ReaderToolTitle_Bookshelf',
    icon: 'Bookcase',
  },
  [ReaderToolType.Menu]: {
    // title: '目次',
    title: 'ReaderToolTitle_Menu',
    clickHandler: toggleSideBarHandler(SideBarType.LEFT),
    icon: 'Content',
  },
  [ReaderToolType.Mark]: {
    // title: '標記文字',
    title: 'ReaderToolTitle_Mark',
    clickHandler: () => {
      EventBus.emit({
        event: ReaderToolsEvent.OnMarkModeEvent
      });
      setReaderToolType(ReaderToolType.Mark)
    },
    icon: 'Mark',
  },
  [ReaderToolType.Drag]: {
    // title: '滑鼠',
    title: 'ReaderToolTitle_Mouse',
    clickHandler: () => {
      EventBus.emit({
        event: ReaderToolsEvent.ClickDragEvent
      });
      setReaderToolType(ReaderToolType.Drag);
    },
    icon: 'MouseHand',
  },
  [ReaderToolType.Select]: {
    // title: '選取',
    title: 'ReaderToolTitle_Select',
    clickHandler: () => {
      changePainterMode({
        event: ReaderToolsEvent.ClickSelectEvent,
        painterMode: PainterMode.Selection
      });
      setReaderToolType(ReaderToolType.Select);
    },
    icon: 'Select',
  },
  [ReaderToolType.Painting]: {
    // title: '畫筆',
    title: 'ReaderToolTitle_Painter',
    clickHandler: () => {
      changePainterMode({
        event: ReaderToolsEvent.ClickPainterEvent,
        painterMode: PainterMode.Painting,
        painterToolType: PainterToolType.Pen
      });
      setReaderToolType(ReaderToolType.Painting);
    },
    icon: 'Painting',
  },
  [ReaderToolType.Highlighter]: {
    // title: '螢光筆',
    title: 'ReaderToolTitle_Highlighter',
    clickHandler: () => {
      changePainterMode({
        event: ReaderToolsEvent.ClickPainterEvent,
        painterMode: PainterMode.Painting,
        painterToolType: PainterToolType.Highlighter
      });
      setReaderToolType(ReaderToolType.Highlighter);
    },
    icon: 'Highlighter',
  },
  [ReaderToolType.Shape]: {
    // title: '形狀',
    title: 'ReaderToolTitle_Shape',
    clickHandler: () => {
      changePainterMode({
        event: ReaderToolsEvent.ClickPainterEvent,
        painterMode: PainterMode.Painting,
        painterToolType: PainterToolType.Shape
      });
      setReaderToolType(ReaderToolType.Shape);
    },
    icon: 'Shape',
  },
  [ReaderToolType.Line]: {
    // title: '線段',
    title: 'ReaderToolTitle_Line',
    clickHandler: () => {
      changePainterMode({
        event: ReaderToolsEvent.ClickPainterEvent,
        painterMode: PainterMode.Painting,
        painterToolType: PainterToolType.Line
      });
      setReaderToolType(ReaderToolType.Line);
    },
    icon: 'Line',
  },
  [ReaderToolType.DeleteAll]: {
    // title: '全部刪除',
    title: 'ReaderToolTitle_DeleteAll',
    clickHandler: () => {
      EventBus.emit({ event: ReaderToolsEvent.ClickEraseAllEvent });
      closeToolbox();
    },
    icon: 'DeleteAll',
  },
  [ReaderToolType.Delete]: {
    // title: '部分刪除',
    title: 'ReaderToolTitle_Delete',
    clickHandler: () => {
      changePainterMode({
        event: ReaderToolsEvent.ClickEraserEvent,
        painterMode: PainterMode.Eraser
      })
      setReaderToolType(ReaderToolType.Delete);
    },
    icon: 'Delete',
  },
  [ReaderToolType.ArrowLeft]: {
    // title: '左翻',
    title: { [BookFlipType.LEFT_TO_RIGHT]: 'ReaderToolTitle_LeftFlip', [BookFlipType.RIGHT_TO_LEFT]: 'ReaderToolTitle_RightFlip' },
    clickHandler: (book, LRFlip) => {
      if (LRFlip === BookFlipType.LEFT_TO_RIGHT) {
        EventBus.emit({
          event: ReaderEvent.ClickPreviousPageEvent,
          payload: { convertToSVG: true }
        });
      } else {
        EventBus.emit({
          event: ReaderEvent.ClickNextPageEvent,
          payload: { pageInfos: book.pageInfos, convertToSVG: true }
        });
      }
      closeToolbox();
    },
    icon: 'ArrowLeft',
  },
  [ReaderToolType.ArrowRight]: {
    // title: '右翻',
    title: { [BookFlipType.LEFT_TO_RIGHT]: 'ReaderToolTitle_RightFlip', [BookFlipType.RIGHT_TO_LEFT]: 'ReaderToolTitle_LeftFlip' },
    clickHandler: (book, LRFlip) => {
      if (LRFlip === BookFlipType.LEFT_TO_RIGHT) {
        EventBus.emit({
          event: ReaderEvent.ClickNextPageEvent,
          payload: { pageInfos: book.pageInfos, convertToSVG: true }
        });
      } else {
        EventBus.emit({
          event: ReaderEvent.ClickPreviousPageEvent,
          payload: { convertToSVG: true }
        });
      }
      closeToolbox();
    },
    icon: 'ArrowRight',
  },
  [ReaderToolType.PageSearcher]: {
    // title: '頁數選擇',
    title: 'ReaderToolTitle_PageSearcher',
    clickHandler: () => {
      EventBus.emit({
        event: ReaderToolsEvent.TogglePageSearcherEvent
      });
      closeToolbox();
    },
    icon: 'PageSearcher',
  },
  [ReaderToolType.FourZoomIn]: {
    // title: '四格放大'
    title: 'ReaderToolTitle_QuarterZoomIn',
  },
  [ReaderToolType.AllScreen]: {
    // title: '全螢幕',
    title: 'ReaderToolTitle_FullScreen',
    clickHandler: () => {
      EventBus.emit({
        event: ReaderToolsEvent.SetReaderZoomEvent,
        payload: { type: ReaderZoomType.OriginZoom }
      })
      closeToolbox();
    },
    icon: 'AllScreen',
  },
  [ReaderToolType.IntervalZoomIn]: {
    // title: '區間放大',
    title: 'ReaderToolTitle_SectionZoomIn',
    clickHandler: () => {
      EventBus.emit({
        event: ReaderEvent.DrawAreaEvent,
        payload: { drawAreaInfo: { isDrawArea: true } }
      });
      setReaderToolType(ReaderToolType.IntervalZoomIn);
    },
    icon: 'IntervalZoomIn',
  },
  [ReaderToolType.Whiteboard]: {
    // title: '白板',
    title: 'ReaderToolTitle_Whiteboard',
    icon: 'Whiteboard',
  },
  [ReaderToolType.NumberPicker]: {
    // title: '選號器',
    title: 'ReaderToolTitle_NumberPicker',
    icon: 'NumberPicker',
  },
  [ReaderToolType.Img]: {
    // title: '新增圖片',
    title: 'ReaderToolTitle_InsertImage',
    clickHandler: addImageClickHandler,
    icon: 'Img',
  },
  [ReaderToolType.Text]: {
    // title: '新增文字',
    title: 'ReaderToolTitle_InsertText',
    clickHandler: addTextClickHandler,
    icon: 'Text',
  },
  [ReaderToolType.SVG]: {
    // title: 'SVG物件',
    title: 'ReaderToolTitle_InsertStamp',
    clickHandler: () => {
      EventBus.emit({ event: ReaderToolsEvent.ClickStampPanelEvent })
      setReaderToolType(ReaderToolType.Menu);
    },
    icon: 'SVG',
  },
  [ReaderToolType.PageMode]: {
    // title: { [true]: '單頁', [false]: '雙頁' },
    title: { [true]: 'ReaderToolTitle_SinglePageMode', [false]: 'ReaderToolTitle_DoublePageMode' },
    clickHandler: isDoublePageMode => {
      EventBus.emit({
        event: ReaderToolsEvent.TogglePageModeEvent,
        payload: { isDoublePageMode: !isDoublePageMode }
      })
      closeToolbox();
    },
    icon: { [true]: 'OnePage', [false]: 'DoublePage' }
  },
  [ReaderToolType.ReaderOption]: {
    // title: '設定',
    title: 'ReaderToolTitle_Options',
    clickHandler: () => {
      EventBus.emit({ event: ReaderToolsEvent.ClickReaderOptionPanelEvent })
      closeToolbox();
    },
    icon: 'Setup',
  },
  [ReaderToolType.Bookmark]: {
    // title: '頁籤',
    title: 'ReaderToolTitle_Bookmark',
    clickHandler: () => toggleBookmarkHandler(),
    icon: 'Bookmark',
  },
  [ReaderToolType.Activity]: {
    // title: '活動',
    title: 'ReaderToolTitle_Activity',
    clickHandler: () => toggleActivityPanel(),
    icon: 'Activity',
  },
  [ReaderToolType.ExportInteractiveObject]: {
    // title: '匯出',
    title: 'ReaderToolTitle_ExportInteractiveObject',
    clickHandler: e => {
      EventBus.emit({
        event: InteractiveObjectEvent.ExportInteractiveObjectEvent
      });
    },
    icon: 'Save',
  },
  [ReaderToolType.AttendeeNumber]: {
    // title: '座號',
    title: 'ReaderToolTitle_AttendeeNumber',
  },
  [ReaderToolType.OfflineReaderToolBox]: {
    // title: '工具箱',
    title: 'ReaderToolTitle_OfflineReaderToolBox',
    clickHandler: () => {
      EventBus.emit({ event: ReaderToolsEvent.ClickOfflineReaderToolBoxEvent })
      setReaderToolType(ReaderToolType.OfflineReaderToolBox);
    },
    icon: 'MathToolBox',
  },
  [ReaderToolType.Toolbox]: {
    // title: '工具箱',
    title: 'ReaderToolTitle_Toolbox',
    clickHandler: () => {
      EventBus.emit({
        event: ReaderToolsEvent.ClickReaderToolBoxEvent,
        payload: {
          isToolboxShow: true
        }
      })
      setReaderToolType(ReaderToolType.Toolbox);
    },
    icon: 'ToolBox',
  },
};
