import React from 'react';

import classnames from 'classnames';
import styles from './index.module.scss';

const FormGroup = ({ label, children, vertical }) => (
  <div className={classnames(styles.formGroup, {[styles.vertical]:vertical})}>
    <div className={styles.label}>{label}</div>
    <div className={styles.content}>
      {children}
    </div>
  </div>
);

export default FormGroup;