import url from 'url';
import { fileExpressHost, fileExpressPort } from 'repositories/Repository';
import { IExtendedResourceRepository } from './IExtendedResourceRepository';
import { encodeURIString } from 'util/helper';

function FileExtendedResourceRepository() { }
FileExtendedResourceRepository.prototype = Object.create(
  IExtendedResourceRepository
);

FileExtendedResourceRepository.prototype.getVideoSrc = ({
  bookId,
  filename
}) => {
  return url.format({
    protocol: 'http:',
    hostname: fileExpressHost,
    port: fileExpressPort,
    pathname: `media/${bookId}/video/${filename}.mp4`,
    slashes: true
  });
};

FileExtendedResourceRepository.prototype.getAudioSrc = ({
  bookId,
  filename
}) => {
  return url.format({
    protocol: 'http:',
    hostname: fileExpressHost,
    port: fileExpressPort,
    pathname: `media/${bookId}/audio/${filename}.mp3`,
    slashes: true
  });
};

FileExtendedResourceRepository.prototype.getAudioVTTSrc = ({
  bookId,
  audioInfo
}) => {
  return url.format({
    protocol: 'http:',
    hostname: fileExpressHost,
    port: fileExpressPort,
    pathname: `media/${bookId}/audio/${audioInfo}.vtt`,
    slashes: true
  });
};

FileExtendedResourceRepository.prototype.getSubtitleSrc = ({
  bookId,
  filename
}) => {
  return url.format({
    protocol: 'http:',
    hostname: fileExpressHost,
    port: fileExpressPort,
    pathname: `media/${bookId}/video/${filename}.vtt`,
    slashes: true
  });
};

FileExtendedResourceRepository.prototype.getInternalHtmlSrc = ({
  bookId,
  filename
}) => {
  return url.format({
    protocol: 'http:',
    hostname: fileExpressHost,
    port: fileExpressPort,
    pathname: `data/${bookId}/${filename}.html`,
    slashes: true
  });
};

FileExtendedResourceRepository.prototype.getDataSrc = ({
  bookId,
  pathName
}) => {
  return url.format({
    protocol: 'http:',
    hostname: fileExpressHost,
    port: fileExpressPort,
    pathname: `data/${bookId}/${encodeURIString(pathName)}`,
    slashes: true
  });
};

FileExtendedResourceRepository.prototype.getInternalWindowHtml = async ({
  bookId,
  filename
}) => {
  const res = await fetch(
    url.format({
      protocol: 'http:',
      hostname: fileExpressHost,
      port: fileExpressPort,
      pathname: `data/${bookId}/${filename}.json`,
      slashes: true
    })
  );
  const json = await res.json();
  return json.html;
};

FileExtendedResourceRepository.prototype.getJSONContent = async ({
  bookId,
  pathName
}) => {
  const res = await fetch(
    url.format({
      protocol: 'http:',
      hostname: fileExpressHost,
      port: fileExpressPort,
      pathname: `data/${bookId}/${pathName}.json`,
      slashes: true
    })
  );
  const json = await res.json();
  return json;
};

FileExtendedResourceRepository.prototype.getMathJaxPath = () => {
  return url.format({
    protocol: 'http',
    hostname: fileExpressHost,
    port: fileExpressPort,
    pathname: 'mathjax/MathJax.js',
    slashes: true
  });
  // return 'http://localhost:54321/mathjax/MathJax.js';
};

FileExtendedResourceRepository.prototype.getBookmarks = async (bookId) => {
  const res = await fetch(
    url.format({
      protocol: 'http:',
      hostname: fileExpressHost,
      port: fileExpressPort,
      pathname: `bookmarks/${bookId}`,
      slashes: true
    })
  );
  const json = await res.json();
  return json;
};

FileExtendedResourceRepository.prototype.getPageTools = async ({ bookId }) => {
  const res = await fetch(
    url.format({
      protocol: 'http:',
      hostname: fileExpressHost,
      port: fileExpressPort,
      pathname: `offline-tools/${bookId}`,
      query: { type: 'pages' },
      slashes: true
    })
  );
  const json = await res.json();
  json.getToolsByPage = function (page) {
    let tools = [];
    for (let tool of this) {
      if (page >= tool.pages[0] && page <= tool.pages[1]) {
        tools.push(tool);
      }
    }
    return tools;
  }
  json.getToolsByPage = json.getToolsByPage.bind(json);
  return json;
};

FileExtendedResourceRepository.prototype.getToolbarTools = async ({ bookId }) => {
  const res = await fetch(
    url.format({
      protocol: 'http:',
      hostname: fileExpressHost,
      port: fileExpressPort,
      pathname: `offline-tools/${bookId}`,
      query: { type: 'toolbar' },
      slashes: true
    })
  );
  const json = await res.json();
  return json;
};

FileExtendedResourceRepository.prototype.postTools = async ({ bookId, filename }) => {
  fetch(url.format({
    protocol: 'http:',
    hostname: fileExpressHost,
    port: fileExpressPort,
    pathname: `offline-tools`,
    slashes: true
  }), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ bookId, filename })
  });

  return null;
};

FileExtendedResourceRepository.prototype.getWordGridAnchorDirection = () => {
  return 'end';
};

export default new FileExtendedResourceRepository();
