import url from 'url';
import { fileExpressHost, fileExpressPort } from 'repositories/Repository';
import { IReaderToolBarRepository } from './IReaderToolBarRepository';
import { 
    ReaderToolType, 
    DefaultLeftReaderTools, 
    DefaultMiddleReaderTools, 
    DefaultRightReaderTools,
    DefaultReaderTools,
    DefaultToolboxCategory,
    ToolboxCategory 
} from 'constants/ReaderTools';

function FileReaderToolBarRepository() { };
FileReaderToolBarRepository.prototype = Object.create(IReaderToolBarRepository);

FileReaderToolBarRepository.prototype.getDefaultReaderTools = async () => {
    return DefaultReaderTools;
};

FileReaderToolBarRepository.prototype.getLeftReaderTools = async () => {
    return DefaultLeftReaderTools;
};

FileReaderToolBarRepository.prototype.getMiddleReaderTools = async () => {
    return DefaultMiddleReaderTools;
};

FileReaderToolBarRepository.prototype.getRightReaderTools = async (bookId) => {
    const results = [...DefaultRightReaderTools];
    const res = await fetch(
        url.format({
            protocol: 'http:',
            hostname: fileExpressHost,
            port: fileExpressPort,
            pathname: `offline-tools/${bookId}`,
            query: { type: 'toolbar' },
            slashes: true
        })
    );
    const json = await res.json();
    const mathTools = json.filter(item=> item.type && item.type === 'mathTool')
    if (!mathTools || mathTools.length === 0 ) {
        if (results.indexOf(ReaderToolType.OfflineReaderToolBox) >= 0) {
            results.splice(results.indexOf(ReaderToolType.OfflineReaderToolBox), 1)
        }
    }
    return results;
};

FileReaderToolBarRepository.prototype.getGuestReaderToolBarWhitelist = () => {
    return [
        ReaderToolType.Toolbox,
        ReaderToolType.BackToCatalog,
        ReaderToolType.Menu,
        ReaderToolType.Bookshelf,
        ReaderToolType.PageMode,
        ReaderToolType.Mark,
        ReaderToolType.Drag,
        ReaderToolType.Select,
        ReaderToolType.Painter,
        ReaderToolType.Painting,
        ReaderToolType.Highlighter,
        ReaderToolType.Shape,
        ReaderToolType.Line,
        ReaderToolType.DeleteAll,
        ReaderToolType.Delete,
        ReaderToolType.ArrowLeft,
        ReaderToolType.ArrowRight,
        ReaderToolType.PageSearcher,
        ReaderToolType.FourZoomIn,
        ReaderToolType.AllScreen,
        ReaderToolType.IntervalZoomIn,
        ReaderToolType.Whiteboard,
        ReaderToolType.NumberPicker,
        ReaderToolType.Img,
        ReaderToolType.Text,
        //ReaderToolType.SVG,
        ReaderToolType.Bookmark,
        ReaderToolType.ReaderOption,
        ReaderToolType.RightSideBar,
        ReaderToolType.FullWidth,
        ReaderToolType.OfflineReaderToolBox,
        ReaderToolType.StickyNote,
        ReaderToolType.MathTools,
    ];
};

FileReaderToolBarRepository.prototype.getReaderToolboxCategory = () => {
    return [...DefaultToolboxCategory, ToolboxCategory.OfflineTools]
 };

export default new FileReaderToolBarRepository();
