import React from 'react';
import { useStore, StoreTypes } from 'context';
import * as types from 'constants/actionTypes';
import MusicPlayer from '../index';
import styles from './index.module.scss';
import Repository from 'repositories/Repository';
import Draggable from 'react-draggable';
import { EventBus } from 'events/EventBus';
import { ReaderToolsEvent } from 'events/EventTypes';
const { ExtendedResourceRepository } = Repository;




export const useMusicModal = () => {
  const [{ musicModal }, globalDispatch] = useStore(StoreTypes.global);
  const [{ bookId }] = useStore(StoreTypes.books);

  const openModal = ({ src, audioInfo, partialSrc = [], isShowAudioControlBar, isAudioHighlighter, onAudioHightlighterPlay }) => {

    const getAudioSrc = filename => ExtendedResourceRepository.getAudioSrc({
      bookId,
      filename
    })

    const srcFullPath = getAudioSrc(src);
    const partialSrcFullPath = partialSrc.map(item => getAudioSrc(item))

    globalDispatch({
      type: types.OPEN_MUSIC_MODAL,
      payload: { src: srcFullPath, audioInfo, partialSrc: partialSrcFullPath, isShowAudioControlBar, isAudioHighlighter, onAudioHightlighterPlay }
    });
  };

  const closeModal = () => {
    EventBus.emit({ event: ReaderToolsEvent.CloseModalEvent, payload: { modalType: "" } });
  };

  return [musicModal, { openModal, closeModal }];
};

const MusicModal = () => {
  const [{ isShowing, src, audioInfo, partialSrc, isShowAudioControlBar, isAudioHighlighter, onAudioHightlighterPlay }, { closeModal }] = useMusicModal();
  const [{ bookId }] = useStore(StoreTypes.books);
  const [, globalDispatch] = useStore(StoreTypes.global);

  const closeAudioHighlighter = () => {
    closeModal();
    globalDispatch({
      type: types.SET_MUSIC_MODAL_PROPERTY,
      payload: {
        activeObjectId: ''
      }
    });
  }

  const onAudioHightlighterStop = () => {
    globalDispatch({
      type: types.SET_MUSIC_MODAL_PROPERTY,
      payload: {
        activeObjectId: ''
      }
    });
  }

  return (
    isShowing && (
      <Draggable>
        <div className={styles.musicModal}>
          {
            isAudioHighlighter ? (
              <MusicPlayer
                setting
                src={src}
                onClose={closeAudioHighlighter}
                onLoad={onAudioHightlighterPlay}
                onStop={onAudioHightlighterStop}
                isShowAudioControlBar={isShowAudioControlBar}
                subtitles={[
                  {
                    language: 'en',
                    src: ExtendedResourceRepository.getAudioVTTSrc({
                      bookId,
                      audioInfo
                    })
                  }
                ]} />
            ) : (
                <MusicPlayer
                  setting
                  src={src}
                  partialSrc={partialSrc}
                  onClose={closeModal}
                  isShowAudioControlBar={isShowAudioControlBar}
                  subtitles={[
                    {
                      language: 'en',
                      src: ExtendedResourceRepository.getAudioVTTSrc({
                        bookId,
                        audioInfo
                      })
                    }
                  ]}
                />
              )
          }
        </div>
      </Draggable>
    )
  );
};

export default MusicModal;
