import React, { useState,useRef } from 'react';
import styles from './index.module.scss';
import { useOutsideAlerter } from 'customHooks/outsideAlerter';
import classnames from 'classnames';
import { DeviceType } from 'constants/chimeTypes'

export const SelectComponent = (props) => {
    const ref = useRef();
    const { defaultSelectText, optionsList, onChange ,className,type} = props
    const [showOptionList, setShowOptionList] = useState(false)
    const [selectText, setSelectText] = useState(defaultSelectText)

    const close=()=>{
        setShowOptionList(false)
    }
    useOutsideAlerter(ref, close);
    return (
        <div ref={ref} className={classnames(styles.selectComponent, className)}>
            <div
                className={styles.selectedText}
                onClick={(e) => {
                    setShowOptionList(true)
                }}
            >
                {selectText}
            </div>
            {showOptionList && (
                <div className={styles.selectOptions}>
                    {optionsList.map((option,index) => {
                        return (
                            <div
                                className={type===DeviceType.VideoDevice?styles.videoDevice:styles.audioOutput}
                                data-name={option.label}
                                key={index}
                                onClick={(e) => {
                                    setSelectText(option.label)
                                    setShowOptionList(false)
                                    onChange(option.value)
                                }}
                            >
                                {option.label}
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    )
}


export default SelectComponent;
