import React from 'react';
import Button from 'components/Button/index';
import Icon from 'components/Icon';
import { PainterType, LineType } from 'constants/painterTypes';
import styles from './index.module.scss';
import classnames from 'classnames';
import { useCallback } from 'react';

const LineTypeData = [
  { label: '實線', value:LineType.Line },
  { label: '虛線', value:LineType.Dash }
];

const PainterOptionSelector = ({ 
  lineType = PainterType.Line, 
  painterType,
  onLineTypeChange = () => {},
  onPainterChange = () => {}
}) => {

  const painterChangeHandler = useCallback(type=>{
    onPainterChange(type)
    type === PainterType.Pen && onLineTypeChange(LineType.Line)
  },[onLineTypeChange, onPainterChange])

  const getRadioStatus = useCallback(()=>{
    return lineType === LineType.Line && painterType === PainterType.Pen
  },[lineType, painterType])

  const lineTypeChangeHandler = useCallback(value => {
    !getRadioStatus() && onLineTypeChange(value)
  },[getRadioStatus, onLineTypeChange])

  return (
    <div className={styles.painterOptionSelector}>
      <div className={styles.radio}>
        {LineTypeData.map(item => (
          <label key={item.value} className={classnames({ [styles.disabled]: getRadioStatus() })}>
            <input
              type="radio"
              name="painterOptionSelector"
              value={item.value}
              checked={lineType === item.value}
              onChange={e => lineTypeChangeHandler(e.target.value)}
            />
            <span className={styles.text}>{item.label}</span>
          </label>
        ))}
      </div>
      <div className={styles.buttonGroup}>
        <Button
            className={classnames(styles.button,{
              [styles.active]: painterType === PainterType.Line
            })}
            onClick={() => painterChangeHandler(PainterType.Line)}
          >
            <Icon name="PenStraight" />
          </Button>
          <Button
            className={classnames(styles.button,{
              [styles.active]: painterType === PainterType.Pen,
              [styles.disabled]: lineType === LineType.Dash
            })}
            onClick={() => painterChangeHandler(PainterType.Pen)}
            disabled={lineType === LineType.Dash}
          >
            <Icon name="PenCurve" />
          </Button>
          <Button
            className={classnames(styles.button,{
              [styles.active]: painterType === PainterType.Arrow
            })}
            onClick={() => painterChangeHandler(PainterType.Arrow)}
          >
            <Icon name="Arrow" />
          </Button>
      </div>
    </div>
  );
};
export default PainterOptionSelector;
