import { useState, useCallback } from 'react';
import * as types from 'constants/actionTypes';
import path from 'path';
import { useStore, StoreTypes } from 'context';
import { useFetchBookCommandDispatcher } from 'customHooks/fetchBook';
import Repository from 'repositories/Repository';
const { BookContentRepository } = Repository;

export const useFetchBooks = () => {
  const [{ books }, bookDispatch] = useStore(StoreTypes.books);
  const [isLoading, setLoading] = useState(true);
  const dispatcher = useFetchBookCommandDispatcher();

  const fetchBooks = useCallback(async ({ token, bookIds = [] }) => {
    const books = await BookContentRepository.fetchBooks({ token, bookIds });
    bookDispatch({ type: types.SET_BOOKS, payload: books });
    setLoading(false);
    return books;
  }, [bookDispatch]);

  const getPurchasedProducts = useCallback(async ({ token }) => {
    const books = await BookContentRepository.getPurchasedProducts({ token });
    bookDispatch({ type: types.SET_BOOKS, payload: books });
    setLoading(false);
    return books;
  }, [bookDispatch]);

  const fetchBook = useCallback(async ({ givenBooks = books, bookId, pages }) => {
    const book = givenBooks.find(book => book.bookId === bookId);
    if (!book) return;
    dispatcher.fetch(pages);
  }, [books, dispatcher]);

  const getBookCatalog = useCallback(async ({ bookId }) => {
    const catalog = await BookContentRepository.getBookCatalog({ bookId }) || [];
    const sortHandler = (a, b) => a.startPage - b.startPage;

    catalog.sections = catalog.map((book, index) => {
      let labels = book.sections.reduce((acc, section) => {
        section.pages.forEach(page => {
          if (!acc.find(v => v.label === page.label)) {
            acc.push({
              startPage: path.basename(page.src, path.extname(page.src)) - 1,
              label: page.label,
              catalog: page.catalog ? true : false
            });
          }
        });
        return acc;
      }, []);
      labels.sort(sortHandler);
      return {
        startPage: (labels[0] && labels[0].startPage) || 0,
        labels,
      };
    });
    catalog.sections.sort(sortHandler);

    function getLabel(pageIndex) {
      for (let section of this.sections.slice().reverse()) {
        if (pageIndex < section.startPage) continue;
        for (let label of section.labels.slice().reverse()) {
          if (pageIndex < label.startPage) continue;
          return label.label;
        }
      }
      return '';
    }
    catalog.getLabel = getLabel.bind(catalog);

    function getCatalogIndex(isDoublePageMode, pageIndex) {
      const nowPageIndex = isDoublePageMode ? (Math.floor(pageIndex * 2)) : pageIndex
      function getSectionsIndex(catalog) {
        for (let section of catalog.sections.slice().reverse()) {
          if (nowPageIndex < section.startPage) continue;
          const label = section.labels.find((obj) => obj.catalog)
          return label ? label.startPage : section.startPage;
        }
      }
      return getSectionsIndex(this)
    }
    catalog.getCatalogIndex = getCatalogIndex.bind(catalog);

    function getBookmarkIndex(pageIndex) {
      let index = catalog.sections.length - 1
      while (catalog.sections[index].startPage > pageIndex) {
        --index;
      }
      return Math.max(index, 0);
    }
    catalog.getBookmarkIndex = getBookmarkIndex.bind(catalog);

    bookDispatch({ type: types.SET_BOOK_CATALOG, catalog });
  }, [bookDispatch]);


  return {
    isLoading,
    setLoading,
    fetchBooks,
    getPurchasedProducts,
    fetchBook,
    getBookCatalog,
  };
};
