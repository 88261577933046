import React, { useCallback } from 'react';
import Icon from 'components/Icon';
import { useBoolean } from 'customHooks/boolean';
import classnames from 'classnames';
import styles from './index.module.scss';

const WordsMovieButtonGroup = ({
  wordingMap,
  data,
  activeIndex,
  onChange = () => { },
  onPlayAllWordsClick = () => { }
}) => {
  const [isPlaying, { toggle }] = useBoolean(false);

  const onPlayAllWordsClickHandler = useCallback(e => {
    onPlayAllWordsClick(!isPlaying);
    toggle();
  }, [onPlayAllWordsClick, isPlaying, toggle]);

  return (
    <div className={styles.buttonGroup}>
      {
        data.map((item, index) => (
          wordingMap[index] &&
          <button
            key={item.Path}
            className={classnames({ [styles.active]: index === activeIndex })}
            onClick={() => onChange({
              index,
              value: item.Path
            })}>
            {wordingMap[index]}
          </button>))
      }
      <button
        className={classnames({ [styles.active]: isPlaying })}
        onClick={onPlayAllWordsClickHandler}
      >
        生字連播
      </button>
    </div>
  )
};

export default WordsMovieButtonGroup;
