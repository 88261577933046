import React from 'react';
import Input from 'components/common/Input';
import { EventBus } from 'events/EventBus';
import { CanvasEvent } from 'events/EventTypes';
import { ExtraFieldsForEditor } from 'constants/canvas';
import styles from './index.module.scss';

const LISTENING_INFO = ExtraFieldsForEditor.ListeningInfo;

export const ListeningStoryComponent = ({ object }) => {

  let listeningInfo = object[LISTENING_INFO] ? JSON.parse(object[LISTENING_INFO]) : { tab: '', zhuyin: '' }

  const changeHandler = type => e => {
    listeningInfo[type] = type === 'zhuyin' ? e.target.checked : e.target.value;

    EventBus.emit({
      event: CanvasEvent.ModifyCanvasObjectPropertyEvent,
      payload: { object, property: LISTENING_INFO, value: JSON.stringify(listeningInfo) }
    });
  };

  return (
    <>
      <Input type="number" className={styles.vocabularyInput} label="預設頁籤" value={listeningInfo.tab} onChange={changeHandler('tab')} />
      <Input type="checkbox" checked={listeningInfo.zhuyin} label="純注音" onChange={changeHandler('zhuyin')} />
    </>
  )
};