import React, { useCallback } from 'react';
import { ReaderToolType } from 'constants/ReaderTools';
import BasicReaderToolComponent from './ReaderToolComponents/BasicReaderToolComponent';
import ActivatableReaderToolComponent from './ReaderToolComponents/ActivatableReaderToolComponent';
import RelativePositionComponent from './ReaderToolComponents/RelativePositionComponent';
import ReaderToolBookshelf from './ReaderToolComponents/ReaderToolBookshelf';
import ReaderToolFlipPageComponent from './ReaderToolComponents/ReaderToolFlipPageComponent';
import ReaderToolFourZoomIn from './ReaderToolComponents/ReaderToolFourZoomIn';
import InteractiveObjectContentCommandComponent from './ReaderToolComponents/InteractiveObjectContentCommandComponent';
import ReaderToolPageModeComponent from './ReaderToolComponents/ReaderToolPageModeComponent';
import ReaderToolInsertComponent from './ReaderToolComponents/ReaderToolInsertComponent';
import { ReaderToolActivityAttendeeNumber } from './ReaderToolComponents/ReaderToolActivityAttendeeNumber';
import { useStore, StoreTypes } from 'context';
import { useInteractiveObjectContentCommandFactory } from 'customHooks/InteractiveObjectContentCommands/commandFactory';
import { InteractiveObjectContentType } from 'constants/interactiveObjectContentTypes';

export const useReaderToolsFactory = () => {
    const reducers = useStore();
    const [{ isDoublePageMode, readerToolType }] = reducers[StoreTypes.reader];
    const [{ activityInfo: { attendeeNumber } }] = reducers[StoreTypes.annotation];

    const InteractiveObjectContentCommandFactory = useInteractiveObjectContentCommandFactory();

    const getInteractiveObjectContentCommand = useCallback((type) => {
        switch (type) {
            case ReaderToolType.Whiteboard:
                return InteractiveObjectContentCommandFactory.createCommand({ contentType: InteractiveObjectContentType.Whiteboard ,canvasSVGObject:null});
            case ReaderToolType.NumberPicker:
                return InteractiveObjectContentCommandFactory.createCommand({ contentType: InteractiveObjectContentType.NumberPicker });
            default:
                return null;
        }
    }, [InteractiveObjectContentCommandFactory]);

    const createReaderTool = (type, { additionalClickHandler, clickHandlerParameters } = {}) => {
        switch (type) {
            case ReaderToolType.Toolbox:
            case ReaderToolType.BackToCatalog:
            case ReaderToolType.Menu:
            case ReaderToolType.DeleteAll:
            case ReaderToolType.AllScreen:
            case ReaderToolType.IntervalZoomIn:
            case ReaderToolType.SVG:
            case ReaderToolType.ReaderOption:
            case ReaderToolType.Bookmark:
            case ReaderToolType.Activity:
            case ReaderToolType.ExportInteractiveObject:
            case ReaderToolType.StickyNote:
            case ReaderToolType.MathTools:
            case ReaderToolType.ResetLastAnnatation:
                return <BasicReaderToolComponent type={type} clickHandlerParameters={clickHandlerParameters} />;
            case ReaderToolType.Mark:
            case ReaderToolType.Drag:
            case ReaderToolType.Select:
            case ReaderToolType.Delete:
            case ReaderToolType.PageSearcher:
                return <ActivatableReaderToolComponent type={type} currentReaderToolType={readerToolType} />;
            case ReaderToolType.Painting:
            case ReaderToolType.Highlighter:
            case ReaderToolType.Shape:
            case ReaderToolType.Line:
            case ReaderToolType.Expression:
            case ReaderToolType.OfflineReaderToolBox:
                return <RelativePositionComponent type={type} currentReaderToolType={readerToolType} additionalClickHandler={additionalClickHandler} />;
            case ReaderToolType.Bookshelf:
                return <ReaderToolBookshelf type={type} />;
            case ReaderToolType.FourZoomIn:
                return <ReaderToolFourZoomIn type={type} />;
            case ReaderToolType.NumberPicker:
            case ReaderToolType.Whiteboard:
                return <InteractiveObjectContentCommandComponent type={type} command={getInteractiveObjectContentCommand(type)} />;
            case ReaderToolType.PageMode:
                return <ReaderToolPageModeComponent type={type} isDoublePageMode={isDoublePageMode} />;
            case ReaderToolType.Img:
            case ReaderToolType.Text:
                return <ReaderToolInsertComponent type={type} currentReaderToolType={readerToolType} />;
            case ReaderToolType.AttendeeNumber:
                return <ReaderToolActivityAttendeeNumber type={type} attendeeNumber={attendeeNumber} />
            case ReaderToolType.ArrowLeft:
            case ReaderToolType.ArrowRight:
                return <ReaderToolFlipPageComponent type={type} clickHandlerParameters={clickHandlerParameters} />
            default:
                console.log(`unsupported type ${type}`);
        }
    };

    const batchCreateReaderTools = (types = [], payloads = {}) => {
        let results = {};
        for (let type of types) {
            results[type] = createReaderTool(type, payloads[type]);
        }
        return results;
    };

    return { createReaderTool, batchCreateReaderTools };
};
