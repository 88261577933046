import { API } from 'api';
import { replaceBookPage } from 'util/book';
import { Roles } from 'constants/role';
import { isExist } from 'util/helper'


import { IBookContentRepository } from './IBookContentRepository';

function CloudBookContentRepository() { };
CloudBookContentRepository.prototype = Object.create(IBookContentRepository);

CloudBookContentRepository.prototype.fetchBooks = async ({ token, bookIds = [] }) => {
    let books = [];
    try {
        const payload = (token && { token }) || {};
        payload.bookIds = bookIds;
        const response = await API.postJson(`${process.env.REACT_APP_API_DOMAIN}/getBooks`, payload);
        if (response.status === 'success') {
            const promises = response.content.map(async item => {
                const bookUrl = `${process.env.REACT_APP_BOOK_CDN_DOMAIN}/uploadoutput/${item.bookId}/book.json`;
                try {
                    const result = await fetch(bookUrl);
                    const json = await result.json();
                    return { ...json, ...item };
                } catch (err) {
                    return { ...item };
                }
            });
            books = await Promise.all(promises);
        }
    } catch (err) {
        console.error('fetchBooks error', err);
    }
    return books;
};

CloudBookContentRepository.prototype.getPurchasedProducts = async ({ token }) => {
    let books = [];
    try {
        const options = (token && { headers: { Authorization: token } }) || {};
        const response = await API.cloudFuncGet(`${process.env.REACT_APP_API_DOMAIN}/products/purchased`, options);
        console.log('getPurchasedProducts', response);
        if (response.status === 'success') {
            const promises = response.data.map(async item => {
                const bookUrl = `${process.env.REACT_APP_BOOK_CDN_DOMAIN}/uploadoutput/${item.bookId}/book.json`;
                try {
                    const result = await fetch(bookUrl);
                    const json = await result.json();
                    return { ...json, ...item };
                } catch (err) {
                    return { ...item };
                }
            });
            books = await Promise.all(promises);
        }
    } catch (err) {
        console.error('fetchBooks error', err);
    }
    return books;
};

CloudBookContentRepository.prototype.getBookContent = async ({ book, pages = [] }) => {
    let results = [];
    try {
        const pageUrl = `${process.env.REACT_APP_BOOK_CDN_DOMAIN}/uploadoutput/${book.bookId}/`;
        results = await Promise.all(book.pageInfos.filter(page => pages.length === 0 || pages.includes(page.pageIndex)).map(async page => {
            let str = await API.getText(pageUrl + page.html);
            return replaceBookPage({ page, pageUrl, str });
        }));
    } catch (err) {
        console.error('getBookContent error', err);
    }
    return results;
};

CloudBookContentRepository.prototype.getInteractiveObjects = async ({ interactiveObjectId, pages }) => {
    let interactiveObjects = {
        interactiveObjectJSON: {},
        interactiveObjectSVG: {}
    };
    try {
        let results = await Promise.all(pages.map(async page => {
            try {
                const result = await API.getJSON(`${process.env.REACT_APP_RESOURCE_CDN_DOMAIN}/interactiveObjects/${interactiveObjectId}/${page}.dat`);
                return result.rawData;
            } catch {
                return null;
            }
        }));
        results = results.filter(result => result);
        results.forEach(({ pageIndex, json, svg }) => {
            interactiveObjects.interactiveObjectJSON[pageIndex] = json.reduce((acc, v) => {
                acc[v.id] = v;
                return acc;
            }, {});
            interactiveObjects.interactiveObjectSVG[pageIndex] = svg;
        });

        // const response = await API.postJson(`${process.env.REACT_APP_API_DOMAIN}/getInteractiveObjects`, { interactiveObjectId, pages });
        // if (response.status === 'success') {
        //     response.content.forEach(({ pageIndex, json, svg }) => {
        //         interactiveObjects.interactiveObjectJSON[pageIndex] = json.reduce((acc, v) => {
        //             acc[v.id] = v;
        //             return acc;
        //         }, {});
        //         interactiveObjects.interactiveObjectSVG[pageIndex] = svg;
        //     });
        // }
    } catch (err) {
        console.error('getInteractiveObjects error', err);
    }

    return interactiveObjects;
};

CloudBookContentRepository.prototype.getThumbnailUrls = ({ bookId, pages }) => {
    return pages.reduce((acc, page) => {
        acc[page] = `${process.env.REACT_APP_BOOK_CDN_DOMAIN}/uploadoutput/${bookId}/thumbnails/${page}.jpg`;
        return acc;
    }, {});
};

CloudBookContentRepository.prototype.getCoverUrls = ({ bookId }) => {
    return `${process.env.REACT_APP_BOOK_CDN_DOMAIN}/uploadoutput/${bookId}/cover.jpg`;
};

CloudBookContentRepository.prototype.getBookCatalog = async ({ bookId }) => {
    try {
        const res = await fetch(`${process.env.REACT_APP_BOOK_CDN_DOMAIN}/uploadoutput/${bookId}/catalog.json`);
        const result = await res.json();
        return result.content.books;
    } catch (err) {
        console.error('getBookCatalog error', err);
    }
};

CloudBookContentRepository.prototype.getIsShowInteractiveObject = ({ role, interactiveObject }) => {
    if (role === Roles.EDITOR) return true;
    return typeof interactiveObject.isWebVisible === 'undefined' ? true : interactiveObject.isWebVisible
}

export default new CloudBookContentRepository();
