import { useCallback } from 'react';
import { useStore, StoreTypes } from 'context';
import { ReaderToolType } from 'constants/ReaderTools';
import { BaseReaderStrategy } from './BaseReaderStrategy';
import { InteractiveObjectContentType } from 'constants/interactiveObjectContentTypes';

const Blacklist = [
    ReaderToolType.Expression,
    ReaderToolType.OpenActivity,
    ReaderToolType.Bookshelf,
    ReaderToolType.Group,
    ReaderToolType.Activity,
    ReaderToolType.ExportInteractiveObject,
    ReaderToolType.SVG
];

const InteractiveObjectTriggerableWhitelist = [
    InteractiveObjectContentType.Video,
    InteractiveObjectContentType.Audio,
    InteractiveObjectContentType.GoPage,
    InteractiveObjectContentType.WordGrid,
    InteractiveObjectContentType.WordExample,
    InteractiveObjectContentType.WordGlyph,
    InteractiveObjectContentType.WordMeaning,
    InteractiveObjectContentType.Submenu
];

export const useActiveStudentStrategy = () => {
    const [{ isActive }] = useStore(StoreTypes.canvas);

    const getReaderToolComponents = useCallback((readerToolComponents) => {
        return BaseReaderStrategy.getFilteredReaderToolComponents(readerToolComponents, Blacklist);
    }, []);

    const isReaderSwipeable = useCallback(() => {
        return !isActive;
    }, [isActive]);

    const isReaderSwitchPageAvailable = useCallback(() => {
        return true;
    }, []);

    const syncAnnotation = useCallback((id) => {
        // Do nothing
    }, []);

    const isInteractiveObjectTriggerable = useCallback(({ contentType }) => {
        return InteractiveObjectTriggerableWhitelist.some((type) => type === contentType);
    }, []);

    return {
        name: 'ActiveStudentStrategy',
        getReaderToolComponents,
        isReaderSwipeable,
        isReaderSwitchPageAvailable,
        syncAnnotation,
        isInteractiveObjectTriggerable
    };
};
