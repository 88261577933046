import React, { useEffect, useState } from 'react';
import Reader from 'components/Reader/index';
import { useStore, StoreTypes } from 'context';
import {
  ContentShare, useContentShareState, MeetingProvider, useMeetingManager, useMeetingStatus, lightTheme, useAudioVideo, Severity, NotificationProvider, ActionType, useNotificationDispatch
} from 'amazon-chime-sdk-component-library-react';
import { ThemeProvider } from 'styled-components';
import LiveStreamMasterPanel from 'components/LiveStreamMasterPanel';
import LiveStreamViewerPanel from 'components/SideToolContents/LiveStreamViewerPanel'
import LiveStreamAdminPanel from 'components/SideToolContents/LiveStreamAdminPanel'
import { useAnnotationChecker } from 'customHooks/annotationChecker';
import { Roles } from 'constants/role';
import Sidebar from 'components/Sidebar';
import Loading from 'components/Loading'
import styles from './index.module.scss';
import classnames from 'classnames';
import { MeetingStatus } from 'constants/meetingStatus';
import { useSlackWebhook } from 'customHooks/slackWebHook'
import * as types from 'constants/actionTypes';

const Master = React.memo(({ roleType }) => {
  return <Sidebar vertical isShow={true} isFloat={false}>
    <LiveStreamMasterPanel role={roleType} />
  </Sidebar>
})

const Viewer = React.memo(({ roleType ,isStreamPannel}) => {
  return <Sidebar vertical isShow={isStreamPannel} isFloat={false}>
    <LiveStreamViewerPanel role={roleType} />
  </Sidebar >
})

const Admin = React.memo(({ roleType }) => {
  return <Sidebar vertical isShow={true} isFloat={false}>
    <LiveStreamAdminPanel role={roleType} />
  </Sidebar >
})

const LiveStreamSelect = React.memo(({ roomName, userName, userId, chimeRole, roleType ,isStreamPannel}) => {
  if (!roomName || !userName || !userId || !chimeRole) return null;
  let role = "teacher"
  switch (chimeRole) {
    case Roles.TUTOR:
    case Roles.ONECLASS_TEACHER:
      role = "teacher"
      break;
    case Roles.ADMIN:
    case Roles.TUTOR_USER:
    case Roles.ONECLASS_STUDENT:
      role = "student"
      break;
    default:
      break;
  }

  // const roomInfo = {
  //   roomName: roomName,
  //   userName: userName,
  //   role: role,
  //   userId: `${userId}$${chimeRole}`
  // }

  const meetingManager = useMeetingManager();
  const meetingStatus = useMeetingStatus();
  const audioVideo = useAudioVideo();

  const dispatch = useNotificationDispatch();
  const { sendSlackMeetingIssue } = useSlackWebhook();
  let enableConnectPoorNotify = true;
  const connectPoorTimeoutSec = 180;

  const joinMeeting = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_CHIME_API_URL}/join?title=` + encodeURIComponent(roomName) +
      '&name=' + encodeURIComponent(userName) +
      '&region=' + encodeURIComponent('ap-northeast-1') +
      '&role=' + encodeURIComponent(role) +
      '&userId=' + encodeURIComponent(`${userId}$${chimeRole}`),
      {
        method: 'POST'
      },
    );

    const { data } = await response.json();
    const joinData = {
      meetingInfo: data.meeting,
      attendeeInfo: data.attendee
    };
    await meetingManager.join(joinData);
    await meetingManager.start();
  };


  const processMessionEnd = (statusCode) => {
    switch (statusCode) {
      case 2:
        showNotification(Severity.ERROR, "此帳號已在其他裝置上開啟此課堂", false, false);
        break;
      case 6:
        showNotification(Severity.ERROR, "課堂已結束", false, false);
        break;
      case 18://連線逾時中斷
      case 19://不明原因中斷
        showNotification(Severity.ERROR, "連線已中斷，重新連線中", true, false);
        joinMeeting(); //重新加入會議
        break;
    }

  };

  const showNotification = (severity, message, replaceAll = false, autoClose = true) => {
    const payload = {
      severity: severity,
      message: message,
      autoClose: autoClose,
      autoCloseDelay: (severity == Severity.INFO ? 2000 : 5000),
      replaceAll: replaceAll,
    };
    dispatch({
      type: ActionType.ADD,
      payload: payload
    });
  };


  useEffect(() => {


    const myObserver = {
      audioVideoDidStart: () => {
        showNotification(Severity.SUCCESS, '已上線', true);
      },
      audioVideoDidStartConnecting: reconnecting => {
        if (reconnecting) {
          // e.g. the WiFi connection is dropped.
          showNotification(Severity.INFO, '重新連線中', false, false);
        }
        else {
          showNotification(Severity.INFO, '連線中');
        }
      },
      audioVideoDidStop: sessionStatus => {
        const sessionStatusCode = sessionStatus.statusCode();
        showNotification(Severity.ERROR, '連線已中斷');
        sendSlackMeetingIssue("連線已中斷 會議狀態碼:" + sessionStatusCode);
        processMessionEnd(sessionStatusCode);
      },
      connectionDidBecomeGood: () => {
        showNotification(Severity.SUCCESS, '連線品質正常', true);
      },
      connectionDidBecomePoor: () => {
        showNotification(Severity.WARNING, '目前連線品質不佳');
      },
      connectionDidSuggestStopVideo: () => {

        if (enableConnectPoorNotify) {
          enableConnectPoorNotify = false;
          setTimeout(() => {
            enableConnectPoorNotify = true;
          }, connectPoorTimeoutSec * 1000);
          sendSlackMeetingIssue("目前連線品質不佳");
        }
        showNotification(Severity.ERROR, '目前連線品質不佳，對方可能無法取得你的畫面', false, false);
      },
    };



    if (audioVideo) {
      audioVideo.addObserver(myObserver);
    }
  }, [audioVideo, sendSlackMeetingIssue]);

  useEffect(() => {
    joinMeeting();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (meetingStatus === MeetingStatus.Loading) {
    return <Sidebar vertical isShow={true} isFloat={false}>
      <Loading />
    </Sidebar>
  }

  if (meetingStatus === MeetingStatus.Succeeded) {
    switch (chimeRole) {
      case Roles.ONECLASS_TEACHER:
      case Roles.TUTOR:
        return <Master roomName={roomName}
          userName={userName}
          userId={userId}
          chimeRole={"teacher"}
          roleType={roleType}
        />
      case Roles.ONECLASS_STUDENT:
      case Roles.TUTOR_USER:
        return <Viewer roleType={roleType} isStreamPannel={isStreamPannel}/>
      case Roles.ADMIN:
        return <Admin roleType={roleType}
        />
      default:
        break;
    }
  }
})

const ContentSwitch = React.memo(({ role, ...props }) => {
  const { sharingAttendeeId } = useContentShareState();
  return <div className={styles.contentWrapper}>
    {(role === Roles.TUTOR_USER || role === Roles.ADMIN) && sharingAttendeeId && (
      <div className={styles.contentShare}>
        <ContentShare />
      </div>
    )}
    <Reader {...props} />
  </div>
})

const ReaderActivity = (props) => {
  const bookId = props.match.params.bookId;
  let urlParams = new URLSearchParams(props.location.search);
  let paramsRole = urlParams.get('role');
  let paramsActivityId = urlParams.get('activityId');
  let paramsTeacherId = urlParams.get('teacherId');
  let paramsInteractiveObjectId = urlParams.get('interactiveObjectId');
  let roomId = urlParams.get('roomId');
  let studentId = urlParams.get('studentId');
  let timeSpanId = urlParams.get('timeSpanId');
  let code = urlParams.get('code');
  let otp = urlParams.get('otp');
  props = { ...props, bookId, paramsRole, paramsActivityId, paramsTeacherId, paramsInteractiveObjectId, roomId, studentId, timeSpanId, code, otp }
  useAnnotationChecker(props);
  const [{ role, name, userId }] = useStore(StoreTypes.user);
  const [{ chimeRole ,isStreamPannel},courseDispatch] = useStore(StoreTypes.course);
  const { sharingAttendeeId } = useContentShareState();

  useEffect(()=>{
    if(sharingAttendeeId){
      if(isStreamPannel){
        courseDispatch({ type: types.SHOW_STREAM_PANNEL, isStreamPannel:false })
      }
    }else{
      if(!isStreamPannel){
        courseDispatch({ type: types.SHOW_STREAM_PANNEL, isStreamPannel:true })
      }
    }
    
  },[sharingAttendeeId])

  const readerClass = (role) => {
    switch (role) {
      case Roles.ONECLASS_TEACHER:
      case Roles.ONECLASS_STUDENT:
      case Roles.TUTOR:
      case Roles.TUTOR_USER:
      case Roles.ADMIN:
        return styles.studentMode;
      default:
        return styles.otherMode;
    }
  }

  return role && (
    <NotificationProvider>
      <ThemeProvider theme={lightTheme}>
        <div className={classnames(styles.LiveStreamContent, readerClass(role))}>
          <ContentSwitch  {...props} role={chimeRole} />
          {
            chimeRole && <LiveStreamSelect
              roomName={roomId}
              userName={name}
              userId={userId}
              chimeRole={chimeRole}
              roleType={role}
              isStreamPannel={isStreamPannel}
            />
          }
        </div>
      </ThemeProvider>
    </NotificationProvider>
  )
}
export default ReaderActivity

