import { InteractiveObjectContentType } from 'constants/interactiveObjectContentTypes';
import { useExternalWindowCommand } from 'customHooks/InteractiveObjectContentCommands/externalWindowCommand';
import { useInternalWindowCommand } from 'customHooks/InteractiveObjectContentCommands/internalWindowCommand';
import { useToggleDisplayCommand } from 'customHooks/InteractiveObjectContentCommands/toggleDisplayCommand';
import { useControlDisplayCommand } from 'customHooks/InteractiveObjectContentCommands/controlDisplayCommand';
import { useControlStageCommand } from 'customHooks/InteractiveObjectContentCommands/controlStageCommand';
import { useControlImageCommand } from 'customHooks/InteractiveObjectContentCommands/controlImageCommand';
import { useGoPageCommand } from 'customHooks/InteractiveObjectContentCommands/goPageCommand';
import { useVideoCommand } from 'customHooks/InteractiveObjectContentCommands/videoCommand';
import { useAudioCommand } from 'customHooks/InteractiveObjectContentCommands/audioCommand';
import { useAreaZoomCommand } from 'customHooks/InteractiveObjectContentCommands/areaZoomCommand';
import { useInternalHtmlWindowCommand } from 'customHooks/InteractiveObjectContentCommands/internalHtmlWindowCommand';
import { useVocabularyCommand } from 'customHooks/InteractiveObjectContentCommands/vocabularyCommand';
import { useWordGridCommand } from 'customHooks/InteractiveObjectContentCommands/wordGridCommand';
import { useSubmenuCommand } from 'customHooks/InteractiveObjectContentCommands/submenuCommand';
import { useWordExampleCommand } from 'customHooks/InteractiveObjectContentCommands/wordExampleCommand';
import { useWhiteboardCommand } from 'customHooks/InteractiveObjectContentCommands/whiteboardCommand';
import { useNumberPickerCommand } from 'customHooks/InteractiveObjectContentCommands/numberPickerCommand';
import { useSynopsisCommand } from 'customHooks/InteractiveObjectContentCommands/synopsisCommand';
import { useTranslationCommand } from 'customHooks/InteractiveObjectContentCommands/translationCommand';
import { useHearingDraftCommand } from 'customHooks/InteractiveObjectContentCommands/hearingDraftCommand';
import { useFlashCardCommand } from 'customHooks/InteractiveObjectContentCommands/flashCardCommand';
import { useListeningStoryCommand } from 'customHooks/InteractiveObjectContentCommands/ListeningStoryCommand';
import { useWordGlyphCommand } from 'customHooks/InteractiveObjectContentCommands/wordGlyphCommand';
import { useWordMeaningCommand } from 'customHooks/InteractiveObjectContentCommands/wordMeaningCommand';
import { useAboutTheAuthorCommand } from 'customHooks/InteractiveObjectContentCommands/aboutTheAuthorCommand';
import { useReadAloudCommand } from 'customHooks/InteractiveObjectContentCommands/readAloudCommand';
import { useZhuyinLessonCommand } from 'customHooks/InteractiveObjectContentCommands/zhuyinLessonCommand';
import { useZhuyinImageCommand } from 'customHooks/InteractiveObjectContentCommands/zhuyinImageCommand';
import { useZhuyinWordCommand } from 'customHooks/InteractiveObjectContentCommands/zhuyinWordCommand';
import { useVolumeOneWordsCommand } from 'customHooks/InteractiveObjectContentCommands/volumeOneWordsCommand';


export const useInteractiveObjectContentCommandFactory = () => {
  const ExternalWindowCommand = useExternalWindowCommand();
  const InternalWindowCommand = useInternalWindowCommand();
  const ToggleDisplayCommand = useToggleDisplayCommand();
  const ControlDisplayCommand = useControlDisplayCommand();
  const ControlStageCommand = useControlStageCommand();
  const ControlImageCommand = useControlImageCommand();
  const GoPageCommand = useGoPageCommand();
  const VideoCommand = useVideoCommand();
  const AudioCommand = useAudioCommand();
  const AreaZoomCommand = useAreaZoomCommand();
  const InternalHtmlWindowCommand = useInternalHtmlWindowCommand();
  const VocabularyCommand = useVocabularyCommand();
  const WordGridCommand = useWordGridCommand();
  const SubmenuCommand = useSubmenuCommand();
  const WordExampleCommand = useWordExampleCommand();
  const WhiteboardCommand = useWhiteboardCommand();
  const NumberPickerCommand = useNumberPickerCommand();
  const SynopsisCommand = useSynopsisCommand();
  const TranslationCommand = useTranslationCommand();
  const HearingDraftCommand = useHearingDraftCommand();
  const FlashCardCommand = useFlashCardCommand();
  const ListeningStoryCommand = useListeningStoryCommand();
  const WordGlyphCommand = useWordGlyphCommand();
  const WordMeaningCommand = useWordMeaningCommand();
  const AboutTheAuthorCommand = useAboutTheAuthorCommand();
  const ReadAloudCommand = useReadAloudCommand();
  const ZhuyinLessonCommand = useZhuyinLessonCommand();
  const ZhuyinImageCommand = useZhuyinImageCommand();
  const ZhuyinWordCommand = useZhuyinWordCommand();
  const VolumeOneWordsCommand = useVolumeOneWordsCommand();

  const createCommand = ({ contentType }) => {
    switch (contentType) {
      case InteractiveObjectContentType.ExternalWindow:
        return ExternalWindowCommand;
      case InteractiveObjectContentType.InternalWindow:
        return InternalWindowCommand;
      case InteractiveObjectContentType.ToggleDisplay:
        return ToggleDisplayCommand;
      case InteractiveObjectContentType.ControlDisplay:
        return ControlDisplayCommand;
      case InteractiveObjectContentType.ControlStage:
        return ControlStageCommand;
      case InteractiveObjectContentType.ControlImage:
        return ControlImageCommand;
      case InteractiveObjectContentType.GoPage:
        return GoPageCommand;
      case InteractiveObjectContentType.Video:
        return VideoCommand;
      case InteractiveObjectContentType.Audio:
        return AudioCommand;
      case InteractiveObjectContentType.AreaZoom:
        return AreaZoomCommand;
      case InteractiveObjectContentType.InternalHtmlWindow:
        return InternalHtmlWindowCommand;
      case InteractiveObjectContentType.Vocabulary:
        return VocabularyCommand;
      case InteractiveObjectContentType.WordGrid:
        return WordGridCommand;
      case InteractiveObjectContentType.Submenu:
        return SubmenuCommand;
      case InteractiveObjectContentType.WordExample:
        return WordExampleCommand;
      case InteractiveObjectContentType.Whiteboard:
        return WhiteboardCommand;
      case InteractiveObjectContentType.NumberPicker:
        return NumberPickerCommand;
      case InteractiveObjectContentType.Synopsis:
        return SynopsisCommand;
      case InteractiveObjectContentType.Translation:
        return TranslationCommand;
      case InteractiveObjectContentType.Hearing:
        return HearingDraftCommand;
      case InteractiveObjectContentType.FlashCard:
        return FlashCardCommand;
      case InteractiveObjectContentType.Listening:
        return ListeningStoryCommand;
      case InteractiveObjectContentType.WordGlyph:
        return WordGlyphCommand;
      case InteractiveObjectContentType.WordMeaning:
        return WordMeaningCommand;
      case InteractiveObjectContentType.AboutTheAuthor:
        return AboutTheAuthorCommand;
      case InteractiveObjectContentType.ReadAloud:
        return ReadAloudCommand;
      case InteractiveObjectContentType.ZhuyinLesson:
        return ZhuyinLessonCommand;
      case InteractiveObjectContentType.ZhuyinImage:
        return ZhuyinImageCommand;
      case InteractiveObjectContentType.ZhuyinWord:
        return ZhuyinWordCommand;
      case InteractiveObjectContentType.VolumeOneWords:
        return VolumeOneWordsCommand;
      default:
        console.error(`ContentType ${contentType} is not supported`);
        return;
    }
  };

  const CommandFactory = { createCommand };

  return CommandFactory;
};
