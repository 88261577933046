import React, { useEffect } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Provider from './context';
import { AppService } from 'components/AppService';
import { Bookshelf } from 'components/Bookshelf';
import Placeholder from 'components/Placeholder';
import ReaderActivity from 'components/ReaderActivity';
import { MeetingProvider } from 'amazon-chime-sdk-component-library-react';
import { useCourse } from 'customHooks/course';
import { LogLevel } from 'amazon-chime-sdk-js';

const SubscribeBroadcastedEventService = () => {
  const [_, { subscribeBroadcastedEvent }] = useCourse();

  useEffect(() => {
    subscribeBroadcastedEvent();
  }, [subscribeBroadcastedEvent])

  return null;
}


const HookedBrowserRouter = ({ children }) => {

  const postLogConfig = {
    name: process.env.REACT_APP_ONE_TUTOR_PROJECT_ID,
    batchSize: 10,
    intervalMs: 5000,
    url: process.env.REACT_APP_CHIME_LOG_API_URL,
    logLevel: LogLevel.WARN
  };

  return (
    <MeetingProvider postLogConfig={postLogConfig}>
      <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
        <Route>
          {(routeProps) => (
            <Provider router={routeProps}>
              {children}
            </Provider>
          )}
        </Route>
      </BrowserRouter>
    </MeetingProvider>
  )
};

const App = () => {
  return (
    <HookedBrowserRouter>
      <AppService />
      <SubscribeBroadcastedEventService />
      <Switch>
        <Route exact path='/bookshelf' component={Bookshelf} />
        <Route exact path='/loading' component={Placeholder} />
        <Route exact path='/:bookId' component={ReaderActivity} />
        <Redirect path='*' to='/bookshelf' />
      </Switch>
    </HookedBrowserRouter>
  );
};

export default App;

