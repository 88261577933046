
import { useCallback } from 'react';
import { useRefreshReader } from 'customHooks/reader';
import { useReadAnnotations, useCreateAnnotation, useUpdateAnnotation } from 'customHooks/db';
import { useSyncClassPreparationDispatcher } from 'customHooks/syncClassPreparation';
import { useStore, StoreTypes } from 'context';
import Repository from 'repositories/Repository';
import { mergeCanvasJSON } from 'util/svg';
const { PreparationRepository } = Repository;

export const usePreparation = () => {
    const { readAnnotations, readAnnotationById } = useReadAnnotations();
    const createAnnotation = useCreateAnnotation();
    const updateAnnotation = useUpdateAnnotation();
    const { syncClassPreparation } = useSyncClassPreparationDispatcher();
    const [{ firestore }] = useStore(StoreTypes.course);

    const getPreparationList = useCallback(async (bookId, token, annotationType) => {
        const params = { bookIds: [bookId] }
        const cloudData = await PreparationRepository.getPreparationList({ params, token });
        const localData = await readAnnotations({ bookId, annotationType });
        for (let data of cloudData) {
            let { id, bookId, name, marks, type, pageIndex, updatedAt, isDeleted, isDoublePageMode, canvasSVGObjects, extendedContentAnnotation } = data;
            let annotations = [];
            const checkObj = localData.find((localData) => { return data.id === localData.id });


            if (!checkObj) {
                if (!data.isDeleted) {
                    //annotations = await PreparationRepository.getPreparationAnnotations({ id, token });
                    await createAnnotation({
                        id,
                        bookId,
                        name,
                        annotations,
                        marks,
                        type,
                        pageIndex,
                        isDoublePageMode,
                        lastSyncedAt: updatedAt,
                        canvasSVGObjects,
                        extendedContentAnnotation,
                        isDeleted,
                        updatedAt
                    });
                }
            } else if (data.updatedAt > checkObj.lastSyncedAt) {
                if (checkObj.isDirty) {
                    const remoteAnnotations = await PreparationRepository.getPreparationAnnotations({ id, token });
                    annotations = remoteAnnotations.map(record => {
                        let localAnnotation = checkObj.annotations.find(v => v.pageIndex === record.pageIndex);
                        if (localAnnotation) {
                            return {
                                pageIndex: record.pageIndex,
                                annotation: JSON.stringify(mergeCanvasJSON(JSON.parse(record.annotation), JSON.parse(localAnnotation.annotation)))
                            };
                        } else {
                            return record;
                        }
                    });
                    annotations = annotations.concat(checkObj.annotations.reduce((acc, record) => {
                        if (!annotations.some(annotation => annotation.pageIndex === record.pageIndex)) {
                            acc.push(record);
                        }
                        return acc;
                    }, []));
                    marks = marks.concat(checkObj.marks).reduce((acc, mark) => {
                        if (!acc.some(v => v.id === mark.id)) {
                            acc.push(mark);
                        }
                        return acc;
                    }, []);
                    canvasSVGObjects = canvasSVGObjects.concat(checkObj.canvasSVGObjects).reduce((acc, canvasSVGObject) => {
                        if (!acc.some(v => v.id === canvasSVGObject.id)) {
                            acc.push(canvasSVGObject);
                        }
                        return acc;
                    }, []);
                    extendedContentAnnotation = extendedContentAnnotation.concat(checkObj.extendedContentAnnotation)
                    pageIndex = checkObj.pageIndex;
                }
                await updateAnnotation(id, {
                    id,
                    bookId,
                    name,
                    annotations,
                    marks,
                    type,
                    pageIndex,
                    isDoublePageMode,
                    lastSyncedAt: updatedAt,
                    updatedAt,
                    canvasSVGObjects,
                    extendedContentAnnotation,
                    isDeleted,
                    isUpdate: true
                });
            }
        }

        for (let data of localData) {
            const checkObj = (cloudData && cloudData.find((remoteData) => { return data.id === remoteData.id })) || null;
            if (!checkObj) {
                syncClassPreparation(data.id)
            } else {
                if (data.updatedAt > checkObj.updatedAt) {
                    syncClassPreparation(data.id)
                }
            }
        }
        return await readAnnotations({ bookId });
    }, [createAnnotation, readAnnotations, syncClassPreparation, updateAnnotation]);

    const updatePreparationAnnotation = useCallback(async ({ id, token, unconditional = false }) => {
        const localData = await readAnnotationById({ id });
        if (!localData) return;
        if (!unconditional) {
            if (!localData.isUpdate) return;
        }
        localData.annotations = await PreparationRepository.getPreparationAnnotations({ id, token });
        localData.isUpdate = false;
        const annotations = await updateAnnotation(id, localData)
    }, [updateAnnotation, readAnnotationById])

    const copyPreparationAnnotation = useCallback(async ({ annotation, token }) => {
        await PreparationRepository.copyPreparationAnnotations({ annotation, token });
    }, [])

    return {
        getPreparationList,
        updatePreparationAnnotation,
        copyPreparationAnnotation
    };
}

export const usePreparationSnapshot = () => {
    const reducers = useStore();
    const [{ activityInfo: { firestore } }] = reducers[StoreTypes.annotation];
    const [{ userId }] = reducers[StoreTypes.user];
    const updateAnnotation = useUpdateAnnotation();
    const { readAnnotationById } = useReadAnnotations();
    const { refreshReader } = useRefreshReader();

    const preparationSnapshot = useCallback(async (id, token) => {
        if (userId) {
            let preparationRef = firestore.collection(`/teachers/${userId}/classPreparations`).where('id', '==', id);
            preparationRef.onSnapshot(async (snapshot) => {
                const cloudData = await PreparationRepository.getPreparationById({ id, token });
                const localData = await readAnnotationById({ id });
                if (cloudData && localData) {
                    if (cloudData.updatedAt > localData.lastSyncedAt) {
                        const { marks, pageIndex, updatedAt, isDoublePageMode, canvasSVGObjects, extendedContentAnnotation } = cloudData;
                        const annotations = await PreparationRepository.getPreparationAnnotations({ id, token });
                        await updateAnnotation(id, {
                            annotations,
                            marks,
                            pageIndex,
                            lastSyncedAt: updatedAt,
                            updatedAt,
                            isDirty: false,
                            isDoublePageMode,
                            canvasSVGObjects,
                            extendedContentAnnotation
                        });
                        refreshReader(cloudData);
                    }
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId]);

    return { preparationSnapshot };
};
