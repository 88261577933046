import { useState, useReducer, useCallback, useEffect } from 'react';
import { fabric } from 'fabric';
import { useStore, StoreTypes } from 'context';
import * as types from 'constants/actionTypes';
import { useCanvasEvents, useConvertExtendedContentJSONToSVG } from 'customHooks/canvas';
import { EventBusType, useEvent } from 'events/EventBus';
import { PainterEvent, ReaderToolsEvent, CanvasEvent } from 'events/EventTypes';
import { PainterMode } from 'constants/painterModes';
import { canvasReducer, initState } from 'reducers/canvasReducer';
import { SideToolContent } from 'constants/sideToolContents';
import {
  useSaveCanvasJSON
} from 'customHooks/canvas';

const eventBusType = EventBusType.ExtendedContent;

const canvas = new fabric.Canvas('whiteboard-canvas', {
  isDrawingMode: true,
  skipTargetFind: true,
  selection: true
});

export const ExtendedContentEvent = elementRef => {
  const [canvasState, canvasDispatch] = useReducer(canvasReducer, { ...initState, canvas, painterMode: PainterMode.Painting });
  const [{ svgContent, popWindowScale, fontSize, isShowAnswer, toolType }, extendedContentDispatch] = useStore(StoreTypes.extendedContent);
  const [, sideToolDispatch] = useStore(StoreTypes.sideTool);

  const [{ canvasHeight, canvasWidth, isContentInit }, setSize] = useState({ isContentInit: false });

  const { saveExtendedContentCanvasJSON } = useSaveCanvasJSON();
  const { convertExtendedContentCanvasJSONToSVG } = useConvertExtendedContentJSONToSVG();

  const handleResize = useCallback(() => {
    if (!elementRef.current) return;
    const canvasWidth = elementRef.current.clientWidth;
    const canvasHeight = elementRef.current.clientHeight;
    setSize({
      canvasWidth,
      canvasHeight,
      isContentInit: true
    });
  }, [elementRef])

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      extendedContentDispatch({ type: types.IMPORT_EXTENDED_CONTENT_CANVAS_SVG, svgContent: '' });
      window.removeEventListener('resize', handleResize);
    }
  }, [setSize, elementRef, canvasDispatch, extendedContentDispatch, handleResize, canvasWidth, canvasHeight])

  useCanvasEvents({ eventBusType, canvasState, canvasDispatch });

  const canvasImportJSONEventHandler = useCallback(async ({ annotations }) => {
    const { canvas } = canvasState;
    const { annotation, originWidth, originHeight } = annotations;
    const annotationSVG = await convertExtendedContentCanvasJSONToSVG({ annotation: JSON.parse(annotation), originSize: { originWidth, originHeight } })
    await new Promise((resolve) => {
      canvas.loadFromJSON(annotationSVG, () => {
        resolve();
      });
    });
    const svgContent = canvas.toSVG({
      width: canvasWidth, height: canvasHeight, suppressPreamble: true
    });
    extendedContentDispatch({ type: types.IMPORT_EXTENDED_CONTENT_CANVAS_SVG, svgContent });
  }, [convertExtendedContentCanvasJSONToSVG, canvasState, canvasWidth, canvasHeight, extendedContentDispatch])

  const importSVG = useCallback(() => {
    const { canvas } = canvasState;
    const svgContent = canvas.toSVG({
      width: canvasWidth, height: canvasHeight, suppressPreamble: true
    });
    extendedContentDispatch({ type: types.IMPORT_EXTENDED_CONTENT_CANVAS_SVG, svgContent });
    canvasDispatch({ type: types.CANVAS_INACTIVATE });
  }, [canvasHeight, canvasState, canvasWidth, extendedContentDispatch]);


  const painterEventHandler = useCallback(({ painterMode, painterToolType }) => {

    canvasDispatch({
      type: types.CANVAS_CHANGE_PAINTER_MODE,
      painterMode,
      painterToolType
    });

  }, []);

  const canvasTextObjectSelectedEventHandler = useCallback(({ activeCanvasObject, sideToolDirection }) => {
    canvasDispatch({
      type: types.SET_CANVAS_ACTIVE_OBJECT,
      activeCanvasObject
    });
    sideToolDispatch({
      type: types.SET_TEXT_SIDE_TOOL_SHOW,
      isTextSideToolShow: true,
      textSideToolDirection: sideToolDirection
    })
  }, [canvasDispatch, sideToolDispatch])

  const eraseAllEventHandler = useCallback(() => {
    canvasDispatch({ type: types.CANVAS_ERASE_ALL });
    extendedContentDispatch({ type: types.IMPORT_EXTENDED_CONTENT_CANVAS_SVG, svgContent: '' });
  }, [extendedContentDispatch]);

  const changeBrushTypeHandler = useCallback(({ brushType }) => {
    canvasDispatch({ type: types.CHANGE_DRAWING_BRUSH, changeDrawingBrush: brushType });
  }, [canvasDispatch]);

  const changeBrushWidthHandler = useCallback(({ lineWidth }) => {
    canvasDispatch({ type: types.CANVAS_DRAWING_BRUSH_LINE_WIDTH, changeLineWidth: lineWidth });
  }, [canvasDispatch]);

  const changeBrushColorHandler = useCallback(({ color }) => {
    canvasDispatch({ type: types.CANVAS_CHANGE_COLOR, changeColorRgb: color, changeColorHex: color });
  }, [canvasDispatch]);

  const changePainterTypeHandler = useCallback(({ painterType }) => {
    canvasDispatch({ type: types.CANVAS_CHANGE_PAINTER_TYPE, painterType });
  }, [canvasDispatch]);

  const fillTypeChangeHandler = useCallback(({ fillType }) => {
    canvasDispatch({ type: types.CANVAS_CHANGE_SHAPE_FILL_TYPE, fillType });
  }, [canvasDispatch]);

  const dragEventHandler = useCallback(() => {
    importSVG();
  }, [importSVG])

  const changePopWindowScaleHandler = useCallback(({ popWindowScale }) => {
    importSVG();
    extendedContentDispatch({
      type: types.CHANGE_EXTENDED_CONTENT_SCALE, payload: {
        popWindowScale
      }
    });
  }, [extendedContentDispatch, importSVG])


  const changePopWindowFontSizeHandler = useCallback(({ fontSize }) => {
    extendedContentDispatch({
      type: types.SET_EXTENDED_CONTENT_FONT_SIZE,
      payload: {
        fontSize
      }
    });
  }, [extendedContentDispatch]);


  const changePopWindowAnswersHandler = useCallback(payload => {
    extendedContentDispatch({
      type: types.SET_EXTENDED_CONTENT_SHOW_ANSWERS,
      payload
    });
  }, [extendedContentDispatch]);

  const setReaderToolTypeEventHandler = useCallback(
    ({ toolType }) => {
      extendedContentDispatch({ type: types.SET_EXTENDED_CONTENT_READER_TOOL_TYPE, payload: { toolType } });
    },
    [extendedContentDispatch]
  );

  const modifyCanvasObjectPropertyHandler = useCallback(
    async ({ property, value }) => {
      const activeObjects = canvas.getActiveObjects();
      activeObjects.forEach(object => {
        if (object.type === 'group' && (!object.extra || !object.extra.stamp)) {
          const items = object.getObjects();
          switch (property) {
            case 'fill':
            case 'stroke':
              items.forEach(item =>
                item.set({ [property]: value }).setCoords()
              );
              break;
            default:
              break;
          }
        }
        object.set({ [property]: value }).setCoords();

      });

      canvas.renderAll();
    },
    []
  );

  const canvasFinishPaintingEventHandler = useCallback(async ({ canvasSVGObjectId }) => {
    const annotation = await convertExtendedContentCanvasJSONToSVG({ annotation: canvas.toJSON() })
    await saveExtendedContentCanvasJSON({ annotation: JSON.stringify(annotation), canvasSVGObjectId });
  }, [convertExtendedContentCanvasJSONToSVG, saveExtendedContentCanvasJSON]);

  const canvasTextCreatedEventHandler = useCallback(
    ({ object }) => {
      canvasDispatch({ type: types.CANVAS_ACTIVATE });
      canvasDispatch({ type: types.ADD_OBJECT, object });
    },
    [canvasDispatch]
  );

  const canvasSelectionClearEventHandler = useCallback(() => {
    sideToolDispatch({
      type: types.SET_TEXT_SIDE_TOOL_SHOW,
      isTextSideToolShow: false
    });
    sideToolDispatch({
      type: types.SET_SIDE_TOOL_CONTENT,
      sideToolContent: SideToolContent.None
    });
    // canvasDispatch({
    //   type: types.SET_CANVAS_ACTIVE_OBJECT,
    //   activeCanvasObject: null
    // });
  }, [canvasDispatch, sideToolDispatch]);

  useEvent(
    {
      eventBusType,
      event: CanvasEvent.CanvasTextCreatedEvent
    },
    canvasTextCreatedEventHandler
  );

  useEvent({ eventBusType, event: CanvasEvent.CanvasFinishPaintingEvent }, canvasFinishPaintingEventHandler);
  useEvent({ eventBusType, event: CanvasEvent.CanvasImportJSONEvent }, canvasImportJSONEventHandler);
  const changelineTypeHandler = useCallback(({ lineType }) => {
    canvasDispatch({ type: types.CANVAS_CHANGE_LINE_TYPE, lineType });
  }, [])

  useEvent({ eventBusType, event: ReaderToolsEvent.ClickDragEvent }, dragEventHandler);
  useEvent([
    { eventBusType, event: ReaderToolsEvent.ClickPainterEvent },
    { eventBusType, event: ReaderToolsEvent.ClickSelectEvent },
    { eventBusType, event: ReaderToolsEvent.ClickEraserEvent },
    { eventBusType, event: ReaderToolsEvent.ClickInsertTextEvent }
  ], painterEventHandler);

  useEvent(
    { eventBusType, event: CanvasEvent.CanvasTextObjectSelectedEvent },
    canvasTextObjectSelectedEventHandler
  );
  // useEvent({ eventBusType, event: ReaderToolsEvent.ClickInsertTextEvent }, clickInsertTextHandler);
  useEvent({ eventBusType, event: ReaderToolsEvent.ClickEraseAllEvent }, eraseAllEventHandler);
  useEvent({ eventBusType, event: PainterEvent.ChangeBrushTypeEvent }, changeBrushTypeHandler);
  useEvent({ eventBusType, event: PainterEvent.ChangeBrushWidthEvent }, changeBrushWidthHandler);
  useEvent({ eventBusType, event: PainterEvent.ChangeBrushColorEvent }, changeBrushColorHandler);
  useEvent({ eventBusType, event: PainterEvent.ChangePainterTypeEvent }, changePainterTypeHandler);
  useEvent({ eventBusType, event: PainterEvent.ChangeShapeFillTypeEvent }, fillTypeChangeHandler);
  useEvent({ eventBusType, event: PainterEvent.ChangelineTypeEvent }, changelineTypeHandler);
  useEvent({ eventBusType, event: ReaderToolsEvent.ChangePopWindowScaleEvent }, changePopWindowScaleHandler);
  useEvent({ eventBusType, event: ReaderToolsEvent.ChangePopWindowFontSizeEvent }, changePopWindowFontSizeHandler);
  useEvent({ eventBusType, event: ReaderToolsEvent.ChangePopWindowAnswersEvent }, changePopWindowAnswersHandler);
  useEvent({ eventBusType, event: ReaderToolsEvent.SetReaderToolTypeEvent }, setReaderToolTypeEventHandler);
  useEvent({ eventBusType, event: CanvasEvent.ModifyCanvasObjectPropertyEvent }, modifyCanvasObjectPropertyHandler);
  useEvent({ eventBusType, event: CanvasEvent.CanvasSelectionClearEvent }, canvasSelectionClearEventHandler);
  return [
    {
      canvasState,
      canvasHeight,
      canvasWidth,
      isContentInit,
      svgContent,
      popWindowScale,
      fontSize,
      isShowAnswer,
      toolType
    },
    {
      canvasDispatch,
      extendedContentDispatch
    }
  ]
}
