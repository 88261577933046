import React from 'react';
import classnames from 'classnames';
import styles from './index.module.scss';

const Radio = ({ className, name, label, value, currentValue, onChange }) => (
  <label
    className={classnames(styles.radio, className, {
      [styles.active]: value === currentValue
    })}
  >
    <div className={styles.dot}></div>
    <input name={name} value={value} type="radio" onChange={onChange} hidden />
    <div className={styles.label}>{label}</div>
  </label>
);

export default Radio;
