
import React, { useState, useEffect, useCallback } from 'react';
import { useStore, StoreTypes } from 'context';
import { DeviceType } from 'constants/chimeTypes'
import styles from './index.module.scss';
import { Roles } from 'constants/role';
import ChatRoom from 'components/ChatRoom';
import classnames from 'classnames';
import SelectComponent from 'components/SelectComponent'
import VolumeMeter from 'components/Tools/VolumeMeter'
import Icon from 'components/Icon';
import { useChimeError } from 'customHooks/chimeError';
import { useSlackWebhook } from 'customHooks/slackWebHook'
import Button from '@material-ui/core/Button';
import * as types from 'constants/actionTypes';
import {
    RemoteVideo,
    LocalVideo,
    useVideoInputs,
    useSelectVideoInputDevice,
    useAudioInputs,
    useAudioOutputs,
    useLocalVideo,
    useToggleLocalMute,
    useLocalAudioOutput,
    useMeetingManager,
    useRemoteVideoTileState,
    useSelectVideoQuality,
    useAudioVideo,
    Notification,
    Severity,
    useRosterState,
    useNotificationState,
    useNotificationDispatch,
    NotificationProvider,
    NotificationGroup,
    ActionType,
    SignalStrength
} from 'amazon-chime-sdk-component-library-react';

export const TeacherVideo = React.memo(({ isFull }) => {
    const meetingManager = useMeetingManager();
    const meetingId = meetingManager.meetingId;
    const [attendeeId, setAttendeeId] = useState('');
    const [signalStrength, setSignalStrength] = useState('');
    const [tileId, setTileId] = useState('');
    const { attendeeIdToTileId } = useRemoteVideoTileState();
    const { sendSlackMeetingIssue } = useSlackWebhook();
    const audioVideo = useAudioVideo();
    let enableConnectPoorNotify = true;
    const connectPoorTimeoutSec = 180;

    const bindAttendeeCallback = (audioVideo, attendeeId) => {
        audioVideo.realtimeSubscribeToVolumeIndicator(
            attendeeId,
            (attendeeId, volume, muted, signalStrength, externalUserId) => {
                if (signalStrength === null) {
                    // signalStrength has not changed
                    return;
                }
                if (signalStrength == 0 && enableConnectPoorNotify) {
                    enableConnectPoorNotify = false;
                    setTimeout(() => {
                        enableConnectPoorNotify = true;
                    }, connectPoorTimeoutSec * 1000);
                    sendSlackMeetingIssue(`收到${externalUserId}訊號不良資訊`);
                }
                setSignalStrength(signalStrength);
            }
        );
    }

    useEffect(() => {
        if (!meetingId || attendeeId) return;
        const getTeacherAttendeeId = async () => {
            const response =
                await fetch(`${process.env.REACT_APP_CHIME_API_URL}/getTeacher?meetingId=${meetingId}`);
            const { data } = await response.json();
            if (data) {
                setAttendeeId(data.AttendeeId)
            }
        }

        getTeacherAttendeeId();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [meetingId, attendeeIdToTileId])


    useEffect(() => {
        if (!audioVideo || !attendeeId) return;

        bindAttendeeCallback(audioVideo, attendeeId);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [attendeeId, audioVideo])


    useEffect(() => {

        //不明原因.有時會吐出undefined.導致前端顯示問題.因此過濾掉
        if (attendeeIdToTileId[attendeeId] !== undefined)
            setTileId(attendeeIdToTileId[attendeeId]);

    }, [attendeeId, attendeeIdToTileId])


    return attendeeId && tileId ?
        <>
            <RemoteVideo tileId={tileId} />
            {
                !isFull && <VolumeMeter className={styles.volumeMeter} width={50} height={15} attendeeId={attendeeId} />
            }
            <SignalStrength className={classnames(styles.signal, GetSignalStrengthCssName(signalStrength))} />
        </>
        : null
})

const GetSignalStrengthCssName = (signalStrength) => {
    if (signalStrength == 1)
        return styles.signalHigh;
    else if (signalStrength == 0)
        return styles.signalLow;
    else
        return styles.signalMedium
};

const LiveStreamViewerPanel = ({ role }) => {
    const [{ rewardInfo, isFullVideo ,isStreamPannel},courseDispatch] = useStore(StoreTypes.course);
    const { fireworks, good, like, trophy } = rewardInfo;

    const meetingManager = useMeetingManager();
    const selectVideoQuality = useSelectVideoQuality();
    const audioVideo = useAudioVideo();

    const { isVideoEnabled, toggleVideo } = useLocalVideo();
    const { devices: videoInputDevices } = useVideoInputs();
    const selectVideoInput = useSelectVideoInputDevice();

    const { devices: audioInputDevices } = useAudioInputs();
    const { devices: audioOutputDevices } = useAudioOutputs();

    const { muted, toggleMute } = useToggleLocalMute();
    const { isAudioOn, toggleAudio } = useLocalAudioOutput();

    // const { roster } = useRosterState();
    // const attendees = Object.values(roster);

    const selfAttendeeId = meetingManager.configuration.credentials.attendeeId;
    const { processDeviceError, showNotification } = useChimeError();
    const { notifications } = useNotificationState();
    const dispatch = useNotificationDispatch();

    const notificationItems =
        notifications.map(({ id, ...rest }) => (
            <Notification
                key={id}
                {...rest}
                onClose={() => dispatch({ type: ActionType.REMOVE, payload: id })}
            />
        ));

    useEffect(() => {
        let audioError = false;
        let videoError = false;
        if (audioInputDevices.length == 0 || audioInputDevices.some(item => item.label.length == 0))
            audioError = true;
        else if (videoInputDevices.length == 0 || videoInputDevices.some(item => item.label.length == 0))
            videoError = true;

        if (videoError) {
            processDeviceError(`攝影機`, "PermissionDeniedError");
        }
        if (audioError) {
            processDeviceError(`麥克風`, "PermissionDeniedError");
        }

    }, [audioInputDevices, videoInputDevices])


    useEffect(() => {
        const attendeeChangedObserver = async (presentAttendeeId, present, externalUserId) => {
            let roleType;
            if (presentAttendeeId.includes("#content")) //表示為螢幕分享
                return;

            if (externalUserId)
                roleType = externalUserId.split("$")[1];
            if (roleType == Roles.TUTOR) {
                if (present)
                    showNotification(Severity.INFO, "對方已上線")
                else
                    showNotification(Severity.WARNING, "對方已離線", true)
            }

        };
        if (audioVideo) {
            //  audioVideo.addObserver(myObserver);
            audioVideo.realtimeSubscribeToAttendeeIdPresence(attendeeChangedObserver);
        }
    }, [audioVideo]);


    useEffect(() => {
        if (!audioVideo)
            return;

        isFullVideo ? audioVideo.chooseVideoInputQuality(480, 270, 15, 500) : audioVideo.chooseVideoInputQuality(160, 120, 15, 300);

        //重起攝影機才會生效
        (async () => {
            if (isVideoEnabled) {
                await restartLocalVideoTile(audioVideo, meetingManager);
                return;
            }

            try {
                await toggleVideo();
            }
            catch (error) {
                processDeviceError(`攝影機`, error);
            }
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFullVideo])

    const restartLocalVideoTile = async (audioVideo, meetingManager) => {
        const observer = {
            videoTileDidUpdate: tileState => {
                // Ignore a tile without attendee ID and other attendee's tile.
                if (!tileState.boundAttendeeId || !tileState.localTile) {
                    return;
                }
                console.log("!tileState.active:" + !tileState.active);
                if (!tileState.active) {
                    (async () => {
                        await audioVideo.chooseVideoInputDevice(meetingManager.selectedVideoInputDevice);
                        audioVideo.startLocalVideoTile();
                        audioVideo.removeObserver(observer);
                    })();
                }
            }
        };
        audioVideo.addObserver(observer);
        audioVideo.stopLocalVideoTile();
    };


    const formatDeviceValue = useCallback(devices => devices.map(item => (
        { label: item.label, value: item.deviceId }
    )
    ), [])


    const onVideoInputChange = async (deviceId) => {
        if (!audioVideo || !meetingManager)
            return;
        //先關閉再開啟
        try {
            await audioVideo.chooseVideoInputDevice(null);
            await audioVideo.chooseVideoInputDevice(deviceId);
            audioVideo.startLocalVideoTile();
        }
        catch (error) {
            processDeviceError(`攝影機`, error);
        }
    }

    const onAudioInputChange = async (deviceId) => {
        await meetingManager.selectAudioInputDevice(deviceId).catch((error) => {
            processDeviceError(`麥克風`, error);
        });
    }

    return (
        <div className={classnames(styles.discussionPanel, styles.wrapper)}>
            <div className={styles.showHidePannelBtn}>
                <Button
                    style={{
                        border: "solid",
                        color: "white",
                        backgroundColor: "cadetblue",
                        borderWidth: 2
                    }}
                    onClick={() => {
                        courseDispatch({ type: types.SHOW_STREAM_PANNEL, isStreamPannel:!isStreamPannel })
                    }}
                    color="primary"
                >
                    {isStreamPannel?"隱藏":"顯示"}
                </Button>
            </div>
            <div id="content" className={styles.chatContent}>
                <div className={styles.toolsControls}>
                    {
                        videoInputDevices.length > 0 &&
                        <div className={styles.localVideoSelect}>
                            <div onClick={toggleVideo}>
                                {
                                    isVideoEnabled ?
                                        <Icon name="OpenVideo" /> :
                                        <Icon name="CloseVideo" />
                                }
                            </div>
                            {
                                videoInputDevices.some(item => item.label.length == 0) ?
                                    <span>請檢查攝影機權限</span>
                                    :
                                    <SelectComponent
                                        type={DeviceType.VideoDevice}
                                        optionsList={formatDeviceValue(videoInputDevices)}
                                        defaultSelectText={videoInputDevices[0].label}
                                        onChange={onVideoInputChange}
                                    />
                            }
                        </div>
                    }
                    {
                        audioInputDevices.length > 0 &&
                        <div className={styles.localSound}>
                            <div onClick={toggleMute}>
                                {
                                    muted ?
                                        <Icon name="CloseAudioInput" /> :
                                        <Icon name="OpenAudioInput" />
                                }
                            </div>
                            {
                                audioInputDevices.some(item => item.label.length == 0) ?
                                    <span>請檢查麥克風權限</span>
                                    :
                                    <SelectComponent
                                        type={DeviceType.AudioInputDevice}
                                        optionsList={formatDeviceValue(audioInputDevices)}
                                        defaultSelectText={audioInputDevices[0] && audioInputDevices[0].label}
                                        onChange={onAudioInputChange}
                                    />}
                        </div>
                    }
                    {
                        audioOutputDevices.length > 0 &&
                        <div className={styles.localAudio}>
                            <div onClick={toggleAudio}>
                                {
                                    isAudioOn ?
                                        <Icon name="OpenAudioOutput" /> :
                                        <Icon name="CloseAudioOutput" />
                                }
                            </div>
                            <SelectComponent
                                type={DeviceType.AudioOutputDevice}
                                optionsList={formatDeviceValue(audioOutputDevices)}
                                defaultSelectText={audioOutputDevices[0] && audioOutputDevices[0].label}
                                onChange={meetingManager.selectAudioOutputDevice}
                            />
                        </div>
                    }
                </div>
                <div className={classnames(styles.localVideo, { [styles.localVideoHide]: isFullVideo, [styles.localVideoBG]: isFullVideo })}>
                    {
                        !isFullVideo && <TeacherVideo isFull={isFullVideo} />
                    }
                    <div className={classnames(styles.fullVideoMessage, { [styles.fullVideoMessageHide]: !isFullVideo })} >
                        <Icon name="StudentVideoIsFull" />
                    </div>
                </div>

                <div className={styles.localVideo}>
                    <LocalVideo />
                    <VolumeMeter className={styles.volumeMeter} width={50} height={15} attendeeId={selfAttendeeId} />
                </div>
                {/* <div className={styles.remoteVideoContainer}>
                                {teacherId && <RemoteVideoGroup isContentShareEnabled={false} viewMode="Room" />}
                            </div> */}
                {
                    (role === Roles.TUTOR_USER || role === Roles.ADMIN) &&
                    <div className={styles.emojiCounts}>
                        <div className={styles.emojiCount}>
                            <div><Icon name="FireworksIcon" /></div>
                            <div className={styles.countText}>{fireworks && fireworks}</div>
                        </div>
                        <div className={styles.emojiCount}>
                            <div><Icon name="GoodIcon" /></div>
                            <div className={styles.countText}>{good && good}</div>
                        </div>
                        <div className={styles.emojiCount}>
                            <div><Icon name="LikeIcon" /></div>
                            <div className={styles.countText}>{like && like}</div>
                        </div>
                        <div className={styles.emojiCount}>
                            <div><Icon name="TrophyIcon" /></div>
                            <div className={styles.countText}>{trophy && trophy}</div>
                        </div>
                    </div>
                }
                <div className={classnames(styles.chatRoomWrapper, { [styles.tutoruser]: role === Roles.TUTOR_USER || role === Roles.ADMIN })}>
                    <ChatRoom />
                </div>
                <div >
                    <NotificationGroup>
                        {notificationItems}
                    </NotificationGroup>
                </div>
            </div>
        </div>
    )
}



export default LiveStreamViewerPanel;
