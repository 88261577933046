import React from 'react';
import Input from 'components/common/Input';
import { EventBus } from 'events/EventBus';
import { CanvasEvent } from 'events/EventTypes';
import styles from './index.module.scss';


export const HearingComponent = ({ object }) => {
  let hearingInfo = object.hearingInfo ? object.hearingInfo : 0

  const changeHandler = () => e => {
      hearingInfo = e.target.value;
      EventBus.emit({ event: CanvasEvent.ModifyCanvasObjectPropertyEvent, payload: { object, property: "hearingInfo", value: parseInt(hearingInfo) } });
  };

  return (
    <>
      <Input className={styles.vocabularyInput} label="預設頁籤" value={hearingInfo} onChange={changeHandler()} />
    </>
  )
};