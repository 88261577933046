import React, { useCallback, useRef, useEffect } from 'react';
import { useStore, StoreTypes } from 'context';
import Card from './Card';
import ControlsButton from 'components/common/ControlsButton';
import AudioControlsButton from 'components/common/AudioControlsButton';
import Icon from 'components/Icon';
import VocabularyInfo from 'components/VocabularyExample/VocabularyInfo';
import AudioButton from 'components/common/AudioButton';
import TranslationControlsButton from 'components/common/TranslationControlsButton';
import useSetState from 'customHooks/setState';
import { shuffle } from 'util/array';
import classnames from 'classnames';
import styles from './index.module.scss';
import Repository from 'repositories/Repository';
const { ExtendedResourceRepository } = Repository;

const CONTENT_TYPE = {
  EXAMPLE: 'EXAMPLE',
  VOCABULARY: 'VOCABULARY'
};

const STEP_TYPE = {
  FIRST: 1,
  SECOND: 2,
  THIRD: 3
};

const ORDER_TYPE = {
  NORMAL: 'NORMAL',
  RANDOM: 'RANDOM'
};

// 250ms for animate transition delay
const SPEED_DATA_TYPE = [
  { label: '快', value: 1500 },
  { label: '中', value: 3500 },
  { label: '慢', value: 5500 }
];


const delay = interval => {
  return new Promise(resolve => {
    setTimeout(resolve, interval);
  });
};

const FirstStepComponent = ({ onClick }) => {
  return (
    <>
      <Card
        onClick={() => onClick(CONTENT_TYPE.VOCABULARY)}
        className={styles.hoverCard}
      >
        <div className={styles.cardTitle}>單字版</div>
      </Card>
      <Card
        onClick={() => onClick(CONTENT_TYPE.EXAMPLE)}
        className={styles.hoverCard}
      >
        <div className={styles.cardTitle}>單字例句版</div>
      </Card>
    </>
  );
};

const SecondStepComponent = ({ onClick }) => {
  return (
    <>
      <Card
        className={styles.hoverCard}
        onClick={() => onClick(ORDER_TYPE.NORMAL)}
      >
        <div className={styles.cardTitle}>In Order (按照課本排序)</div>
      </Card>
      <Card
        className={styles.hoverCard}
        onClick={() => onClick(ORDER_TYPE.RANDOM)}
      >
        <div className={styles.cardTitle}>Random (隨機)</div>
      </Card>
    </>
  );
};

const VocabularyComponent = ({
  bookId,
  content,
  isShowTranslation
}) => {
  const audioBtn = useRef();
  return (
    <div className={styles.vocabulary}>
      <AudioButton
        ref={audioBtn}
        className={styles.audioButton}
        src={ExtendedResourceRepository.getDataSrc({
          bookId,
          pathName: content.wordSound
        })}
      >
        <Icon type="text" name="volumeUp" />
      </AudioButton>
      <div className={styles.word}>{content.word}</div>
      <VocabularyInfo
        className={styles.KK}
        kkContent={content.KK}
        partOfSpeech={content.partOfSpeech}
      />
      <div
        className={classnames(styles.tw, {
          [styles.show]: isShowTranslation
        })}
      >
        {content.wordTranslation}
      </div>
    </div>
  )
}

const ExampleComponent = ({
  bookId,
  content,
  isShowTranslation,
  isCardRotated,
  isAutoPlay,
  onExampleSoundEnded,
  isWordSoundEnded
}) => {

  const audioBtn = useRef();

  useEffect(() => {
    isWordSoundEnded && isCardRotated && isAutoPlay && audioBtn.current && audioBtn.current.click();
  }, [content, isAutoPlay, isCardRotated, isWordSoundEnded]);

  return (
    <div className={styles.example}>
      <div className={styles.word}>
        <div className={styles.voca}>{content.word}</div>
      </div>
      <div className={styles.info}>
        <VocabularyInfo
          className={styles.KK}
          kkContent={content.KK}
          partOfSpeech={content.partOfSpeech}
        />
        <div
          className={classnames(styles.tw, {
            [styles.show]: isShowTranslation
          })}
        >
          {content.wordTranslation}
        </div>
      </div>
      <div className={styles.exampleSentence}>

        <AudioControlsButton
          ref={audioBtn}
          src={ExtendedResourceRepository.getDataSrc({
            bookId,
            pathName: content.exampleSound
          })}
          onEnded={() => onExampleSoundEnded()}
        />
        <div className={styles.exampleSentenceContent}>
          {Array.isArray(content.example) ? (
            content.example.map(item =>
              <p dangerouslySetInnerHTML={{ __html: item }} key={item} />
            )
          ) : (
              <p dangerouslySetInnerHTML={{ __html: content.example }} />
            )}
          {Array.isArray(content.exampleTranslation) ? (
            content.exampleTranslation.map(item => (
              <p
                key={item}
                className={classnames(styles.tw, {
                  [styles.show]: isShowTranslation
                })}
              >
                {item}
              </p>
            ))
          ) : (
              <p
                className={classnames(styles.tw, {
                  [styles.show]: isShowTranslation
                })}
              >
                {content.exampleTranslation}
              </p>
            )}
        </div>
      </div>
    </div >
  );
};

const ThirdStepComponent = ({
  contentType,
  content,
  rotate,
  isAutoPlay,
  isShowTranslation,
  toggleShowTranslation,
  isWordSoundEnded,
  onExampleSoundEnded
}) => {
  const [{ bookId }] = useStore(StoreTypes.books);

  return (
    <div className={styles.stepThirdSection}>
      <div
        className={classnames(styles.flipper, {
          [styles.rotate]: rotate
        })}
      >
        <Card className={classnames(styles.side, styles.front, {
          [styles.hide]: rotate
        })}>

          <div className={styles.decoIcon}>
            <img src="assets/img/fox.png" alt="" />
          </div>
          <div className={styles.image}>
            {
              content.image ? (<img src={ExtendedResourceRepository.getDataSrc({
                bookId,
                pathName: content.image
              })}
                alt=""
              />) : 
                <div className={styles.word}>
                  {content.word}
                </div>
            }
          </div>
        </Card>
        <Card className={classnames(styles.side, styles.back)}>
          <div className={styles.decoIcon}>
            <img src="assets/img/fox.png" alt="" />
          </div>
          <div className={styles.content}>
            <TranslationControlsButton
              className={styles.translationButton}
              active={isShowTranslation}
              content="中文"
              onClick={toggleShowTranslation}
            />
            {contentType === CONTENT_TYPE.VOCABULARY ? (
              <VocabularyComponent
                bookId={bookId}
                content={content}
                isShowTranslation={isShowTranslation}
                isCardRotated={rotate}
                isAutoPlay={isAutoPlay}
              />
            ) : (
                <ExampleComponent
                  bookId={bookId}
                  content={content}
                  isShowTranslation={isShowTranslation}
                  isCardRotated={rotate}
                  isAutoPlay={isAutoPlay}
                  onExampleSoundEnded={onExampleSoundEnded}
                  isWordSoundEnded={isWordSoundEnded}
                />
              )}
          </div>
        </Card>
      </div>
    </div>
  );
};

const FlashCard = ({ data }) => {
  const [{ fontSize }] = useStore(StoreTypes.extendedContent);
  const [{ bookId }] = useStore(StoreTypes.books);
  const [
    {
      cardDatas,
      currentIndex,
      isAutoPlay,
      speed,
      step,
      contentType,
      orderType,
      isCardRotated,
      isWordSoundEnded,
      isAudioEnded,
      isShowTranslation
    },
    setState
  ] = useSetState({
    cardDatas: [],
    currentIndex: 0,
    isAutoPlay: false,
    speed: SPEED_DATA_TYPE[1].value,
    step: STEP_TYPE.FIRST,
    contentType: null,
    orderType: ORDER_TYPE.NORMAL,
    isCardRotated: false,
    isWordSoundEnded: false,
    isAudioEnded: false,
    isShowTranslation: false
  });

  const wordSoundButtonRef = useRef();

  useEffect(() => {
    const cardDatas = orderType === ORDER_TYPE.RANDOM ? shuffle(data) : data;
    setState({ cardDatas });
  }, [data, orderType, setState]);

  const contentTypeSelectHandler = contentType => {
    setState({ step: STEP_TYPE.SECOND, contentType });
  };

  const sortTypeHandler = orderType => {
    setState({ step: STEP_TYPE.THIRD, orderType });
  };

  const backHandler = useCallback(
    e => () => {
      const newStep = Math.min(step - 1, 1);
      setState({ step: newStep, currentIndex: 0, isCardRotated: false });
    },
    [setState, step]
  );

  const toggleFlipHandler = useCallback(
    () => () => {
      setState({ isCardRotated: !isCardRotated });
    },
    [isCardRotated, setState]
  );

  const flipToBack = useCallback(() => {
    setState({ isCardRotated: true });
  }, [setState]);

  const flipToFront = useCallback(() => {
    setState({ isCardRotated: false });
  }, [setState]);

  useEffect(() => {

  }, [currentIndex, setState])

  const nextPage = useCallback(
    () => async () => {
      const max = data.length - 1;
      setState({ isCardRotated: false, isShowTranslation: false, isWordSoundEnded: false })
      await delay(250); // for animate transition delay
      setState({ currentIndex: Math.min(currentIndex + 1, max) });
    },
    [currentIndex, data.length, setState]
  );

  const prevPage = useCallback(
    () => async () => {
      setState({ isCardRotated: false, isShowTranslation: false, isWordSoundEnded: false })
      await delay(250); // for animate transition delay
      setState({ currentIndex: Math.max(currentIndex - 1, 0) });
    },
    [currentIndex, setState]
  );

  const toggleShowTranslation = useCallback(() => {
    setState({ isShowTranslation: !isShowTranslation });
  },
    [isShowTranslation, setState]
  );

  const toggleAutoPlayHandler = useCallback(
    () => () => {
      setState({ isAutoPlay: !isAutoPlay });
    },
    [isAutoPlay, setState]
  );

  const speedChangeHandler = useCallback(
    value => () => {
      setState({ speed: value });
    },
    [setState]
  );

  const playWordSound = useCallback(() => {
    wordSoundButtonRef.current && wordSoundButtonRef.current.click();
  }, [])

  useEffect(() => {
    if (!isAutoPlay) return;
    const autoPlay = async () => {
      await delay(speed);
      if (!isAutoPlay) return;
      flipToBack();
      await delay(speed / 2);
      if (!isAutoPlay) return;
      setState({ isShowTranslation: true });
      playWordSound();
    };
    autoPlay();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex, flipToBack, isAutoPlay, playWordSound, setState])


  useEffect(() => {
    const autoNextPageHandler = async () => {
      if (!isAutoPlay || !isAudioEnded) return;
      setState({ isAudioEnded: false })
      await delay(speed);
      if (!isAutoPlay) return;
      nextPage()();
    }
    autoNextPageHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAudioEnded, isAutoPlay])


  useEffect(() => {
    if (isAutoPlay && currentIndex === cardDatas.length - 1) {
      setState({ currentIndex: 0, isCardRotated: false })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardDatas.length, isAutoPlay, setState])


  const onExampleSoundEnded = () => {
    setState({ isAudioEnded: true })
  }


  const wordSoundPlayEndedHandler = () => {
    contentType === CONTENT_TYPE.VOCABULARY ?
      setState({ isAudioEnded: true }) :
      setState({ isWordSoundEnded: true });
  }

  return (
    <div className={classnames(styles.flashCard, styles[fontSize])}>
      <div className={styles.cardContainer}>
        {step === STEP_TYPE.FIRST && (
          <FirstStepComponent
            onClick={type => contentTypeSelectHandler(type)}
          />
        )}
        {step === STEP_TYPE.SECOND && (
          <SecondStepComponent onClick={type => sortTypeHandler(type)} />
        )}
        {step === STEP_TYPE.THIRD && cardDatas[currentIndex] && (
          <ThirdStepComponent
            rotate={isCardRotated}
            contentType={contentType}
            content={cardDatas[currentIndex]}
            onClick={type => contentTypeSelectHandler(type)}
            isAutoPlay={isAutoPlay}
            isWordSoundEnded={isWordSoundEnded}
            onExampleSoundEnded={() => onExampleSoundEnded()}
            isShowTranslation={isShowTranslation}
            toggleShowTranslation={() => toggleShowTranslation()}
            speed={speed}
          />
        )}
      </div>
      <div className={styles.controlBar}>
        {
          step === STEP_TYPE.THIRD &&
          <div
            className={classnames(styles.controlsButtonGroup, styles.middle)}
          >
            {
              isAutoPlay ? (
                <>
                  <div className={styles.controlBarLabel}>翻面速度</div>
                  {SPEED_DATA_TYPE.map(item => (
                    <ControlsButton
                      key={item.value}
                      active={item.value === speed}
                      className={classnames(styles.controlsButton, styles.speedControls)}
                      onClick={speedChangeHandler(item.value)}
                    >
                      {item.label}
                    </ControlsButton>
                  ))}
                </>
              ) : (
                  <>
                    <ControlsButton
                      className={styles.controlsButton}
                      onClick={toggleFlipHandler()}
                    >
                      <img
                        src="assets/img/handpaper.png"
                        className={classnames(styles.handpaper, styles.rotate)}
                        alt=""
                      />
                    </ControlsButton>
                    <ControlsButton
                      disabled={currentIndex === 0}
                      className={styles.controlsButton}
                      onClick={prevPage()}
                    >
                      <Icon type="text" name="arrowLeft" />
                    </ControlsButton>
                    <ControlsButton
                      disabled={currentIndex === cardDatas.length - 1}
                      className={styles.controlsButton}
                      onClick={nextPage()}
                    >
                      <Icon type="text" name="arrowRight" />
                    </ControlsButton>
                    <ControlsButton
                      className={styles.controlsButton}
                      onClick={toggleFlipHandler()}
                    >
                      <img src="assets/img/handpaper.png" className={styles.handpaper} alt="" />
                    </ControlsButton>
                  </>
                )
            }
            <AudioControlsButton
              ref={wordSoundButtonRef}
              className={classnames(styles.controlsButton, {
                [styles.hide]: isAutoPlay
              })}
              src={ExtendedResourceRepository.getDataSrc({
                bookId,
                pathName: cardDatas[currentIndex].wordSound
              })}
              onEnded={() => wordSoundPlayEndedHandler()}
            />
          </div>
        }

        <div className={classnames(styles.controlsButtonGroup, styles.right, {
          [styles.show]: step === STEP_TYPE.THIRD || step > STEP_TYPE.FIRST
        })}>
          {step === STEP_TYPE.THIRD && (
            <>
              <ControlsButton
                active={isAutoPlay}
                className={styles.controlsButton}
                onClick={toggleAutoPlayHandler()}
              >
                <div>自動</div>
                <div>播放</div>
              </ControlsButton>
              <ControlsButton
                active={!isAutoPlay}
                className={styles.controlsButton}
                onClick={toggleAutoPlayHandler()}
              >
                <div>手動</div>
                <div>操作</div>
              </ControlsButton>
            </>
          )}
          {step > STEP_TYPE.FIRST && (
            <ControlsButton
              className={styles.controlsButton}
              onClick={backHandler()}
            >
              <Icon type="text" name="undo" />
            </ControlsButton>
          )}
        </div>

      </div>
    </div>
  );
};

export default FlashCard;
