import React, { useCallback, useState, useRef, useEffect } from 'react';
import Button from 'components/Button/index';
import Icon from 'components/Icon';
import PainterPanel from 'components/Painter/PainterPanel';
import { EventBus, EventBusType } from 'events/EventBus';
import { ReaderToolsEvent, PainterEvent, ReaderEvent } from 'events/EventTypes';
import { PainterMode } from 'constants/painterModes';
import { PainterToolType } from 'constants/painterTypes';
import { ReaderToolType } from 'constants/ReaderTools';
import useModal from 'components/common/Modal/useModal';
import ContextMenu from 'components/ContextMenu';
import useContextMenu from 'customHooks/contextMenu';
import { useStore, StoreTypes } from 'context'
import SidePanel from 'components/SidePanel';
import TextPanel from 'components/TextPanel';
import * as types from 'constants/actionTypes';
import styles from './index.module.scss';
import { ExtendedContentType } from '../ExtendedContentContainer';

const PanelState = {
  None: 'None',
  Pen: 'Pen',
  Highlighter: 'Highlighter',
  Shape: 'Shape',
  Line: 'Line'
};

const useExtendedContentToolsEvent = ({ eventBusType, canvasState }) => {
  const { activeCanvasObject, painterToolType, painterTool, fillType, canvasSVGObjectId } = canvasState;
  const eventHandler = useCallback(
    ({ event, painterMode, painterToolType, contentType }) => {
      EventBus.emit({
        eventBusType,
        event,
        payload: { painterMode, painterToolType, contentType, canvasSVGObjectId }
      });
    },
    [eventBusType, canvasSVGObjectId]
  );

  const setReaderToolType = type => {
    EventBus.emit({
      eventBusType,
      event: ReaderToolsEvent.SetReaderToolTypeEvent,
      payload: {
        toolType: type
      }
    });
  }
  const clickDragEvent = () => {
    eventHandler({
      event: ReaderToolsEvent.ClickDragEvent
    });
    setReaderToolType(ReaderToolType.Drag)
  }

  const clickSelectEvent = () => {
    eventHandler({
      event: ReaderToolsEvent.ClickSelectEvent,
      painterMode: PainterMode.Selection
    });
    setReaderToolType(ReaderToolType.Select)
  }

  const clickPainterEvent = painterToolType => {
    eventHandler({
      event: ReaderToolsEvent.ClickPainterEvent,
      painterMode: PainterMode.Painting,
      painterToolType
    });
    setReaderToolType(painterToolType)
  };

  const clickEraseAllEvent = () =>
    eventHandler({ event: ReaderToolsEvent.ClickEraseAllEvent });

  const clickEraserEvent = () => {
    eventHandler({
      event: ReaderToolsEvent.ClickEraserEvent,
      painterMode: PainterMode.Eraser
    });
    setReaderToolType(ReaderToolType.Delete)
  }

  const clickWhiteboardEvent = () => {
    EventBus.emit({
      event: ReaderToolsEvent.CreateCourseInteractiveItemEvent,
      payload: { painterMode: PainterMode.Selection, contentType: ReaderToolType.Whiteboard }
    });
    setReaderToolType(ReaderToolType.Select);
  }

  const clickTextEvent = () => {
    eventHandler({
      event: ReaderToolsEvent.ClickInsertTextEvent,
      painterMode: PainterMode.InsertText,
      painterToolType
    });
    setReaderToolType(ReaderToolType.Text)
  }

  return [
    { activeCanvasObject, painterToolType, painterTool, fillType },
    {
      clickDragEvent,
      clickSelectEvent,
      clickPainterEvent,
      clickEraseAllEvent,
      clickEraserEvent,
      clickWhiteboardEvent,
      clickTextEvent
    }
  ];
};

export const ExtendedContentContextMenu = ({ eventBusType, canvasState }) => {
  const [, {
    clickDragEvent,
    clickSelectEvent,
    clickPainterEvent,
    clickEraseAllEvent,
    clickEraserEvent,
    clickWhiteboardEvent,
    clickTextEvent
  }] = useExtendedContentToolsEvent({ eventBusType, canvasState });

  const contextMenuRef = useRef();

  const [isMenuVisible] = useContextMenu(contextMenuRef);

  return (
    <ContextMenu.Box ref={contextMenuRef} isVisible={isMenuVisible}>
      <ContextMenu.Item onClick={clickDragEvent}>滑鼠</ContextMenu.Item>
      <ContextMenu.Item onClick={clickSelectEvent}>選取</ContextMenu.Item>
      <ContextMenu.Item onClick={() => clickPainterEvent(PainterToolType.Pen)}>
        畫筆
      </ContextMenu.Item>
      <ContextMenu.Item
        onClick={() => clickPainterEvent(PainterToolType.Highlighter)}
      >
        螢光筆
      </ContextMenu.Item>
      <ContextMenu.Item onClick={clickEraseAllEvent}>全部刪除</ContextMenu.Item>
      <ContextMenu.Item onClick={clickEraserEvent}>部分刪除</ContextMenu.Item>
    </ContextMenu.Box>
  );
};

export const ExtendedContentTools = ({
  extendedContentType,
  eventBusType,
  canvasState,
  popWindowScale,
  disablePopWindowScale,
  children,
  onClose
}) => {
  const [, { closeModal }] = useModal();
  const [panelState, setPanelState] = useState(PanelState.None);
  const panelCloseHandler = useCallback(() => {
    setPanelState(PanelState.None)
  },[setPanelState]);
  const [
    { activeCanvasObject, painterToolType, painterTool, fillType },
    {
      clickDragEvent,
      clickSelectEvent,
      clickPainterEvent,
      clickEraseAllEvent,
      clickEraserEvent,
      clickWhiteboardEvent,
      clickTextEvent
    }
  ] = useExtendedContentToolsEvent({ eventBusType, canvasState });

  const [{ isTextSideToolShow, textSideToolDirection }, sideToolDispatch] = useStore(StoreTypes.sideTool);

  const onColorChange = color => {
    EventBus.emit({
      eventBusType,
      event: PainterEvent.ChangeBrushColorEvent,
      payload: { color }
    });
  };

  const onLineWidthChange = lineWidth => {
    EventBus.emit({
      eventBusType,
      event: PainterEvent.ChangeBrushWidthEvent,
      payload: { lineWidth }
    });
  };

  const onPainterChangeHandler = useCallback(painterType => {
    EventBus.emit({
      eventBusType,
      event: PainterEvent.ChangePainterTypeEvent,
      payload: { painterType }
    });
  }, [eventBusType]);

  const onBrushChange = useCallback(
    e => {
      EventBus.emit({
        eventBusType,
        event: PainterEvent.ChangeBrushTypeEvent,
        payload: { brushType: e.target.value }
      });
    },
    [eventBusType]
  );

  const onFillTypeChangeHandler = useCallback(
    fillType => {
      EventBus.emit({
        eventBusType,
        event: PainterEvent.ChangeShapeFillTypeEvent,
        payload: { fillType }
      });
    },
    [eventBusType]
  );

  const onPopWindowScaleChangeHandler = useCallback(popWindowScale => {
    EventBus.emit({
      eventBusType,
      event: ReaderToolsEvent.ChangePopWindowScaleEvent,
      payload: { popWindowScale }
    });
  }, [eventBusType])

  const setReaderToolType = useCallback(type => {
    EventBus.emit({
      eventBusType,
      event: ReaderToolsEvent.SetReaderToolTypeEvent,
      payload: {
        toolType: type
      }
    });
  }, [eventBusType])


  const onLineTypeChangeHandler = useCallback(lineType => {
    EventBus.emit({
      eventBusType,
      event: PainterEvent.ChangelineTypeEvent,
      payload: { lineType }
    });
  }, [eventBusType])


  const onCloseHandler = useCallback(() => {
    EventBus.emit({
      eventBusType: EventBusType.Default,
      event: ReaderEvent.SetCanvasSVGObjectIdEvent,
      payload: { canvasSVGObjectId: null }
    });

    //解開會造成，設定不可被畫筆點擊的物件時，在畫筆開啟小視窗關閉後，可以摸到
    // EventBus.emit({ 
    //   event: ReaderToolsEvent.ClickDragEvent
    // });
    setReaderToolType(ReaderToolType.Drag)
    onPopWindowScaleChangeHandler(1);
    sideToolDispatch({
      type: types.SET_TEXT_SIDE_TOOL_SHOW,
      isTextSideToolShow: false
    });
    onClose ? onClose() : closeModal();
  }, [closeModal, onClose, onPopWindowScaleChangeHandler, setReaderToolType, sideToolDispatch])


  return (
    <div className={styles.extended_content_tools}>
      {
      panelState === PanelState.Pen &&
        <PainterPanel.Pen
          toolType={painterToolType}
          painterTool={painterTool[painterToolType]}
          onPanelClose={panelCloseHandler}
          onLineWidthChange={onLineWidthChange}
          onColorChange={onColorChange}
          onPainterHandler={onPainterChangeHandler}
          onBrushChange={onBrushChange}
          onLineTypeChange={onLineTypeChangeHandler}
          onShapeChange={onPainterChangeHandler}
          onFillTypeChange={onFillTypeChangeHandler}
          shapeFillTypeValue={fillType}
        />
      }
      {panelState === PanelState.Highlighter &&
        <PainterPanel.Pen
          toolType={painterToolType}
          painterTool={painterTool[painterToolType]}
          onPanelClose={panelCloseHandler}
          onLineWidthChange={onLineWidthChange}
          onColorChange={onColorChange}
          onPainterHandler={onPainterChangeHandler}
          onBrushChange={onBrushChange}
          onLineTypeChange={onLineTypeChangeHandler}
          onShapeChange={onPainterChangeHandler}
          onFillTypeChange={onFillTypeChangeHandler}
          shapeFillTypeValue={fillType}
        />}
      {panelState === PanelState.Shape &&
        <PainterPanel.Shape
          toolType={painterToolType}
          painterTool={painterTool[painterToolType]}
          onPanelClose={panelCloseHandler}
          onLineWidthChange={onLineWidthChange}
          onColorChange={onColorChange}
          onShapeChange={onPainterChangeHandler}
          onFillTypeChange={onFillTypeChangeHandler}
          shapeFillTypeValue={fillType}
        />}
      {panelState === PanelState.Line &&
        <PainterPanel.Line
          toolType={painterToolType}
          painterTool={painterTool[painterToolType]}
          onPanelClose={panelCloseHandler}
          onLineWidthChange={onLineWidthChange}
          onColorChange={onColorChange}
          onLineTypeChange={onLineTypeChangeHandler}
          onPainterHandler={onPainterChangeHandler}
        />}
      {
        isTextSideToolShow && activeCanvasObject&&(
          <SidePanel direction={textSideToolDirection}>
            <TextPanel eventBusType={EventBusType.ExtendedContent} activeCanvasObject={activeCanvasObject} />
          </SidePanel>
        )
      }
      <div className={styles.toolsWrapper}>
        <div className={styles.closebox}>
          <Button title="關閉" onClick={onCloseHandler}>
            <Icon name="Close" />
            關閉
          </Button>
        </div>
        <div className={styles.toolbox}>
          <div className={styles.toolBtn}>
            <Button onClick={clickDragEvent} title="滑鼠">
              <Icon name="MouseHand" />
              <div className={styles.text}>滑鼠</div>
            </Button>
          </div>
          <div className={styles.toolBtn}>
            <Button onClick={clickSelectEvent} title="選取">
              <Icon name="Select" />
              <div className={styles.text}>選取</div>
            </Button>
          </div>
          <div className={styles.toolBtn}>
            <Button
              onClick={() => {
                clickPainterEvent(PainterToolType.Pen);
                setPanelState(PanelState.Pen);
              }}
              title="畫筆"
            >
              <Icon name="Painting" />
              <div className={styles.text}>畫筆</div>
            </Button>
          </div>
          <div className={styles.toolBtn}>
            <Button
              onClick={() => {
                clickPainterEvent(PainterToolType.Highlighter);
                setPanelState(PanelState.Highlighter);
              }}
              title="螢光筆"
            >
              <Icon name="Highlighter" />
              <div className={styles.text}>螢光筆</div>
            </Button>
          </div>
          <div className={styles.toolBtn}>
            <Button
              onClick={() => {
                clickPainterEvent(PainterToolType.Shape);
                setPanelState(PanelState.Shape);
              }}
              title="形狀"
            >
              <Icon name="Shape" />
              <div className={styles.text}>形狀</div>
            </Button>
          </div>
          <div className={styles.toolBtn}>
            <Button
              onClick={() => {
                clickPainterEvent(PainterToolType.Line);
                setPanelState(PanelState.Line);
              }}
              title="線段"
            >
              <Icon name="Line" />
              <div className={styles.text}>線段</div>
            </Button>
          </div>
          <div className={styles.toolBtn}>
            <Button onClick={clickTextEvent} title="新增文字">
              <Icon name="Text" />
              <div className={styles.text}>新增文字</div>
            </Button>
          </div>
          <div className={styles.toolBtn}>
            <Button onClick={clickEraseAllEvent} title="全部刪除">
              <Icon name="DeleteAll" />
              <div className={styles.text}>全部刪除</div>
            </Button>
          </div>
          <div className={styles.toolBtn}>
            <Button onClick={clickEraserEvent} title="部分刪除">
              <Icon name="Delete" />
              <div className={styles.text}>部分刪除</div>
            </Button>
          </div>
          {
            extendedContentType === ExtendedContentType.Whiteboard &&
            <div className={styles.toolBtn}>
              <Button onClick={clickWhiteboardEvent} title="儲存">
                <Icon name="Save" />
                <div className={styles.text}>儲存</div>
              </Button>
            </div>
          }
        </div>
        <div className={styles.rightbox}>
          {/* {
            disablePopWindowScale ? (
              <div className={styles.toolBtn}>
                {
                  popWindowScale === 1 ? (
                    <Button title="縮小" onClick={() => onPopWindowScaleChangeHandler(0.5)}>
                      <Icon name="WindowZoomOut" />
                      縮小
                </Button>
                  ) : (
                      <Button title="放大" onClick={() => onPopWindowScaleChangeHandler(1)}>
                        <Icon name="WindowZoomIn" />
                        放大
                  </Button>
                    )
                }
              </div>) : ""
          } */}
          {children}
          <div className={styles.toolBtn}>
            <Button title="關閉" onClick={onCloseHandler}>
              <Icon name="Close" />
              關閉
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
