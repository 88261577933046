import React, { useEffect, useRef, useCallback } from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';
import styles from './index.module.scss';
import { useStore, StoreTypes } from 'context';
import { EventBus } from 'events/EventBus';
import { InteractiveObjectEvent } from 'events/EventTypes';
import { preserveSVGAspectRatio } from 'util/book';
import Repository from 'repositories/Repository';


const { BookContentRepository } = Repository;

export const InteractiveObjectView = ({
  svgString,
  pageIndex,
  bookInfo,
  interactiveObjectState,
  setInteractiveObjectState,
  interactiveObjectJSON
}) => {

  const [{ isDoublePageMode, isMarkModeShow, fullWidthInfo }] = useStore(
    StoreTypes.reader
  );
  const [{ role }] = useStore(StoreTypes.user);
  const [{ musicModal: { activeObjectId } }] = useStore(StoreTypes.global);
  const [{ isActive }] = useStore(StoreTypes.canvas);


  const ref = useRef(null);
  const downRef = useRef(null);
  const { width, height, LRFlip } = bookInfo;
  const { userAgent } = navigator;

  useEffect(() => {
    const node = ReactDOM.findDOMNode(ref.current);
    if (!node) return;

    const clonedNode = node.cloneNode(true);
    const clonedSvgNode = clonedNode.querySelector('svg');
    const downNode = ReactDOM.findDOMNode(downRef.current);

    if (!downNode.querySelector('svg')) {
      downNode.appendChild(clonedSvgNode);
    } else {
      downNode.replaceChild(clonedSvgNode, downNode.querySelector('svg'));
    }

    const groups = node.querySelectorAll('g');
    let pageIndexInfo = [pageIndex];
    let interactiveObjects;
    let interactiveObjectsHidden = [];
    let interactiveObjectClickable = [];

    for (let obj in interactiveObjectJSON) {
      if (~pageIndexInfo.indexOf(parseInt(obj))) {
        const interactiveObjectArr = Object.values(interactiveObjectJSON[obj]);
        interactiveObjectArr.forEach(item => {
          if (!BookContentRepository.getIsShowInteractiveObject({ role, interactiveObject: item })) {
            interactiveObjectsHidden.push(item.id)
          }

          if (item.isClickable === true) {
            interactiveObjectClickable.push(item.id)
          }

        })
        interactiveObjects = interactiveObjectJSON[obj]
        break;
      }
    }

    const checkMouseOver = (function (group) {
      return this.interactiveObjects.some(data => data.id === group.id && data.mouseOver);
    }).bind({ interactiveObjects: Object.values(interactiveObjects) });

    let targetObjectId = '';
    const cloneGroups = clonedSvgNode.querySelectorAll('g');
    groups.forEach((group, index) => {
      if (group.id && group.id.match(/^[\d|a-zA-Z]+$/) ) {

        const element = cloneGroups[index];
        if (interactiveObjectsHidden.includes(group.id)) {
          group.style.display = 'none';
          element.style.display = 'none';
        }

        const addClickable = () => {
          group.classList.add(styles.clickable);
          element.classList.add(styles.clickable);
        }

        const removeClickable = () => {
          group.classList.remove(styles.clickable);
          element.classList.remove(styles.clickable);
        }

        if(!isActive){
          addClickable();
        } else {
          if (interactiveObjectClickable.includes(group.id)) {
            addClickable();
          } else {
            removeClickable();
          }
        }
        
        if (checkMouseOver(group)) {
          const subGroup = group.querySelector('g');
          if (subGroup) {
            element.querySelector('g').style.opacity = '0';
          }
          element.querySelectorAll('rect').forEach(target => {
            target.style["mix-blend-mode"] = 'null';
            target.style["opacity"] = '0';
          })
        }

        const handler = event => {
          if (targetObjectId !== group.id) return;
          if (!interactiveObjectClickable.includes(group.id) && isActive) return;
          event.preventDefault();
          EventBus.emit({
            event: InteractiveObjectEvent.ClickInteractiveObjectEvent,
            payload: {
              id: group.id,
              pageIndex,
              isDoublePageMode,
              interactiveObjectState,
              setInteractiveObjectState,
              target: event.target
            }
          });
        };

        const mouseOverHandler = event => {
          const subGroup = group.querySelector('g');
          if (subGroup) {
            element.querySelector('g').style.opacity = '1';
          }
          element.querySelectorAll('rect').forEach(target => {
            target.style["mix-blend-mode"] = 'multiply';
            target.style["opacity"] = 0.3;
          })
        };
        const mouseOutHandler = event => {
          const subGroup = group.querySelector('g');
          if (subGroup) {
            element.querySelector('g').style.opacity = '0';
          }
          element.querySelectorAll('rect').forEach(target => {
            target.style["mix-blend-mode"] = 'null';
            target.style["opacity"] = '0';
          })
        };

        const startHandler = () => {
          targetObjectId = group.id
        }

        if (/Android/i.test(userAgent) || /iPhone|iPad|iPod/i.test(userAgent)) {
          group.ontouchstart = startHandler;
          group.ontouchend = handler;
        } else {
          group.onpointerdown = startHandler;
          group.onpointerup = handler;
          if (checkMouseOver(group)) {
            group.onmouseover = mouseOverHandler;
            group.onmouseout = mouseOutHandler;
          }
        }

        if (activeObjectId.indexOf(group.id) > -1) {
          mouseOverHandler();
        }

      }
    });

  }, [activeObjectId, isDoublePageMode, interactiveObjectState, pageIndex, setInteractiveObjectState, userAgent, fullWidthInfo.mode, interactiveObjectJSON, role, isActive]);

  const toggleInteractive = useCallback(node => {
    if (!node) return;
    for (let item of interactiveObjectState.state) {
      item.pageIndex.forEach(pageIndex => {
        const groups = node.querySelectorAll('g');
        if (groups) {
          for (let displayIndex in item.display) {
            const display = item.display[displayIndex];
            for (let group of groups) {
              if (group.id === display.id) {
                for (let child of group.children) {
                  if (child.style) {
                    child.style.opacity = display.opacity;
                  }
                }
                break;
              }
            }
          }
          for (let stageIndex in item.stage) {
            const stage = item.stage[stageIndex];
            let phases = [];
            for (let group of groups) {
              if (stage.phase.includes(group.id)) {
                phases.push(group);
              }
            }
            phases.forEach((phase, i) => {
              const phaseIndex = stage.phase.indexOf(phase.id);
              if (~phaseIndex) {
                const element = phase.children[0];
                element.style.opacity =
                  stage.revealStage ^ (phaseIndex >= stage.current);
              }
            });
          }
          for (let imageIndex in item.image) {
            const image = item.image[imageIndex];
            for (let group of groups) {
              if (group.id === image.id) {
                const element = group.children[0];
                element.setAttributeNS(
                  'http://www.w3.org/1999/xlink',
                  'href',
                  image.src
                );
                // replace image from item.src
                break;
              }
            }
          }
        }
      });
    }
  }, [interactiveObjectState.state])

  useEffect(() => {
    const refNode = ReactDOM.findDOMNode(ref.current);
    const downRefNode = ReactDOM.findDOMNode(downRef.current);
    toggleInteractive(refNode);
    toggleInteractive(downRefNode)

  }, [interactiveObjectState, fullWidthInfo.mode, toggleInteractive]);

  return (
    <>
      <div
        className={classnames(
          styles.svgContent,
          styles.hide,
          styles[
          isMarkModeShow
            ? 'canvasObjectSavedSvgContentDisable'
            : 'canvasObjectSavedSvgContentEnable'
          ]
        )}
        ref={ref}
        dangerouslySetInnerHTML={{
          __html: preserveSVGAspectRatio({
            svg: svgString,
            width,
            height,
            LRFlip,
            pageIndex,
            isDoublePageMode
          })
        }}
      />
      <div className={classnames(
        styles.svgContent,
        styles[
        isMarkModeShow
          ? 'canvasObjectSavedSvgContentDisable'
          : 'canvasObjectSavedSvgContentEnable'
        ],
        styles.down
      )} ref={downRef} />
    </>
  );
};
