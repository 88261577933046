import CloudBookContentRepository from './BookContentRepositories/CloudBookContentRepository';
import FileBookContentRepository from './BookContentRepositories/FileBookContentRepository';
import CloudExtendedResourceRepository from './ExtendedResourceRepositories/CloudExtendedResourceRepository';
import FileExtendedResourceRepository from './ExtendedResourceRepositories/FileExtendedResourceRepository';
import CloudPreparationRepository from './PreparationRepositories/CloudPreparationRepository';
import FilePreparationRepository from './PreparationRepositories/FilePreparationRepository';
import CloudReaderToolBarRepository from './ReaderToolBarRepositories/CloudReaderToolBarRepository';
import FileReaderToolBarRepository from './ReaderToolBarRepositories/FileReaderToolBarRepository';
import FileClassNameRepository from './ClassNameRepositories/FileClassNameRepository';
import CloudClassNameRepository from './ClassNameRepositories/CloudClassNameRepository';
import CloudBookshelfRepository from './BookshelfRepowitories/CloudBookshelfRepository';
import FileBookshelfRepository from './BookshelfRepowitories/FileBookshelfRepository';

export const fileExpressHost = process.env.REACT_APP_EXPRESS_HOST || 'localhost';
export const fileExpressPort = (window.electron && window.electron.remote.getGlobal('fileExpressPort')) || process.env.REACT_APP_EXPRESS_PORT || 54321;

const Repository = {
    classNameRepository: window.electron ? FileClassNameRepository : CloudClassNameRepository,
    BookContentRepository: window.electron ? FileBookContentRepository : CloudBookContentRepository,
    ExtendedResourceRepository: window.electron ? FileExtendedResourceRepository : CloudExtendedResourceRepository,
    PreparationRepository: window.electron ? FilePreparationRepository : CloudPreparationRepository,
    ReaderToolBarRepository: window.electron ? FileReaderToolBarRepository : CloudReaderToolBarRepository,
    BookshelfRepository: window.electron ? FileBookshelfRepository : CloudBookshelfRepository,
};

export default Repository;
