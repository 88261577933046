import React from 'react';
import Input from 'components/common/Input';
import { EventBus } from 'events/EventBus';
import { CanvasEvent } from 'events/EventTypes';
import styles from './index.module.scss';


export const WordGridComponent = ({ object }) => {
  // const [wordGridInfo,setWordGridInfo] = useSetState(object.wordGridInfo ? JSON.parse(object.wordGridInfo) : { src: '', word: '' });
  let wordGridInfo = object.wordGridInfo ? JSON.parse(object.wordGridInfo) : { src: '', word: '', wordsSetSrc: '', idiomSetSrc: '' }

  const changeHandler = infoType => e => {
    // setWordGridInfo({ [infoType]: e.target.value });
    wordGridInfo[infoType] = e.target.value;
    EventBus.emit({ event: CanvasEvent.ModifyCanvasObjectPropertyEvent, payload: { object, property: "wordGridInfo", value: JSON.stringify(wordGridInfo) } });
  };

  return (
    <>
      <Input className={styles.wordGridInput} label="Src" value={wordGridInfo.src} onChange={changeHandler('src')} />
      <Input className={styles.wordGridInput} label="Word" value={wordGridInfo.word} onChange={changeHandler('word')} />
      <br />
      <label htmlFor="defaultOpacity">延伸教學</label>
      <Input className={styles.wordGridInput} label="語詞列表" value={wordGridInfo.wordsSetSrc} onChange={changeHandler('wordsSetSrc')} />
      <Input className={styles.wordGridInput} label="成語列表" value={wordGridInfo.idiomSetSrc} onChange={changeHandler('idiomSetSrc')} />
    </>
  )
};
