import React, { useRef } from 'react';

import { DefaultPanelColors } from 'constants/colors';
import { PainterToolType } from 'constants/painterTypes';
import { useStore, StoreTypes } from 'context';

import ColorSelector from 'components/Painter/ColorSelector';
import LineWidthSelector from 'components/Painter/LineWidthSelector';
import ShapeSelector from 'components/Painter/ShapeSelector';
import FormGroup from 'components/common/FormGroup';
import Icon from 'components/Icon';
import PainterOptionSelector from 'components/Painter/PainterOptionSelector';

import { useOutsideAlerter } from 'customHooks/outsideAlerter';

import classnames from 'classnames';
import styles from './index.module.scss';

const PainterPanel = ({ className, children, onClose }) => {
  const [{ readerToolDirection }] = useStore(StoreTypes.reader);
  const panelRef = useRef(null);

  useOutsideAlerter(panelRef, onClose);
  return (
    <div
      ref={panelRef}
      className={classnames(
        styles.popover,
        styles[readerToolDirection],
        className
      )}
    >
      <div className={styles.closeBtn} onClick={onClose}>
        <Icon type="text" name="times" />
      </div>
      {children}
    </div>
  );
};

const PenPanel = ({
  className,
  onPanelClose,
  toolType,
  painterTool: { painterType, lineWidth, colorHex, lineType },
  onLineWidthChange = () => {},
  onColorChange = () => {},
  onLineTypeChange = () => {},
  onPainterHandler = () => {},
  onShapeChange = () => {},
  onFillTypeChange = () => {},
  shapeFillTypeValue
}) => {
  return (
    <PainterPanel className={classnames(styles.painterPanel,className)} onClose={onPanelClose}>
      <div className={styles.group}>
        <FormGroup label="顏色" vertical>
          <ColorSelector
            opacity={toolType ===  PainterToolType.Highlighter ? 0.35 : 1}
            colors={DefaultPanelColors}
            value={colorHex}
            onChange={onColorChange}
          />
        </FormGroup>
        <FormGroup label="線條粗細" vertical>
          <LineWidthSelector value={lineWidth} onChange={onLineWidthChange} />
        </FormGroup>
      </div>
      <div className={styles.group}>
        <FormGroup label="畫筆選項" vertical>
          <PainterOptionSelector 
            lineType={lineType}
            painterType={painterType} 
            onPainterChange={onPainterHandler}
            onLineTypeChange={onLineTypeChange}
          />
        </FormGroup>
        <FormGroup label="形狀選項" vertical>
          <ShapeSelector
            painterType={painterType}
            onChange={onShapeChange}
            onFillTypeChange={onFillTypeChange}
            fillType={shapeFillTypeValue}
          />
        </FormGroup>
      </div>
    </PainterPanel>
  );
};

const ShapePanel = ({
  className,
  onPanelClose,
  painterTool: { lineWidth, colorHex, painterType },
  onLineWidthChange = () => {},
  onColorChange = () => {},
  onShapeChange = () => {},
  onFillTypeChange = () => {},
  shapeFillTypeValue
}) => {
  return (
    <PainterPanel className={className} onClose={onPanelClose}>
      <div className={styles.group}>
        <FormGroup label="顏色" vertical>
          <ColorSelector
            opacity={1}
            colors={DefaultPanelColors}
            value={colorHex}
            onChange={onColorChange}
          />
        </FormGroup>
        <FormGroup label="線條粗細" vertical>
            <LineWidthSelector value={lineWidth} onChange={onLineWidthChange} />
        </FormGroup>
      </div>
      <FormGroup label="形狀選項" vertical>
        <ShapeSelector
          painterType={painterType}
          onChange={onShapeChange}
          onFillTypeChange={onFillTypeChange}
          fillType={shapeFillTypeValue}
        />
      </FormGroup>
    </PainterPanel>
  );
};

const LinePanel = ({
  className,
  onPanelClose,
  painterTool: { lineWidth, colorHex, painterType, lineType },
  onLineWidthChange = () => {},
  onColorChange = () => {},
  onLineTypeChange = () => {},
  onPainterHandler = () => {},
}) => {
  return (
    <PainterPanel className={className} onClose={onPanelClose}>
      <div className={styles.group}>
        <FormGroup label="顏色" vertical>
          <ColorSelector
            opacity={1}
            colors={DefaultPanelColors}
            value={colorHex}
            onChange={onColorChange}
          />
        </FormGroup>
        <FormGroup label="畫筆粗細" vertical>
          <LineWidthSelector value={lineWidth} onChange={onLineWidthChange} />
        </FormGroup>
      </div>
      <FormGroup label="畫筆選項" vertical>
          <PainterOptionSelector 
            lineType={lineType}
            painterType={painterType} 
            onPainterChange={onPainterHandler}
            onLineTypeChange={onLineTypeChange}
          />
        </FormGroup>
    </PainterPanel>
  );
};

const ExpressionPanel = ({
  className,
  onPanelClose,
  onExpressionHandler = () => { },
}) => {
  return (
    <PainterPanel className={classnames(styles.expression, className)} onClose={onPanelClose}>
      <div className={styles.expressionBtn} onClick={() => onExpressionHandler("Fireworks")}>
        <Icon name="Fireworks" />
      </div>
      <div className={styles.expressionBtn} onClick={() => onExpressionHandler("Good")}>
        <Icon name="Good" />
      </div>
      <div className={styles.expressionBtn} onClick={() => onExpressionHandler("Like")}>
        <Icon name="Like" />
      </div>
      <div className={styles.expressionBtn} onClick={() => onExpressionHandler("Trophy")}>
        <Icon name="Trophy" />
      </div>
    </PainterPanel>
  );
};

export default {
  Pen: PenPanel,
  Line: LinePanel,
  Shape: ShapePanel,
  Expression: ExpressionPanel
};
