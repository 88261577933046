import React, { useCallback, useEffect } from 'react';
import { useStore, StoreTypes } from 'context';
import { EventBus } from 'events/EventBus';
import { UserEvent } from 'events/EventTypes';
import { AnnotationType } from 'constants/annotationTypes';
import { Roles } from 'constants/role';
import { usePullAnnotations, useRefreshReader } from 'customHooks/reader';
import { usePreparationSnapshot } from 'customHooks/preparation';

import { ReaderEvent, ReaderToolsEvent } from 'events/EventTypes';
import { useReadAnnotations } from 'customHooks/db';
import { usePreparation } from 'customHooks/preparation';
import { useUpdateDisplayLanguage, useUpdateUserSettings } from 'customHooks/userSetting';
import { UserSettingsAPI } from 'api/UserSettingsAPI';
import * as types from 'constants/actionTypes';

export const useAnnotationChecker = (props) => {
    const { bookId, paramsRole, paramsActivityId, paramsTeacherId, paramsInteractiveObjectId, roomId, studentId, timeSpanId, code, otp } = props
    const [{ annotationId, annotationType }] = useStore(StoreTypes.annotation);
    const [{ isLiveStreamMasterPanelShow, areaZoomInteractiveObjects, submenuInfo, isDoublePageMode, pageIndex, readerToolDirection, indicatorInfo: { isActive: isIndicatorActive, message: indicatorMessage } }, readerDispatch] = useStore(StoreTypes.reader);

    const [{ books }] = useStore(StoreTypes.books);
    const [{ role, token, userId, name }, userDispatch] = useStore(StoreTypes.user);
    const [, courseDispatch] = useStore(StoreTypes.course);

    const { getPreparationList, updatePreparationAnnotation } = usePreparation();
    const { refreshReader } = useRefreshReader();
    const { updateDisplayLanguage } = useUpdateDisplayLanguage();
    const updateUserSettings = useUpdateUserSettings();
    const { readAnnotations, readAnnotationById } = useReadAnnotations();
    let payload;

    useEffect(() => {
        console.log(annotationId, "------paramsActivityId------", paramsActivityId);
        const book = books.find(book => book.bookId === bookId);
        if (!book) return;
        if (!annotationId && !paramsActivityId && !paramsInteractiveObjectId) {
            EventBus.emit({
                event: UserEvent.GetUserIdentityEvent,
                payload: {
                    roomId,
                    studentId,
                    timeSpanId,
                    code,
                    otp,
                    callback: (async ({ role, userId, userName, courseInfo }) => {
                        let type = AnnotationType.GUEST;
                        switch (role) {
                            case Roles.GUEST:
                                type = AnnotationType.GUEST
                                break;
                            case Roles.TEACHER:
                            case Roles.PARENT:
                            case Roles.STUDENT:
                                type = AnnotationType.CLASS_PREPARATION
                                break;
                            case Roles.EDITOR:
                                type = AnnotationType.INTERACTIVE_OBJECT
                                break;
                            case Roles.TUTOR:
                            case Roles.TUTOR_USER:
                            case Roles.ADMIN:
                                type = AnnotationType.ONE_TUTOR
                                break;
                            case Roles.ONECLASS_TEACHER:
                                type = AnnotationType.ONE_CLASS
                                break;
                            case Roles.ONECLASS_STUDENT:
                                type = AnnotationType.ONE_CLASS
                                break;
                            default:
                                break;
                        }
                        //撈出indexdb裡面的備課資料，取第一筆進書
                        payload = {
                            bookId,
                            annotationType: type,
                            name: "未命名",
                            marks: []
                        };



                        if (token) {
                            await getPreparationList(bookId, token, type);
                        }

                        let enterCourse = false;
                        let callback = null;
                        if (type !== AnnotationType.ONE_TUTOR && type !== AnnotationType.ONE_CLASS) {
                            let results = await readAnnotations({ bookId, annotationType: type });
                            if (results.length > 0) {
                                const latestAnnotation = results.filter((annotation) => !annotation.isDeleted).sort((a, b) => {
                                    return b.updatedAt - a.updatedAt;
                                })[0];
                                callback = () => {
                                    refreshReader(latestAnnotation)
                                };
                                payload.annotationId = latestAnnotation.id;
                                payload.name = latestAnnotation.name;
                                payload.marks = latestAnnotation.marks;
                                payload.annotations = latestAnnotation.annotations;
                                if (type === AnnotationType.CLASS_PREPARATION) {
                                    //preparationSnapshot(latestAnnotation.id, token)
                                }
                            }
                        } else {
                            const result = await readAnnotationById({ id: roomId });
                            if (result) {
                                payload.annotationId = result.id;
                                payload.name = result.name;
                                payload.marks = result.marks;
                                payload.annotations = result.annotations;
                            } else {
                                payload.annotationId = roomId;
                                payload.name = userName;
                                payload.createNewAnnotation = true;
                                payload.marks = [];
                                payload.annotations = [];
                            }

                            if (role === Roles.GUEST) {
                                alert("您不是該課程的成員")
                            } else {
                                let isFreeGroup = false;
                                if (courseInfo) {
                                    isFreeGroup = courseInfo.organizationId === undefined ? true : false;
                                    const time = new Date().getTime();
                                    const { finishedBefore ,tutorInfo} = courseInfo
                                    const { startTime,endTime} = tutorInfo
                                    const extendTime=60 * 1000 * 90
                                    if(finishedBefore<0){
                                        if (finishedBefore-extendTime < 0) {
                                            enterCourse = true;
                                            courseDispatch({ type: types.SET_COURSE_INFO, courseInfo: { ...courseInfo, id: roomId, userRole: role } })
                                        }
                                    }else{
                                        if (time < startTime+extendTime) {
                                            enterCourse = true;
                                            courseDispatch({ type: types.SET_COURSE_INFO, courseInfo: { ...courseInfo, id: roomId, userRole: role } })
                                        }
                                    }
                                    courseDispatch({ type: types.SET_FINISHED_BEFORE, finishedBefore })
                                }

                                switch (role) {
                                    case Roles.TUTOR:
                                        if (enterCourse) {
                                            courseDispatch({ type: types.SET_COURSE_INFO, courseInfo: { ...courseInfo, id: roomId, userRole: role } })
                                            courseDispatch({ type: types.SET_CHIME_ROLE, chimeRole: role })
                                            courseDispatch({ type: types.SHOW_STREAM_PANNEL, isStreamPannel: true })
                                            readerDispatch({ type: types.TOGGLE_LIVE_STREAM_MASTER_PANEL, isLiveStreamMasterPanelShow: true });
                                        } else {
                                            await updatePreparationAnnotation({ id: roomId, token, userId: userId.split("_")[0], unconditional: true });
                                        }
                                        break;
                                    case Roles.ONECLASS_TEACHER:
                                        if (enterCourse) {
                                            courseDispatch({ type: types.SET_CHIME_ROLE, chimeRole: role })
                                            !isFreeGroup && readerDispatch({ type: types.TOGGLE_LIVE_STREAM_MASTER_PANEL, isLiveStreamMasterPanelShow: true });
                                        }
                                        break;
                                    case Roles.ADMIN:
                                    case Roles.TUTOR_USER:
                                        if (enterCourse) {
                                            courseDispatch({ type: types.SET_COURSE_INFO, courseInfo: { ...courseInfo, id: roomId, userRole: role } })
                                            courseDispatch({ type: types.SET_CHIME_ROLE, chimeRole: role })
                                            courseDispatch({ type: types.SHOW_STREAM_PANNEL, isStreamPannel: true })
                                            readerDispatch({ type: types.TOGGLE_LIVE_STREAM_VIEWER_PANEL, isLiveStreamViewerPanelShow: true });
                                        } else {
                                            await updatePreparationAnnotation({ id: roomId, token, userId: userId.split("_")[0], unconditional: true });
                                        }
                                        break;
                                    case Roles.ONECLASS_STUDENT:
                                        if (enterCourse) {
                                            courseDispatch({ type: types.SET_CHIME_ROLE, chimeRole: role })
                                            !isFreeGroup && readerDispatch({ type: types.TOGGLE_LIVE_STREAM_VIEWER_PANEL, isLiveStreamViewerPanelShow: true });
                                        }
                                        break;
                                    default:
                                        break;
                                }
                            }
                        }

                        EventBus.emit({
                            event: ReaderEvent.CreateAndEnterAnnotationEvent,
                            payload: {
                                ...payload,
                                callback
                            }
                        });
                    })
                }
            });
        } else {
            if (annotationType === AnnotationType.CLASS_PREPARATION) {
                //preparationSnapshot(annotationId, token)
                refreshReader();
            } else if (annotationType === AnnotationType.GUEST) {
                refreshReader();
            }
        }

        return payload
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [books, token]);
}