import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import * as actionTypes from 'constants/actionTypes';
import { useStore, StoreTypes } from 'context';
import { useReadUserSettings, useWriteUserSettings } from 'customHooks/db';
import { UserSettingsAPI } from 'api/UserSettingsAPI';

export const useUpdateDisplayLanguage = () => {
    const [{ userId }] = useStore(StoreTypes.user);
    const [, userSettingsDispatch] = useStore(StoreTypes.settings);
    const readUserSettings = useReadUserSettings();
    const { i18n } = useTranslation();

    const updateDisplayLanguage = useCallback(async () => {
        let userSettings = await readUserSettings(userId);
        if (userSettings) {
            userSettingsDispatch({ type: actionTypes.UPDATE_USER_SETTINGS, ...userSettings });
            if (userSettings.language) {
                const changeLanguageHandler = () => {
                    i18n.changeLanguage(userSettings.language);
                };
                if (i18n.isInitialized) {
                    changeLanguageHandler();
                } else {
                    i18n.on('initialized', changeLanguageHandler);
                }
            }
        }
    }, [i18n, readUserSettings, userId, userSettingsDispatch]);

    return { updateDisplayLanguage };
};

export const useUpdateUserSettings = () => {
    const [{ userId, token }] = useStore(StoreTypes.user);
    const [, userSettingsDispatch] = useStore(StoreTypes.settings);
    const writeUserSettings = useWriteUserSettings();

    const updateUserSettings = useCallback(async (userSettings, sync = true) => {
        userSettingsDispatch({ type: actionTypes.UPDATE_USER_SETTINGS, ...userSettings });
        await writeUserSettings(userId, userSettings);
        if (sync) {
            await UserSettingsAPI.updateUserSettings(token, userSettings);
        }
    }, [token, userId, userSettingsDispatch, writeUserSettings]);

    return updateUserSettings;
};
