import React, { useEffect } from 'react';
import styles from './index.module.scss';
import { useStore, StoreTypes } from 'context';
import { useReadAnnotations } from 'customHooks/db'
import { EventBus, EventBusType } from 'events/EventBus';
import { ReaderEvent, CanvasEvent } from 'events/EventTypes';
import { Roles } from 'constants/role';

// empty for now
const Whiteboard = ({ canvasSVGObject }) => {
  const [{ role }] = useStore(StoreTypes.user);
  const [{ annotationId }] = useStore(StoreTypes.annotation);
  const { readAnnotationById } = useReadAnnotations();
  useEffect(() => {
    (async () => {
      if (canvasSVGObject === undefined) {
        EventBus.emit({
          eventBusType: EventBusType.Default,
          event: ReaderEvent.SetCanvasSVGObjectIdEvent,
          payload: { canvasSVGObjectId: null }
        });
        return;
      };
      const { id } = canvasSVGObject
      EventBus.emit({
        eventBusType: EventBusType.Default,
        event: ReaderEvent.SetCanvasSVGObjectIdEvent,
        payload: { canvasSVGObjectId: id }
      });
      let { extendedContentAnnotation } = await readAnnotationById({ id: annotationId })
      if (!extendedContentAnnotation) {
        extendedContentAnnotation = []
      }
      const annotations = extendedContentAnnotation.find((obj) => {
        if (obj.id === id) {
          return obj
        }
        return null
      })
      if (!annotations) return;
      EventBus.emit({ eventBusType: EventBusType.ExtendedContent, event: CanvasEvent.CanvasImportJSONEvent, payload: { annotations } });

      role === Roles.TUTOR &&
        EventBus.emit({
          event: CanvasEvent.SendCanvasSVGObjectAnnotationEvent,
          payload: { annotations }
        });
    })()
  }, [role, annotationId, canvasSVGObject, readAnnotationById])
  return (
    <div className={styles.whiteboard}></div>
  )
}


export default Whiteboard;