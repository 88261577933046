import React, { useCallback, useRef } from 'react';
import { ReaderToolType } from 'constants/ReaderTools';
import { useReaderStrategyDecider } from 'customHooks/Strategies/ReaderStrategies';
import { useReaderToolsFactory } from '../ReaderToolsFactory';
import { useDrop, useDrag } from 'react-dnd'
import { EventBus } from 'events/EventBus';
import { ReaderToolsEvent } from 'events/EventTypes';
import { useUpdateUserSettings } from 'customHooks/userSetting';
import styles from './index.module.scss';
import { throttle } from 'util/debounce';
import { useStore, StoreTypes } from 'context';
import classnames from 'classnames';

const ITEM_MAX = 16;

const Dragable = React.memo(({
  className,
  id,
  children,
  moveItem,
  findItem,
  setItemActive,
  dropSelf = true,
  dragable = false
}) => {
  const originalIndex = findItem(id).index;
  const [, drag] = useDrag({
    canDrag: () => dragable,
    item: { type: 'ReaderTool', id, originalIndex },
    end: (dropResult, monitor) => {
      const { id: droppedId, originalIndex } = monitor.getItem()
      const didDrop = monitor.didDrop()
      if (!didDrop) {
        moveItem(droppedId, originalIndex)
        setItemActive(droppedId, false)
      }
    },
  })

  const [, drop] = useDrop({
    accept: 'ReaderTool',
    canDrop: () => false,
    hover({ id: draggedId, index: hoverIndex }) {
      if (draggedId !== id) {
        const { index: overIndex } = findItem(id)
        moveItem(draggedId, overIndex)
      }
    }
  })

  return (
    <div ref={(node) => drag(dropSelf ? drop(node) : node)} className={className}>
      {children}
    </div>
  )
})

const ReaderToolComponents = React.memo(({
  type,
  className,
  dragableClassName,
  bookInfo,
  panelState,
  setPanelStateHandler,
  filter,
  dropSelf = true,
  dragable = false
}) => {
  const wrapperRef = useRef();
  const { book, LRFlip } = bookInfo;
  const decider = useReaderStrategyDecider();
  const strategy = decider.getReaderStrategy();
  const ReaderToolsFactory = useReaderToolsFactory();
  const updateUserSettings = useUpdateUserSettings();

  const [{ readerTools: cards }] = useStore(StoreTypes.reader);
  const setCards = useCallback(throttle((readerTools) => {
    EventBus.emit({
      event: ReaderToolsEvent.SetReaderToolsEvent,
      payload: {
        readerTools
      }
    });
    updateUserSettings({ readerToolSetting: readerTools.filter(item => item.isActive).map(item => item.id) });
  }, 100), [])

  const findItem = useCallback((id) => {
    const card = cards.filter((c) => `${c.id}` === `${id}`)[0]
    return {
      card,
      index: cards.indexOf(card),
    }
  }, [cards])

  const moveItem = useCallback((id, atIndex) => {
    const { card, index } = findItem(id);
    let nexntCard = cards.concat();
    nexntCard.splice(index, 1);
    nexntCard.splice(atIndex, 0, card)
    setCards([...nexntCard])
  }, [cards, findItem, setCards])

  const setItemActive = useCallback((id, isActive = true) => {
    const { index } = findItem(id);
    let nexntCard = cards.concat();
    nexntCard[index]['isActive'] = isActive;
    setCards([...nexntCard])
  }, [cards, findItem, setCards])


  const Tools = React.useMemo(() => {
    return ReaderToolsFactory.batchCreateReaderTools(filter(cards).map(item => item.id), {
      // Could refactor deeper?
      [ReaderToolType.Painting]: { additionalClickHandler: () => setPanelStateHandler(panelState.Pen) },
      [ReaderToolType.Highlighter]: { additionalClickHandler: () => setPanelStateHandler(panelState.Highlighter) },
      [ReaderToolType.Shape]: { additionalClickHandler: () => setPanelStateHandler(panelState.Shape) },
      [ReaderToolType.Line]: { additionalClickHandler: () => setPanelStateHandler(panelState.Line) },
      [ReaderToolType.Expression]: { additionalClickHandler: () => setPanelStateHandler(panelState.Expression) },
      [ReaderToolType.ArrowLeft]: { clickHandlerParameters: [book, LRFlip] },
      [ReaderToolType.ArrowRight]: { clickHandlerParameters: [book, LRFlip] }
    })
  }, [LRFlip, ReaderToolsFactory, book, cards, filter, panelState.Expression, panelState.Highlighter, panelState.Line, panelState.Pen, panelState.Shape, setPanelStateHandler])


  const getActiveToolsLength = React.useMemo(() => {
    return strategy ? strategy.getReaderToolComponents(Tools).length : 0
  }, [Tools, strategy])


  const [, drop] = useDrop({
    accept: 'ReaderTool',
    canDrop: () => dragable,
    hover({ id: draggedId }) {
      setItemActive(draggedId, true)
    }
  })

  const addTools = useCallback(() => {
    if (!strategy) return;
    const tools = strategy.getReaderToolComponents(Tools)
    if (tools.length > 0) {
      return tools.map(component => (
        <Dragable
          className={classnames(styles.dragable, dragableClassName)}
          id={component.props.type}
          moveItem={moveItem}
          findItem={findItem}
          setItemActive={setItemActive}
          dropSelf={dropSelf}
          dragable={dragable}
        >
          {component}
        </Dragable>
      ))
    }
  }, [Tools, moveItem, findItem, setItemActive, dropSelf, dragable, strategy, dragableClassName])

  return (
    <div ref={wrapperRef} className={classnames(styles.container, {
      [styles.lessThanMax]: getActiveToolsLength <= ITEM_MAX && type === 'ReaderTool',
      [styles[type]]: type,
      [styles.hasScrollBar]: wrapperRef.current && (wrapperRef.current.scrollWidth > wrapperRef.current.offsetWidth)
    })}>
      <div ref={dropSelf ? drop : null} className={classnames(className, {
        [styles.hasScrollBar]: wrapperRef.current && (wrapperRef.current.scrollWidth > wrapperRef.current.offsetWidth)
      })}>
        {
          addTools()
        }
      </div>
    </div>
  )
})


export default ReaderToolComponents;