import React from 'react';
import { EventBus } from 'events/EventBus';
import { ReaderToolsEvent } from 'events/EventTypes';
import Icon from 'components/Icon';

import styles from './index.module.scss';

const MathToolsContainer = () => {
  const closeHandler = () => {
    EventBus.emit({ event: ReaderToolsEvent.ClickMathToolsEvent, payload: { isMathToolsShow: false } });
  }

  return (
    <div className={styles.mathToolsContainer}>
      <iframe className={styles.iframe} title="compass" src="assets/MathTools/MathTools.html" frameborder="0"></iframe>
      <div className={styles.closeLeftButton} onClick={closeHandler}><Icon name="Close" /></div>
      <div className={styles.closeRightButton} onClick={closeHandler}><Icon name="Close" /></div>
    </div>
  )
}

export default MathToolsContainer;