import { CanvasSVGObjectContentType } from 'constants/canvasSVGObjectContentTypes';
import { useStickyNoteCommand } from 'customHooks/CanvasSVGObjectContentCommands/stickyNoteCommand';
import { useWhiteboardCommand } from 'customHooks/InteractiveObjectContentCommands/whiteboardCommand';

export const useCanvasSVGObjectContentCommandFactory = () => {
  const StickyNoteCommand = useStickyNoteCommand();
  const WhiteboardCommand = useWhiteboardCommand();

  const createCommand = ({ contentType }) => {
    switch (contentType) {
      case CanvasSVGObjectContentType.StickyNote:
        return StickyNoteCommand;
      case CanvasSVGObjectContentType.Whiteboard:
        return WhiteboardCommand;
      default:
        console.error(`ContentType ${contentType} is not supported`);
        return;
    }
  };

  const CanvasSVGCommandFactory = { createCommand };

  return CanvasSVGCommandFactory;
};
