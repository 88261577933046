import { useCallback } from 'react';
import {
    useNotificationDispatch,
    Severity,
    ActionType,
} from 'amazon-chime-sdk-component-library-react';
import {
    PermissionDeniedError,
    NotReadableError,
    NotFoundError,
    OverconstrainedError,
    GetUserMediaError
} from 'amazon-chime-sdk-js';
import { useSlackWebhook } from 'customHooks/slackWebHook'


export const useChimeError = () => {
    const dispatch = useNotificationDispatch();
    const { sendSlackMeetingIssue } = useSlackWebhook();

    const getDeviceError = (error) => {
        if (error === "PermissionDeniedError" || error instanceof PermissionDeniedError)
            return {
                code: 1,
                description: "裝置安全或權限因素"
            };
        else if (error instanceof NotReadableError)
            return {
                code: 2,
                description: "裝置無法使用"
            };
        else if (error instanceof GetUserMediaError)
            return {
                code: 3,
                description: "無法取得媒體"
            };
        else if (error instanceof NotFoundError)
            return {
                code: 4,
                description: "找不到指定的輸入裝置"
            };
        else if (error instanceof OverconstrainedError)
            return {
                code: 5,
                description: "無法滿足設置條件"
            };
        else
            return {
                code: 6,
                description: "未知的錯誤"
            }
    }

    const processDeviceError = useCallback((deviceName, error) => {
        const deviceError = getDeviceError(error);
        showNotification(Severity.ERROR, `目前此裝置無法使用：錯誤碼 ${deviceError.code}`, false, false);
        sendSlackMeetingIssue(`遇上${deviceName}裝置無法啟用的問題-裝置錯誤碼 ${deviceError.code}_${deviceError.description}`);
    });

    const showNotification = useCallback((severity, message, replaceAll = false, autoClose = true) => {

        const payload = {
            severity: severity,
            message: message,
            autoClose: autoClose,
            autoCloseDelay: (severity == Severity.INFO ? 2000 : 5000),
            replaceAll: replaceAll,
        };
        dispatch({
            type: ActionType.ADD,
            payload: payload
        });
    });


    return { showNotification, processDeviceError };
};

