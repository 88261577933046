import React from 'react';
import Dropdown from 'components/common/Dropdown';

import styles from './index.module.scss';

const options = Array(14)
  .fill()
  .map((value, index) => ({
    label: '──────────────────────',
    value: index + 1
  }));

const LineWidthSlider = ({ value, onChange = () => {} }) => (
      <Dropdown data={options} value={value} onChange={val=>onChange(val)}>
        {
          options.map(option => (
            <div className={styles.option}>
              <span className={styles.line} style={{height: option.value + 'px'}}></span>
            </div>
          ))
        }
      </Dropdown>
);
export default LineWidthSlider;
