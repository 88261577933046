import { useEnhanceReducer } from 'customHooks/enhanceReducer';
import * as types from 'constants/actionTypes';
import { SideToolContent, SideToolDirection } from 'constants/sideToolContents';

export const initState = {
    sideToolContent: SideToolContent.None,
    sideToolDirection: SideToolDirection.RIGHT,
    isTextSideToolShow: false,
    textSideToolDirection: SideToolDirection.RIGHT
};

const SideToolReducer = (state, action) => {
    switch (action.type) {
        case types.SET_SIDE_TOOL_CONTENT:
            return {
                ...state,
                sideToolContent: action.sideToolContent,
                sideToolDirection: action.sideToolDirection
            };
        case types.SET_TEXT_SIDE_TOOL_SHOW:
            return {
                ...state,
                isTextSideToolShow: action.isTextSideToolShow,
                textSideToolDirection: action.textSideToolDirection
            }
        default:
            return state;
    }
};

const useSideToolReducer = () => useEnhanceReducer(SideToolReducer, initState);

export default useSideToolReducer;
