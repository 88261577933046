import React, { useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';
import styles from './index.module.scss';
import { useStore, StoreTypes } from 'context';
import { EventBus } from 'events/EventBus';
import { ReaderEvent } from 'events/EventTypes';
import Repository from 'repositories/Repository';
const { ExtendedResourceRepository } = Repository;

const BOOKMARK_TYPE = {
  'NORMAL': 'normal',
  'TOOL': 'tool'
};

const COLORS = ['#0070C0', '#00B0F0', '#F3503D', '#FFC000', '#00B050', '#92D050', '#7A4F7A', '#302A6C',];

const Bookmarks = ({ bookmarkData: { data = [], tools = [] } = {}, show }) => {
  const [{ bookId }] = useStore(StoreTypes.books);
  const [bookmarkList, setBookmarkList] = useState([]);

  const clickBookmarkHandler = ({ id, page }) => e => {
    // page is from single page mode
    EventBus.emit({
      event: ReaderEvent.ClickBookmarkEvent,
      payload: { defaultPageIndex: page, bookId: id }
    });
  };

  const clickToolHandler = useCallback(({ filename }) => e => {
    ExtendedResourceRepository.postTools({ bookId, filename });
  }, [bookId]);

  useEffect(() => {
    const dataWithType = data.map(item => ({ bookmarkType: BOOKMARK_TYPE.NORMAL, ...item }));
    const toolsWithType = tools.map(item => ({ bookmarkType: BOOKMARK_TYPE.TOOL, ...item }));
    setBookmarkList(dataWithType.concat(toolsWithType));
  }, [data, tools])

  return (
    <div
      className={classnames(styles.bookmarkContainer, { [styles.show]: show })}
    >
      {
        bookmarkList.map((item, index) =>
          <div
            className={styles.bookmark}
            style={{ backgroundColor: COLORS[index % COLORS.length] }}
            key={index}
            onClick={
              item.bookmarkType === BOOKMARK_TYPE.NORMAL ?
                clickBookmarkHandler({
                  id: item.id,
                  page: item.page
                }) : clickToolHandler(item)
            }
          >
            {' '}
            {item.name}{' '}
          </div>
        )
      }
    </div>
  );
};

export default Bookmarks;
