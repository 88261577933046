import React, { useEffect, useState, useCallback, useRef, createRef } from 'react';
import path from 'path';
import classnames from 'classnames';
import styles from './index.module.scss';
import { useStore, StoreTypes } from 'context';
import { groupPages } from 'util/book';
import { EventBus } from 'events/EventBus';
import { ReaderEvent } from 'events/EventTypes';
import { BookFlipType } from 'constants/flipTypes';
import Repository from 'repositories/Repository';
import Input from 'components/common/Input';
import PropTypes from "prop-types";
import {LazyLoadImage} from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';


const { BookContentRepository } = Repository;
const PageSearcher = ({ vertical, horizontal }) => {
  const [{ books, bookId, catalog }] = useStore(StoreTypes.books);
  const [{ pageIndex, isDoublePageMode }] = useStore(StoreTypes.reader);
  const book = books.find(item => item.bookId === bookId);
  const [data, setData] = useState({});
  const [dataList, setDataList] = useState({});
  const [currentPageIndex, setCurrentPageIndex] = useState(pageIndex);
  const [thumbnailUrls, setThumbnailUrls] = useState([]);
  const [inputText, setInputText] = useState("");
  const thumbnailWrapperRef = useRef();
  const thumbnailGroupsRef = useRef([]);

  useEffect(() => {
    if (!book) {
      return;
    }
    const { pageInfos } = book;
    const pages = pageInfos.reduce((acc, item) => {
      acc[item.pageIndex] = path.basename(item.html, path.extname(item.html));
      return acc;
    }, {});
    const imgList = getLabel(groupPages(pages, isDoublePageMode))
    setData(imgList);
    setDataList(imgList)
    thumbnailGroupsRef.current = Object.values(imgList).map(() => createRef())
    const results = BookContentRepository.getThumbnailUrls({ bookId, pages: Object.values(pages) });
    setThumbnailUrls(results);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [book, bookId, isDoublePageMode]);

  const getLabel = (data) => {
    return Object.values(data).map((pages, index) => {
      const labels = []
      for (let page of pages) {
        const label = (catalog.getLabel(page - 1)).toString()
        labels.push({ label, page, index })
      }
      return labels;
    })
  }

  const setImg = useCallback((pages) => {
    let pagesElement = []
    if (pages.length < 2) {
      pagesElement.push(<div className={styles.pagesElement} key={pages[0].page}>
        <LazyLoadImage  key={pages[0].page} src={thumbnailUrls[pages[0].page]} alt='' effect="blur"/>
      </div>)
    } else {
      for (let page of pages) {
        const pageIndex = parseInt(page.page)
        pagesElement.push(<div className={styles.pagesElement} key={page.page}>
          <LazyLoadImage  key={pageIndex} src={thumbnailUrls[pageIndex]} alt='' effect="blur"/>
        </div>)
      }
    }
    return pagesElement
  }, [thumbnailUrls]);

  const searchPage = useCallback((value) => {
    setInputText(value);
    setDataList(Object.values(data).filter((pages) => {
      return pages.some((obj) => obj.label.indexOf(value) > -1);
    }));
  }, [data]);


  useEffect(() => {
    const dataListArr = Object.values(dataList);
    const totalLength = dataListArr.length;
    if (totalLength < 1) return;
    let newPageIndex = 0;
    if (inputText) {
      const selectedDataIndex = dataListArr.findIndex(item => item[0].index === pageIndex);
      if (selectedDataIndex < 0) return;
      newPageIndex = selectedDataIndex;
      setCurrentPageIndex(dataListArr[selectedDataIndex][0].index);
    } else {
      newPageIndex = pageIndex;
      setCurrentPageIndex(pageIndex);
    }


    const target = thumbnailGroupsRef.current[newPageIndex].current;
    const wrapper = thumbnailWrapperRef.current;
    if (!target || !wrapper) return;
    const { offsetTop } = target;
    const { offsetWidth } = wrapper;
    const BUFFER = offsetWidth / 2;

    vertical ?
      wrapper.scrollTo(0, offsetTop - BUFFER) : 
      target.scrollIntoView({ inline: newPageIndex < 3 ? 'nearest' : 'center' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataList, pageIndex]);

  return (
    <div className={classnames(styles.pageSearcher)}>
      <Input className={styles.input} placeholder="輸入頁數" value={inputText} onChange={(e) => {
        searchPage(e.target.value)
      }} />
      <div
        ref={thumbnailWrapperRef}
        className={classnames(styles.thumbnailWrapper, {
          [styles.vertical]: vertical,
          [styles.horizontal]: !vertical || horizontal,
          [styles.rightToLeft]: book.LRFlip === BookFlipType.RIGHT_TO_LEFT,
          [styles.doublePage]: isDoublePageMode
        })}>
        {
          Object.values(dataList).map((pages, index) => (
            <div
              ref={thumbnailGroupsRef.current[index]}
              key={index}
              className={classnames(styles.thumbnailGroup, {
                [styles.active]: currentPageIndex === pages[0].index
              })}>
              {
                pages.length > 1 && pages[0].label ?
                  <>
                    {
                      pages[0].label === pages[1].label ? <div className={styles.title}>{pages[0].label}</div> :
                        <div className={styles.title}>
                          <span>{pages[0].label}</span>
                          <span>{pages[1].label}</span>
                        </div>
                    }
                  </> : <div className={styles.title}>{pages[0].label}</div>
              }
              <div key={index}
                className={styles.thumbnail}
                onClick={() => EventBus.emit({ event: ReaderEvent.ClickBookThumbnailEvent, payload: { pageIndex: pages[0].index } })}
              >
                {
                  setImg(pages)
                }
              </div>
            </div>
          ))
        }
      </div>
    </div>
  )
}
PageSearcher.propTypes = {
  classes: PropTypes.object.isRequired
};

export default PageSearcher;
