import React, { useCallback } from 'react';
import Input from 'components/common/Input';
import Textarea from 'components/common/Textarea';
import CheckBox from 'components/common/CheckBox';
import classnames from 'classnames';
import styles from './index.module.scss';
import { useStore, StoreTypes } from 'context';
import { EventBus } from 'events/EventBus';
import { CanvasEvent } from 'events/EventTypes';
import {
  InteractiveObjectContentType,
  InteractiveObjectControlStageType
} from 'constants/interactiveObjectContentTypes';
import {
  LayoutType,
  LayoutDisplayName
} from 'components/ExtendedContent/ExtendedContentContainer/layout';
import { VideoComponent } from 'components/Tools/PropertyForm/VideoPropertyForm';
import { AreaZoomComponent } from 'components/Tools/PropertyForm/AreaZoomPropertyForm';
import { InternalHtmlWindowComponent } from 'components/Tools/PropertyForm/InternalHtmlWindowComponent';
import { WordGridComponent } from 'components/Tools/PropertyForm/WordGridPropertyForm';
import { VolumeOneWordsComponent } from 'components/Tools/PropertyForm/VolumeOneWordsPropertyForm';
import { SubmenuPropertyForm } from 'components/Tools/PropertyForm/SubmenuPropertyForm';
import { WordExampleComponent } from 'components/Tools/PropertyForm/WordExamplePropertyForm';
import { VocabularyComponent } from 'components/Tools/PropertyForm/VocabularyPropertyForm';
import { SynopsisComponent } from 'components/Tools/PropertyForm/SynopsisPropertyForm';
import { HearingComponent } from 'components/Tools/PropertyForm/HearingPropertyForm';
import { ListeningStoryComponent } from 'components/Tools/PropertyForm/ListeningStoryPropertyForm';
import { AudioComponent } from 'components/Tools/PropertyForm/AudioPropertyForm';

export const ContentTypeDisplayMap = {
  [InteractiveObjectContentType.ExternalWindow]: '外開連結',
  [InteractiveObjectContentType.InternalWindow]: '內開視窗',
  [InteractiveObjectContentType.ToggleDisplay]: '蓋答案',
  [InteractiveObjectContentType.ControlDisplay]: '全解',
  [InteractiveObjectContentType.Stage]: '步驟編號',
  [InteractiveObjectContentType.ControlStage]: '步驟解',
  [InteractiveObjectContentType.Image]: '外開連結',
  [InteractiveObjectContentType.Image]: '圖片',
  [InteractiveObjectContentType.ControlImage]: '換圖',
  [InteractiveObjectContentType.GoPage]: '跳頁',
  [InteractiveObjectContentType.Video]: '影片',
  [InteractiveObjectContentType.Audio]: '音檔',
  [InteractiveObjectContentType.AreaZoom]: '區域放大',
  [InteractiveObjectContentType.InternalHtmlWindow]: 'HTML內開視窗',
  [InteractiveObjectContentType.Vocabulary]: '單字例句',
  [InteractiveObjectContentType.Translation]: '翻譯小視窗',
  [InteractiveObjectContentType.WordGrid]: '生字庫',
  [InteractiveObjectContentType.VolumeOneWords]: '首冊生字庫',
  [InteractiveObjectContentType.Submenu]: '次選單',
  [InteractiveObjectContentType.WordExample]: '語詞選單',
  [InteractiveObjectContentType.Synopsis]: '多題小視窗',
  [InteractiveObjectContentType.Hearing]: '聽力稿',
  [InteractiveObjectContentType.FlashCard]: 'Flash Card',
  [InteractiveObjectContentType.Listening]: '聆聽故事',
  [InteractiveObjectContentType.WordGlyph]: '字音字形',
  [InteractiveObjectContentType.WordMeaning]: '字義教學',
  [InteractiveObjectContentType.AboutTheAuthor]: '作者介紹',
  [InteractiveObjectContentType.ReadAloud]: '朗讀',
  [InteractiveObjectContentType.ZhuyinLesson]: '注音課文',
  [InteractiveObjectContentType.ZhuyinImage]: '注音圖片',
  [InteractiveObjectContentType.ZhuyinWord]: '一音二詞',
};

const StyleComponent = ({ object, inputTextHandler }) => {
  return (
    <div>
      <label htmlFor="style">Style</label>
      <select
        id="style"
        value={object.style || ''}
        onChange={e => inputTextHandler('style', e.target.value)}
      >
        {!object.controlStage && (
          <option key={-1} value="">
            ---
          </option>
        )}
        {Object.values(LayoutType).map((type, i) => (
          <option key={i} value={type}>
            {LayoutDisplayName[type]}
          </option>
        ))}
      </select>
    </div>
  );
};

const ControlStageComponent = ({ object, inputTextHandler }) => {
  return (
    <div>
      <label htmlFor="controlStage">ControlStage</label>
      <select
        id="controlStage"
        value={object.controlStage || ''}
        onChange={e => inputTextHandler('controlStage', e.target.value)}
      >
        {!object.controlStage && (
          <option key={-1} value="">
            ---
          </option>
        )}
        {Object.values(InteractiveObjectControlStageType).map((type, i) => (
          <option key={i} value={type}>
            {type}
          </option>
        ))}
      </select>
      <br />
      <label htmlFor="revealStage">逐步顯示</label>
      <input
        id="revealStage"
        type="checkbox"
        checked={object.revealStage || false}
        onChange={e => inputTextHandler('revealStage', e.target.checked)}
      />
    </div>
  );
};

const OpacityComponent = ({ object, inputTextHandler }) => {
  return (
    <div>
      <label htmlFor="opacity">Opacity</label>
      <select
        id="opacity"
        value={object.opacity}
        onChange={e => inputTextHandler('opacity', parseInt(e.target.value))}
      >
        <option key={0} value={0}>
          Hide
        </option>
        <option key={1} value={1}>
          Show
        </option>
      </select>
    </div>
  );
};

const AdjustLineAngleComponent = ({ object }) => {
  return (
    <div>
      <label>線段角度</label>
      <br />
      <input
        type="button"
        value="垂直"
        onClick={e =>
          EventBus.emit({
            event: CanvasEvent.AdjustCanvasLineAngleEvent,
            payload: { object, angle: 180 }
          })
        }
      />
      <input
        type="button"
        value="水平"
        onClick={e =>
          EventBus.emit({
            event: CanvasEvent.AdjustCanvasLineAngleEvent,
            payload: { object, angle: 90 }
          })
        }
      />
      <br />
    </div>
  );
};

const VisibleStatusComponent = ({ object, inputTextHandler }) => {
  return (
    <div className={styles.visibleStatusComponent}>
      <div className={styles.title}>可視狀態</div>
      <div className={styles.checkBoxGroup}>
        <label>
          <input
            type="checkbox"
            checked={
              object.isWebVisible === undefined ? true : object.isWebVisible
            }
            onChange={e => inputTextHandler('isWebVisible', e.target.checked)}
          />
        Web版
      </label>
        <label>
          <input
            type="checkbox"
            checked={
              object.isOfflineVisible === undefined ? true : object.isOfflineVisible
            }
            onChange={e => inputTextHandler('isOfflineVisible', e.target.checked)}
          />
      離線版
      </label>
      </div>
    </div>
  )
}

const ClickableStatusComponent = ({ object, inputTextHandler }) => {
  return (
    <div className={styles.clickableStatusComponent}>
      <label>
        <input
          type="checkbox"
          checked={
            object.isClickable || false
          }
          onChange={e => inputTextHandler('isClickable', e.target.checked)}
        />
      可被畫筆點擊
    </label>
    </div>
  )
}

const ControlObjectWhiteList = [
  InteractiveObjectContentType.ControlDisplay,
  InteractiveObjectContentType.ControlStage,
  InteractiveObjectContentType.ControlImage,
  InteractiveObjectContentType.AreaZoom
];

const sourceBlackList = [
  InteractiveObjectContentType.Video,
  InteractiveObjectContentType.InternalHtmlWindow,
  InteractiveObjectContentType.WordGrid,
  InteractiveObjectContentType.WordGlyph,
  InteractiveObjectContentType.WordExample,
  InteractiveObjectContentType.Synopsis,
  InteractiveObjectContentType.VolumeOneWords,
];

const PropertyForm = ({ className }) => {
  const [{ activeCanvasObject: object }] = useStore(StoreTypes.canvas);
  const inputTextHandler = useCallback(
    (property, value) => {
      EventBus.emit({
        event: CanvasEvent.ModifyCanvasObjectPropertyEvent,
        payload: { object, property, value }
      });
    },
    [object]
  );

  return (
    <div className={classnames(styles.propertyForm, className)}>
      <Input label="Id" value={object.id} disabled />
      <StyleComponent object={object} inputTextHandler={inputTextHandler} />
      {/* {!sourceBlackList.includes(object.contentType) && <Input label="Src" value={object.src} onChange={(e) => inputTextHandler('src', e.target.value.trim())} />} */}
      {!sourceBlackList.includes(object.contentType) && (
        <Textarea
          label="Src"
          value={object.src}
          onChange={e => inputTextHandler('src', e.target.value.trim())}
        />
      )}
      <label htmlFor="contentType">ContentType</label>
      <select
        id="contentType"
        value={object.contentType || ''}
        onChange={e => inputTextHandler('contentType', e.target.value)}
      >
        {!object.contentType && (
          <option key={-1} value="">
            ---
          </option>
        )}

        {Object.values(InteractiveObjectContentType).map(
          (type, i) =>
            ContentTypeDisplayMap[type] && (
              <option key={i} value={type}>
                {ContentTypeDisplayMap[type]}
              </option>
            )
        )}
      </select>
      {ControlObjectWhiteList.includes(object.contentType) && (
        <Textarea
          label="ControlObject"
          value={object.controlObject}
          onChange={e => inputTextHandler('controlObject', e.target.value)}
        />
      )}
      {object.contentType === InteractiveObjectContentType.ControlDisplay && (
        <div>
          <label htmlFor="defaultOpacity">預設隱藏</label>
          <input
            id="defaultOpacity"
            type="checkbox"
            checked={
              object.defaultOpacity === undefined ? true : object.defaultOpacity
            }
            onChange={e => inputTextHandler('defaultOpacity', e.target.checked)}
          />
        </div>
      )}
      {object.contentType === InteractiveObjectContentType.ControlStage && (
        <ControlStageComponent
          object={object}
          inputTextHandler={inputTextHandler}
        />
      )}
      {object.contentType === InteractiveObjectContentType.Stage && (
        <Input
          label="Stage"
          value={object.stage}
          onChange={e => inputTextHandler('stage', e.target.value)}
        />
      )}
      <div>
        <label htmlFor="mouseOver">滑入狀態</label>
        <input
          id="mouseOver"
          type="checkbox"
          checked={
            object.mouseOver === undefined ? false : object.mouseOver
          }
          onChange={e => inputTextHandler('mouseOver', e.target.checked)}
        />
      </div>
      {<OpacityComponent object={object} inputTextHandler={inputTextHandler} />}
      {<VisibleStatusComponent object={object} inputTextHandler={inputTextHandler} />}
      {<ClickableStatusComponent object={object} inputTextHandler={inputTextHandler} />}
      {object.contentType === InteractiveObjectContentType.GoPage && (
        <Input
          type="number"
          label="頁數"
          value={object.pageIndex}
          min="1"
          onChange={e => inputTextHandler('pageIndex', e.target.value)}
        />
      )}
      {object.contentType === InteractiveObjectContentType.Video && (
        <VideoComponent object={object} />
      )}
      {object.contentType === InteractiveObjectContentType.AreaZoom && (
        <AreaZoomComponent object={object} />
      )}
      {object.contentType ===
        InteractiveObjectContentType.InternalHtmlWindow && (
          <InternalHtmlWindowComponent object={object} />
        )}
      {(object.contentType === InteractiveObjectContentType.WordGrid ||
        object.contentType === InteractiveObjectContentType.WordGlyph ||
        object.contentType === InteractiveObjectContentType.WordMeaning
      ) && (
          <WordGridComponent object={object} />
        )}
      {(object.contentType === InteractiveObjectContentType.VolumeOneWords
      ) && (
          <VolumeOneWordsComponent object={object} />
        )}
      {object.contentType === InteractiveObjectContentType.WordExample && (
        <WordExampleComponent object={object} />
      )}
      {object.contentType === InteractiveObjectContentType.Submenu && (
        <SubmenuPropertyForm object={object} />
      )}
      {object.contentType === InteractiveObjectContentType.Vocabulary && (
        <VocabularyComponent object={object} />
      )}
      {object.contentType === InteractiveObjectContentType.Hearing && (
        <HearingComponent object={object} />
      )}
      {object.type === 'line' && <AdjustLineAngleComponent object={object} />}
      {object.contentType === InteractiveObjectContentType.Synopsis && (
        <SynopsisComponent object={object} />
      )}
      {object.contentType === InteractiveObjectContentType.Listening && (
        <ListeningStoryComponent object={object} />
      )}
      {object.contentType === InteractiveObjectContentType.Audio && (
        <>
          <CheckBox
            checked={object.isAudioHighlighter}
            onChange={val => inputTextHandler('isAudioHighlighter', val)}
          >隨文朗讀</CheckBox>
          {
            object.isAudioHighlighter ? (
              <Input
                label="物件id (.vtt)"
                value={object.audioInfo}
                onChange={e => inputTextHandler('audioInfo', e.target.value)}
              />
            ) : (
                <Input
                  label="字幕"
                  value={object.audioInfo}
                  onChange={e => inputTextHandler('audioInfo', e.target.value)}
                />
              )
          }

          {!object.audioInfo && !object.isAudioHighlighter && <AudioComponent
            value={object.partialSrc}
            onChange={value => inputTextHandler('partialSrc', value)}
          />}
          <label>
            <input
              type="checkbox"
              checked={
                object.isShowAudioControlBar !== false ? true : object.isShowAudioControlBar
              }
              onChange={e => inputTextHandler('isShowAudioControlBar', e.target.checked)}
            />
            顯示播放Bar
          </label>
          <Input
            label="字幕"
            value={object.audioInfo}
            onChange={e => inputTextHandler('audioInfo', e.target.value)}
          />
          {!object.audioInfo && <AudioComponent value={object.partialSrc} onChange={value => inputTextHandler('partialSrc', value)} />}
        </>
      )}
      {object.contentType === InteractiveObjectContentType.ReadAloud && (
        <Input
          label="字幕"
          value={object.readAloudInfo}
          onChange={e => inputTextHandler('readAloudInfo', e.target.value)}
        />
      )}
    </div>
  );
};

export default PropertyForm;
