import { useState, useRef, useCallback } from 'react';
import { fabric } from 'fabric';
import { API } from 'api';
import * as types from 'constants/actionTypes';
import { ExtraFieldsForEditor } from 'constants/canvas';
import { BookFlipType } from 'constants/flipTypes';
import { PainterMode } from 'constants/painterModes';
import { PainterType, LineType } from 'constants/painterTypes';
import { SideToolDirection } from 'constants/sideToolContents';
import { FillType } from 'constants/fillTypes';
import { StampMap } from 'constants/stampTypes';
import { useStore, StoreTypes } from 'context';
import { useUpdateAnnotation, useMarkAnnotationSynced, useReadAnnotations } from 'customHooks/db'
import { useWindowSize } from 'customHooks/windowSize';
import { EventBus, EventBusType } from 'events/EventBus';
import { CanvasEvent, ReaderEvent, ReaderToolsEvent } from 'events/EventTypes';
import { short_uuid } from 'util/uuid';
import { useReaderStrategyDecider } from 'customHooks/Strategies/ReaderStrategies';
import { CanvasSVGObjectContentType } from 'constants/canvasSVGObjectContentTypes';
import { SVGCanvasSwitchType, ReaderToolType } from 'constants/ReaderTools';
import { Roles } from 'constants/role';

let canvasReanderTime;
let drawTime;
let drawTimeCount = 0;

export const useAssignCanvasObjectId = () => {
    const reducers = useStore();
    const [{ canvas }] = reducers[StoreTypes.canvas];

    const assignCanvasObjectId = useCallback(() => {
        canvas.getObjects().forEach(obj => {
            if (!obj.id) {
                obj.id = short_uuid();
            }
        });
    }, [canvas]);

    return assignCanvasObjectId;
};

export const useExportCanvasToJSON = () => {
    const reducers = useStore();
    const [{ canvas, extraFields }] = reducers[StoreTypes.canvas];
    const [{ bookId, style, books }] = reducers[StoreTypes.books];
    const [{ pageIndex, isDoublePageMode }] = reducers[StoreTypes.reader];
    const { width, height } = style;
    const book = books.find(book => book.bookId === bookId);
    const { width: bookWidth, height: bookHeight, LRFlip } = book || {};

    const exportCanvasToJSON = useCallback(({ targetPage = (pageIndex * (isDoublePageMode ? 2 : 1)) } = {}) => {
        const originalCanvasJSON = canvas.toJSON(extraFields);
        const leftPageCanvasJSON = canvas.toJSON(extraFields);
        const rightPageCanvasJSON = canvas.toJSON(extraFields);
        leftPageCanvasJSON.objects = [];
        rightPageCanvasJSON.objects = [];

        const ratio = bookWidth / bookHeight;
        let offsetX = [0, 0];
        let offsetY = 0;
        let scale = 1.0;
        let factor = isDoublePageMode ? 2 : 1;
        if (bookWidth * factor / bookHeight < width / height) {
            scale = height / bookHeight;
        } else {
            scale = width / (bookWidth * factor);
        }
        if (isDoublePageMode) {
            offsetX[1] = width / 2;
            if (height * ratio < width / 2) {
                offsetX[0] = (width / 2 - height * ratio);
            } else {    // height * ratio > width / 2
                offsetY = (height - (width / 2) / ratio) / 2;
            }
        } else {
            if (height * ratio < width) {
                offsetX[0] = (width - height * ratio) / 2;
            } else {    // height * ratio > width
                offsetY = (height - width / ratio) / 2;
            }
        }

        const shiftPoint = (x, y, offsetX, offsetY, scale, isRightPage) => {
            const newY = y - offsetY;
            let newX = x;
            if (isRightPage) {
                newX -= offsetX[1];
            } else {
                newX -= offsetX[0];
            }
            return [newX / scale, newY / scale];
        };

        originalCanvasJSON.objects.forEach((obj) => {
            let isRightPage = false;
            if (obj.type === 'path') {
                isRightPage = isDoublePageMode && obj.path[0][1] >= offsetX[1];
                obj.path.forEach((p) => {
                    if (p[0] === 'z') return;
                    let fixedOffsetX = isRightPage ? offsetX[1] : offsetX[0];
                    p[1] = (p[1] - fixedOffsetX) / scale;
                    p[2] = (p[2] - offsetY) / scale;
                    if (p[0] === 'Q') {
                        p[3] = (p[3] - fixedOffsetX) / scale;
                        p[4] = (p[4] - offsetY) / scale;
                    }
                });
            } else if (obj.type === 'line') {
                if (isDoublePageMode) {
                    if (obj.left >= offsetX[1]) {
                        isRightPage = true;
                    } else if (obj.x1 > 0 && obj.left + obj.width > offsetX[1]) {
                        isRightPage = true;
                    }
                }
                [obj.x1, obj.y1] = shiftPoint(obj.x1, obj.y1, offsetX, offsetY, scale);
                [obj.x2, obj.y2] = shiftPoint(obj.x2, obj.y2, offsetX, offsetY, scale);
            } else if (obj.type === 'circle') {
                isRightPage = isDoublePageMode && obj.left >= offsetX[1];
                obj.radius /= scale;
            } else if (obj.type === 'group' && obj.extra && obj.extra.stamp) {
                isRightPage = isDoublePageMode && obj.left >= offsetX[1];
                obj.scaleX = scale;
                obj.scaleY = scale;
            } else {
                isRightPage = isDoublePageMode && obj.left >= offsetX[1];
                obj.extra = { ...(obj.extra || {}), originalScale: [obj.scaleX / scale, obj.scaleY / scale] };
            }

            [obj.left, obj.top] = shiftPoint(obj.left, obj.top, offsetX, offsetY, scale, isRightPage);
            isRightPage ? rightPageCanvasJSON.objects.push(obj) : leftPageCanvasJSON.objects.push(obj);
        });

        let annotation = {};
        if (!isDoublePageMode) {
            annotation[targetPage] = JSON.stringify(leftPageCanvasJSON);
        } else if (LRFlip === BookFlipType.LEFT_TO_RIGHT) {
            annotation[targetPage] = JSON.stringify(leftPageCanvasJSON);
            annotation[targetPage + 1] = JSON.stringify(rightPageCanvasJSON);
        } else {
            annotation[targetPage] = JSON.stringify(rightPageCanvasJSON);
            annotation[targetPage + 1] = JSON.stringify(leftPageCanvasJSON);
        }

        return annotation;
    }, [LRFlip, bookHeight, bookWidth, canvas, extraFields, height, isDoublePageMode, pageIndex, width]);

    const exportExtendedContentCanvasToJSON = useCallback(({ id } = {}) => {
        const originalCanvasJSON = canvas.toJSON();
        return JSON.stringify(originalCanvasJSON);
    }, [canvas]);

    return { exportCanvasToJSON, exportExtendedContentCanvasToJSON };
}

export const useSaveCanvasJSON = () => {
    const reducers = useStore();
    const [{ canvasJSON }, canvasDispatch] = reducers[StoreTypes.canvas];
    const [{ annotationId }] = reducers[StoreTypes.annotation];
    const [{ isDoublePageMode }] = reducers[StoreTypes.reader];
    const [{ style }] = reducers[StoreTypes.books];
    const { exportCanvasToJSON } = useExportCanvasToJSON();
    const updateAnnotation = useUpdateAnnotation();
    const { readAnnotationById } = useReadAnnotations();

    const decider = useReaderStrategyDecider();
    const strategy = decider.getReaderStrategy();

    const saveCanvasJSON = useCallback(async () => {
        const annotation = exportCanvasToJSON();

        canvasDispatch({
            type: types.CANVAS_EXPORT_SVG,
            canvasJSON: annotation
        });
        const annotations = { ...canvasJSON, ...annotation };
        const result = await updateAnnotation(annotationId, {
            annotations: Object.keys(annotations).map(p => ({
                pageIndex: parseInt(p),
                annotation: annotations[p]
            })),
            isDirty: true,
            updatedAt: Date.now(),
            isDoublePageMode
        });

        strategy && strategy.syncAnnotation(annotationId);
        if (result) {
            EventBus.emit({ event: CanvasEvent.CanvasSavedEvent, payload: { annotation: result } });
        }
        return result;
    }, [annotationId, canvasDispatch, canvasJSON, exportCanvasToJSON, isDoublePageMode, strategy, updateAnnotation]);

    const saveExtendedContentCanvasJSON = useCallback(async ({ annotation, canvasSVGObjectId }) => {

        let { extendedContentAnnotation } = await readAnnotationById({ id: annotationId })
        let checkAnnotation = false;
        const { width, height } = style;
        if (!extendedContentAnnotation) {
            extendedContentAnnotation = []
        }

        const saveInfo = { id: canvasSVGObjectId, annotation, originWidth: width, originHeight: height }
        extendedContentAnnotation = extendedContentAnnotation.map((obj, index) => {
            if (obj.id === canvasSVGObjectId) {
                obj = saveInfo
                checkAnnotation = true;
            }
            return obj
        })

        if (!checkAnnotation) {
            extendedContentAnnotation.push(saveInfo)
        }
        const extendedContentResult = await updateAnnotation(annotationId, {
            extendedContentAnnotation
        });
        EventBus.emit({
            eventBusType: EventBusType.Default,
            event: CanvasEvent.SendCanvasSVGObjectAnnotationEvent,
            payload: { annotations: saveInfo }
        });
        strategy && strategy.syncAnnotation(annotationId);
        return null
    }, [annotationId, readAnnotationById, updateAnnotation, style, strategy]);

    return { saveCanvasJSON, saveExtendedContentCanvasJSON, style };
};

export const useConvertJSONToSVG = () => {
    const reducers = useStore();
    const [{ canvas, canvasJSON }] = reducers[StoreTypes.canvas];
    const [{ bookId, style, books }] = reducers[StoreTypes.books];
    const [{ pageIndex, isDoublePageMode }] = reducers[StoreTypes.reader];
    const book = books.find(book => book.bookId === bookId);
    const { width: bookWidth, height: bookHeight, LRFlip } = book || {};

    const convertJSONToSVG = useCallback(async ({ annotations = canvasJSON, targetPage = pageIndex * (isDoublePageMode ? 2 : 1), pageMode = isDoublePageMode, keepCanvas = false, toSVG = false, size = style } = {}) => {
        const { width, height } = size;
        canvas.clear();
        let mergedCanvasJSON;
        let sourceCanvasJSON = { left: annotations[targetPage], right: pageMode && annotations[targetPage + 1] };
        if (LRFlip === BookFlipType.RIGHT_TO_LEFT) {
            sourceCanvasJSON = { left: pageMode && annotations[targetPage + 1], right: annotations[targetPage] };
        }

        const ratio = bookWidth / bookHeight;

        for (let dir in sourceCanvasJSON) {
            if (!sourceCanvasJSON[dir]) {
                continue;
            }
            if (!mergedCanvasJSON) {
                mergedCanvasJSON = JSON.parse(sourceCanvasJSON[dir]);
                mergedCanvasJSON.objects = [];
            }
            const originalCanvasJSON = JSON.parse(sourceCanvasJSON[dir]);
            let offsetX = 0;
            let offsetY = 0;
            let scale = 1.0;
            let factor = pageMode ? 2 : 1;
            if (bookWidth * factor / bookHeight < width / height) {
                scale = height / bookHeight;
            } else {
                scale = width / (bookWidth * factor);
            }
            if (pageMode) {
                if (height * ratio < width / 2) {
                    offsetX = width / 2 - height * ratio;
                } else {    // height * ratio > width / 2
                    offsetY = (height - (width / 2) / ratio) / 2;
                }
                dir === 'right' && (offsetX = width / 2);
            } else {
                if (height * ratio < width) {
                    offsetX = (width - height * ratio) / 2;
                } else {    // height * ratio > width
                    offsetY = (height - width / ratio) / 2;
                }
            }

            originalCanvasJSON.objects.forEach((obj) => {
                obj.left = obj.left * scale + offsetX;
                obj.top = obj.top * scale + offsetY;
                if (obj.type === 'path') {
                    obj.path.forEach((p) => {
                        if (p[0] === 'z') return;
                        p[1] = p[1] * scale + offsetX;
                        p[2] = p[2] * scale + offsetY;
                        if (p[0] === 'Q') {
                            p[3] = p[3] * scale + offsetX;
                            p[4] = p[4] * scale + offsetY;
                        }
                    });
                } else if (obj.type === 'line') {
                    obj.x1 = obj.x1 * scale + offsetX;
                    obj.x2 = obj.x2 * scale + offsetX;
                    obj.y1 = obj.y1 * scale + offsetY;
                    obj.y2 = obj.y2 * scale + offsetY;
                } else if (obj.type === 'circle') {
                    obj.radius *= scale;
                } else if (obj.type === 'group' && obj.extra && obj.extra.stamp) {
                    obj.scaleX = scale;
                    obj.scaleY = scale;
                } else {
                    const originalScale = (obj.extra && obj.extra.originalScale) || [1, 1];
                    obj.scaleX = originalScale[0] * scale;
                    obj.scaleY = originalScale[1] * scale;
                }
            });

            mergedCanvasJSON.objects.push.apply(mergedCanvasJSON.objects, originalCanvasJSON.objects);
        }

        if (mergedCanvasJSON) {
            await new Promise((resolve) => {
                canvas.loadFromJSON(mergedCanvasJSON, () => {
                    resolve();
                });
            });
        } else {
            return {};
        }

        let annotation = {};
        if (toSVG) {
            let scaledWidth = width, scaledHeight = height;
            if ((width / (pageMode ? 2 : 1)) / height > ratio) {
                scaledWidth = height * ratio * (pageMode ? 2 : 1);
            } else {
                scaledHeight = (width / (pageMode ? 2 : 1)) / ratio;
            }
            let svgIndex = (pageMode ? Math.floor(targetPage / 2) : targetPage);
            annotation[svgIndex] = canvas.toSVG({ width: '100%', height: '100%', viewBox: { x: (width - scaledWidth) / 2, y: (height - scaledHeight) / 2, width: scaledWidth, height: scaledHeight }, suppressPreamble: true });
        }

        if (!keepCanvas) {
            canvas.clear();
        }

        return annotation;
    }, [LRFlip, bookHeight, bookWidth, canvas, canvasJSON, isDoublePageMode, pageIndex, style]);

    return convertJSONToSVG;
};

export const useConvertExtendedContentJSONToSVG = () => {
    const reducers = useStore();
    const [{ style }] = reducers[StoreTypes.books];

    const convertExtendedContentCanvasJSONToSVG = useCallback(async ({ annotation, toSVG = false, originSize = null, size = style } = {}) => {
        const width = originSize ? originSize.originWidth : size.width
        const height = originSize ? originSize.originHeight : size.height

        if (!annotation) {
            return;
        }

        const originalCanvasJSON = annotation;
        let offsetX = 0;
        let offsetY = 0;
        let scaleX = 1.0;
        let scaleY = 1.0;
        scaleX = (style.width) / width;
        scaleY = (style.height) / height;

        originalCanvasJSON.objects.forEach((obj) => {
            obj.left = obj.left * scaleX + offsetX;
            obj.top = obj.top * scaleY + offsetY;
            if (obj.type === 'path') {
                obj.path.forEach((p) => {
                    if (p[0] === 'z') return;
                    p[1] = p[1] * scaleX + offsetX;
                    p[2] = p[2] * scaleY + offsetY;
                    if (p[0] === 'Q') {
                        p[3] = p[3] * scaleX + offsetX;
                        p[4] = p[4] * scaleY + offsetY;
                    }
                });
            } else if (obj.type === 'line') {
                obj.x1 = obj.x1 * scaleX + offsetX;
                obj.x2 = obj.x2 * scaleX + offsetX;
                obj.y1 = obj.y1 * scaleY + offsetY;
                obj.y2 = obj.y2 * scaleY + offsetY;
            } else if (obj.type === 'circle') {
                obj.radius *= scaleX;
            } else if (obj.type === 'group' && obj.extra && obj.extra.stamp) {
                obj.scaleX = scaleX;
                obj.scaleY = scaleY;
            } else {
                const originalScale = (obj.extra && obj.extra.originalScale) || [1, 1];
                obj.scaleX = originalScale[0] * scaleX;
                obj.scaleY = originalScale[1] * scaleY;
            }
        });

        return originalCanvasJSON;
    }, [style]);

    return { convertExtendedContentCanvasJSONToSVG };
};



export const useCanvasEvents = ({ eventBusType = EventBusType.Default, canvasState, canvasDispatch }) => {
    const { canvas, painterMode, painterToolType, painterTool, isActive, stampType, fillType, saveCanvasTime } = canvasState;
    const [{ isDoublePageMode, readerToolType }] = useStore(StoreTypes.reader);
    const [{ annotationId }] = useStore(StoreTypes.annotation);
    const [{ role }] = useStore(StoreTypes.user);
    const [{ style: { width, height }, bookContent }] = useStore(StoreTypes.books);
    const { colorHex, lineWidth, painterType, lineType } = painterTool[painterToolType];
    const mouseFrom = useRef({});
    const [isDrawing, setDrawing] = useState(false);
    const [, setCanvasObject] = useState(null);
    const [canvasObjectIsDrag, setCanvasObjectIsDrag] = useState(false);
    const paintingObject = useRef({});
    const windowSize = useWindowSize();

    window.zoom = window.zoom || 1;

    const drawClickTime = useCallback(() => {
        drawTimeCount++;
        if (drawTimeCount >= 3) {
            //EventBus.emit({ event: ReaderToolsEvent.SetSaveCanvasJsonTimeEvent, payload: { saveCanvasTime: drawTimeCount } });
            clearInterval(drawTime)
        }
    }, [])

    const runDrawClickTime=()=>{
        clearInterval(drawTime)
        drawTimeCount = 0;
        //EventBus.emit({ event: ReaderToolsEvent.SetSaveCanvasJsonTimeEvent, payload: { saveCanvasTime: drawTimeCount } });
        drawTime = setInterval(() => drawClickTime(), 100)
    }

    canvas.off('mouse:down');
    canvas.off('mouse:up');
    canvas.off('mouse:move');
    canvas.off('selection:created');
    canvas.off('selection:updated');
    canvas.off('selection:cleared');
    canvas.off('object:scaled');
    canvas.off('object:moved');
    canvas.off('object:added');
    canvas.off('after:render');
    canvas.on('mouse:down', function (options) {
        clearInterval(canvasReanderTime)
        mouseFrom.current = options.e === 'MouseEvent' ? transformMouse(options.e.offsetX, options.e.offsetY) : transformMouse(options.pointer.x, options.pointer.y);
        setCanvasObjectIsDrag(false)

        const canvasObject = getCanvasObject(mouseFrom.current)
        if (canvasObject) {
            setCanvasObject(canvasObject)
            return;
        }

        if (painterMode === PainterMode.Painting) {
            paintingObject.current[painterType] = createObject(painterType, mouseFrom.current);
            if (paintingObject.current[painterType]) {
                setDrawing(true);
                canvas.add(paintingObject.current[painterType]).requestRenderAll();
            }
        }
    });
    canvas.on('mouse:up', (options) => {
        //runDrawClickTime();

        let doReimportSVG = true;
        let { x, y } = options.e === 'MouseEvent' ? transformMouse(options.e.offsetX, options.e.offsetY) : transformMouse(options.pointer.x, options.pointer.y);

        if (getCanvasObject(mouseFrom.current)) {
            if (!canvasObjectIsDrag) {
                EventBus.emit({ event: ReaderEvent.ClickCanvasSVGObjectEvent, payload: { annotationId, id: getCanvasObject(mouseFrom.current).id } });
            }
            setCanvasObjectIsDrag(false)
            return;
        }

        if (painterMode === PainterMode.InsertText) {
            const iText = new fabric.IText('');
            iText.set({
                left: x,
                top: y,
                angle: 0,
                padding: 10,
                cornerSize: 10,
                fill: '#000000',
                fontSize: 24
            });
            EventBus.emit({ eventBusType, event: CanvasEvent.CanvasTextCreatedEvent, payload: { object: iText } });

            iText.on('changed', () => {
                //runDrawClickTime();
                EventBus.emit({ eventBusType, event: CanvasEvent.CanvasTextChangedEvent });
            });

            iText.on("editing:entered", () => {
                document.onclick = e => {
                    console.log("element", e.target.tagName.toLowerCase())
                    console.log("iText", iText)
                    if (e.target.tagName.toLowerCase() !== 'canvas') {
                        iText.exitEditing();
                        document.onclick = null;
                    }
                }
            })

            iText.enterEditing();

            return;
        } else if (painterMode === PainterMode.InsertImage) {
            doReimportSVG = false;
            mouseFrom.current = { x, y };
            EventBus.emit({ eventBusType, event: CanvasEvent.CanvasChooseImageSourceEvent });
            return;
        } else if (painterMode === PainterMode.Stamp) {
            StampMap[stampType] && fabric.loadSVGFromURL(StampMap[stampType], (objects, options) => {
                const bookWidth = bookContent[0].width;
                const bookHeight = bookContent[0].height;
                let scale = Math.min(width / (bookWidth * (isDoublePageMode ? 2 : 1)), height / bookHeight);

                const obj = fabric.util.groupSVGElements(objects, options);
                obj.set({
                    id: short_uuid(),
                    left: x,
                    top: y,
                    originX: "center",
                    originY: "center",
                    scaleX: scale,
                    scaleY: scale,
                    extra: {
                        stamp: true
                    }
                });
                EventBus.emit({ eventBusType, event: CanvasEvent.CanvasStampCreatedEventEvent, payload: { object: obj } });
            });
            return;
        } else if (painterMode === PainterMode.Eraser) {
            removeCanvasObjectData(canvas.getActiveObjects())
            canvasDispatch({ type: types.CANVAS_ERASE_OBJECT });

        }

        if (isActive && eventBusType === EventBusType.Default) {
            EventBus.emit({ eventBusType, event: CanvasEvent.CanvasFinishPaintingEvent });
        }

        setDrawing(false);
        paintingObject.current[painterType] = null;
    });

    canvas.on('mouse:move', function (options) {
        let pos = options.e === 'MouseEvent' ? transformMouse(options.e.offsetX, options.e.offsetY) : transformMouse(options.pointer.x, options.pointer.y);
        setCanvasObjectIsDrag(true)

        if (isDrawing && paintingObject.current[painterType]) {
            updateObject(paintingObject.current[painterType], painterType, mouseFrom.current, pos);
        }
    });

    const selectionHandler = useCallback(e => {
        if (e.target.extra !== undefined) {
            const { type } = e.target.extra
            if (type === CanvasSVGObjectContentType.StickyNote) { return; }
        }
        const isLeft = e.target.left > (windowSize.width / 2);
        const { type } = e.target;
        let eventType = CanvasEvent.CanvasObjectSelectedEvent;
        if (type.toLowerCase() === 'i-text' && role !== Roles.EDITOR) {
            eventType = CanvasEvent.CanvasTextObjectSelectedEvent;
        }
        EventBus.emit({
            eventBusType, event: eventType,
            payload: { activeCanvasObject: e.target, sideToolDirection: isLeft ? SideToolDirection.LEFT : SideToolDirection.RIGHT }
        });

    }, [eventBusType, role, windowSize.width]);

    const selectionClearHandler = useCallback(e => {
        EventBus.emit({ eventBusType, event: CanvasEvent.CanvasSelectionClearEvent });
        if (readerToolType === ReaderToolType.Text) {
            EventBus.emit({ event: ReaderToolsEvent.ClickSelectEvent, payload: { painterMode: PainterMode.Selection } });
            EventBus.emit({ event: ReaderToolsEvent.SetReaderToolTypeEvent, payload: { readerToolType: ReaderToolType.Select } });
        }
    }, [eventBusType, readerToolType])

    canvas.on('selection:created', selectionHandler);
    canvas.on('selection:updated', selectionHandler);
    canvas.on('selection:cleared', selectionClearHandler);

    const objectModifiedHandler = e => {
        const object = e.target;
        object.height = Math.round(object.height) || 1;
        object.width = Math.round(object.width) || 1;
        object.top = Math.round(object.top) || 0;
        object.left = Math.round(object.left) || 0;
    }
    canvas.on('object:added', objectModifiedHandler);
    canvas.on('object:scaled', objectModifiedHandler);
    canvas.on('object:moved', objectModifiedHandler);

    canvas.on('after:render', () => {
        // keep a visible frame on each invisible object
        canvas.contextContainer.strokeStyle = 'rgba(255, 0, 0, 0.15)';
        canvas.forEachObject(function (obj) {
            if (obj.opacity === 0) {
                var bound = obj.getBoundingRect();

                canvas.contextContainer.strokeRect(
                    bound.left + 0.5,
                    bound.top + 0.5,
                    bound.width,
                    bound.height
                );
            }
        })
    });

    //座標轉換
    const transformMouse = (mouseX, mouseY) => {
        return { x: mouseX / window.zoom, y: mouseY / window.zoom };
    }

    const getCanvasObject = (pointer) => {
        const canvasObject = canvas.getObjects().find((obj) => {
            if (obj.extra) {
                if (obj.containsPoint(pointer) && obj.extra.type) {
                    switch (obj.extra.type) {
                        case CanvasSVGObjectContentType.StickyNote:
                        case CanvasSVGObjectContentType.Whiteboard:
                            return obj;
                        default:
                            console.error(`CanvasSVG Object ContentType ${obj.extra.type} is not supported`);
                            return null;
                    }
                }
            }
            return null;
        })
        return canvasObject;
    }

    const removeCanvasObjectData = (objects) => {
        const objectIds = objects.map((obj) => {
            return obj.id
        })
        EventBus.emit({ eventBusType, event: ReaderEvent.DeleteCanvasSVGObjectInfoEvent, payload: { objectIds } });
    }

    const createObject = (painterType, pos) => {
        let canvasObject = null;
        const transparent = 'rgba(255, 255, 255, 0)';
        switch (painterType) {
            case PainterType.Arrow: //箭頭
                canvasObject = new fabric.Path(`M ${pos.x} ${pos.y}`, {
                    strokeDashArray: lineType === LineType.Line ? [0, 0] : [5, 5],
                    stroke: colorHex,
                    fill: 'rgba(255,255,255,0)',
                    strokeWidth: lineWidth
                });
                break;
            case PainterType.Line: //直線
                canvasObject = new fabric.Line([pos.x, pos.y, pos.x, pos.y], {
                    strokeDashArray: lineType === LineType.Line ? [0, 0] : [5, 5],
                    stroke: colorHex,
                    strokeWidth: lineWidth
                });
                break;
            // case PainterType.DottedLine: //虛線
            //     canvasObject = new fabric.Line([pos.x, pos.y, pos.x, pos.y], {
            //         strokeDashArray: [3, 1],
            //         stroke: colorHex,
            //         strokeWidth: lineWidth
            //     });
            //     break;
            case PainterType.RightTriangle: //直角三角形
            case PainterType.IsoscelesTriangle: //等腰三角形
                const path = `M ${pos.x} ${pos.y}`;
                canvasObject = new fabric.Path(path, {
                    strokeDashArray: lineType === LineType.Line ? [0, 0] : [5, 5],
                    stroke: fillType === FillType.Solid ? transparent : colorHex,
                    strokeWidth: lineWidth,
                    fill: fillType === FillType.Solid ? colorHex : transparent
                });
                break;
            case PainterType.Circle: //正圆
            case PainterType.SolidCircle: //實心正圆
                canvasObject = new fabric.Circle({
                    strokeDashArray: lineType === LineType.Line ? [0, 0] : [5, 5],
                    left: pos.x,
                    top: pos.y,
                    stroke: fillType === FillType.Solid ? transparent : colorHex,
                    fill: fillType === FillType.Solid ? colorHex : transparent,
                    radius: 0,
                    strokeWidth: lineWidth,
                    originX: 'center',
                    originY: 'center'
                });
                break;
            case PainterType.Rectangle: //方形
                canvasObject = new fabric.Rect({
                    strokeDashArray: lineType === LineType.Line ? [0, 0] : [5, 5],
                    left: pos.x,
                    top: pos.y,
                    width: 0,
                    height: 0,
                    stroke: fillType === FillType.Solid ? transparent : colorHex,
                    strokeWidth: lineWidth,
                    fill: fillType === FillType.Solid ? colorHex : transparent,
                });
                break;
            default:
                break;
        };

        return canvasObject;
    }

    const updateObject = (obj, painterType, origin, pos) => {
        obj.dirty = true;
        let newPath;
        switch (painterType) {
            case PainterType.Arrow: //箭頭
                newPath = obj.path.slice(0, 1);
                newPath.push(['L', pos.x, pos.y]);
                newPath.push.apply(newPath, drawArrow(origin.x, origin.y, pos.x, pos.y, 30, 30));
                obj.path = newPath;
                obj.left = Math.min(pos.x, origin.x);
                obj.top = Math.min(pos.y, origin.y);
                obj.width = Math.abs(pos.x - origin.x);
                obj.height = Math.abs(pos.y - origin.y);
                obj.pathOffset.x = obj.left + obj.width / 2;
                obj.pathOffset.y = obj.top + obj.height / 2;
                break;
            case PainterType.Line: //直線
            case PainterType.DottedLine: //虛線
                obj.x2 = pos.x;
                obj.y2 = pos.y;
                obj.left = Math.min(pos.x, origin.x);
                obj.top = Math.min(pos.y, origin.y);
                obj.width = Math.abs(pos.x - origin.x);
                obj.height = Math.abs(pos.y - origin.y);
                break;
            case PainterType.RightTriangle: //直角三角形
                newPath = obj.path.slice(0, 1);
                newPath.push(['L', origin.x, pos.y]);
                newPath.push(['L', pos.x, pos.y]);
                newPath.push(['z']);
                obj.path = newPath;
                obj.left = Math.min(pos.x, origin.x);
                obj.top = Math.min(pos.y, origin.y);
                obj.width = Math.abs(pos.x - origin.x);
                obj.height = Math.abs(pos.y - origin.y);
                obj.pathOffset.x = obj.left + obj.width / 2;
                obj.pathOffset.y = obj.top + obj.height / 2;
                break;
            case PainterType.IsoscelesTriangle: //等腰三角形
                let bottomLength = (pos.x - origin.x) * 2;
                newPath = obj.path.slice(0, 1);
                newPath.push(['L', pos.x, pos.y]);
                newPath.push(['L', pos.x - bottomLength, pos.y]);
                newPath.push(['z']);
                obj.path = newPath;
                obj.left = Math.min(pos.x, origin.x, pos.x - bottomLength);
                obj.top = Math.min(pos.y, origin.y);
                obj.width = Math.abs(pos.x - origin.x) * 2;
                obj.height = Math.abs(pos.y - origin.y);
                obj.pathOffset.x = obj.left + obj.width / 2;
                obj.pathOffset.y = obj.top + obj.height / 2;
                break;
            case PainterType.Circle: //正圆
            case PainterType.SolidCircle: //實心正圆
                obj.radius = Math.sqrt(Math.pow((origin.x - pos.x), 2) + Math.pow((origin.y - pos.y), 2));
                obj.width = obj.radius * 2;
                obj.height = obj.radius * 2;
                break;
            case PainterType.Rectangle: //方形
                obj.left = Math.min(pos.x, origin.x);
                obj.top = Math.min(pos.y, origin.y);
                obj.width = Math.abs(pos.x - origin.x);
                obj.height = Math.abs(pos.y - origin.y);
                break;
            default:
                break;
        };

        obj.setCoords();
        canvas.requestRenderAll();
    };

    const drawArrow = (fromX, fromY, toX, toY, theta = 30, arrowLen = 10) => {
        const angle = Math.atan2(fromY - toY, fromX - toX) * 180 / Math.PI,
            angle1 = (angle + theta) * Math.PI / 180,
            angle2 = (angle - theta) * Math.PI / 180,
            topX = arrowLen * Math.cos(angle1),
            topY = arrowLen * Math.sin(angle1),
            botX = arrowLen * Math.cos(angle2),
            botY = arrowLen * Math.sin(angle2);
        let arrowLeftX = toX + topX;
        let arrowLeftY = toY + topY;
        let arrowRightX = toX + botX;
        let arrowRightY = toY + botY;
        let path = [
            ['M', arrowLeftX, arrowLeftY],
            ['L', toX, toY],
            ['L', arrowRightX, arrowRightY]
        ];
        return path;
    }

    const addCanvasImage = useCallback(async ({ url },callback) => {

        fabric.Image.fromURL(url,  (img) => {
            EventBus.emit({ eventBusType, event: CanvasEvent.CanvasImageCreatedEvent, payload: { object: img } });
            EventBus.emit({
                event: ReaderToolsEvent.SVGCanvasSwitchEvent, payload: { switchType: SVGCanvasSwitchType.CANVAS }
            });
            callback();
        },
            {
                left: mouseFrom.current.x,
                top: mouseFrom.current.y,
                angle: 0,
                padding: 10,
                cornerSize: 10
            }
        );

    }, [eventBusType]);

    return {
        addCanvasImage
    }
};

export const useFlushAnnotations = () => {
    const reducers = useStore();
    const [{ canvasJSON }, canvasDispatch] = reducers[StoreTypes.canvas];
    const [{ isDoublePageMode, pageIndex }] = reducers[StoreTypes.reader];
    const convertJSONToSVG = useConvertJSONToSVG();

    const flushAnnotations = useCallback(async ({ annotations = canvasJSON, currentPageIndex = pageIndex, pageMode = isDoublePageMode, size }) => {
        //canvasDispatch({ type: types.CANVAS_RESET_SVG });
        const annotationPages = Object.keys(annotations).map(v => parseInt(v));
        let needDumpPages = pageMode ? [...new Set(annotationPages.map(v => Math.floor(v / 2)))] : annotationPages.slice();
        needDumpPages = needDumpPages.filter(v => v !== currentPageIndex).concat(currentPageIndex).map(v => pageMode ? v * 2 : v);
        const canvasSVG = await needDumpPages.reduce((promiseChain, targetPage) => {
            return promiseChain.then(chainResults => {
                return convertJSONToSVG({
                    annotations,
                    targetPage,
                    pageMode,
                    keepCanvas: targetPage === (currentPageIndex * (pageMode ? 2 : 1)),
                    size,
                    toSVG: true
                }).then(annotation => ({ ...chainResults, ...annotation }));
            });
        }, Promise.resolve({}));
        canvasDispatch({
            type: types.CANVAS_IMPORT_SVG,
            canvasSVG
        });
        return canvasSVG;
    }, [canvasDispatch, canvasJSON, convertJSONToSVG, isDoublePageMode, pageIndex]);

    return flushAnnotations;
};

export const useExportInteractiveObjects = () => {
    const reducers = useStore();
    const [{ token }] = reducers[StoreTypes.user];
    const [{ canvasJSON }, canvasDispatch] = reducers[StoreTypes.canvas];
    const [{ bookId, style }] = reducers[StoreTypes.books];
    const [{ annotationId }] = reducers[StoreTypes.annotation];
    const { exportCanvasToJSON } = useExportCanvasToJSON();
    const convertJSONToSVG = useConvertJSONToSVG();
    const markAnnotationSynced = useMarkAnnotationSynced();

    const exportInteractiveObjects = useCallback(async (pageIndices = []) => {
        const annotation = exportCanvasToJSON();
        canvasDispatch({
            type: types.CANVAS_EXPORT_SVG,
            canvasJSON: annotation
        });

        let annotations = { ...canvasJSON, ...annotation };
        if (pageIndices.length > 0) {
            const filteredAnnotations = {};
            for (let pageIndex in annotations) {
                if (pageIndices.includes(parseInt(pageIndex))) {
                    filteredAnnotations[pageIndex] = annotations[pageIndex];
                }
            }
            annotations = filteredAnnotations;
        }
        const results = await Object.keys(annotations).reduce((promiseChain, targetPage) => {
            return promiseChain.then(chainResults => {
                return convertJSONToSVG({
                    annotations,
                    targetPage,
                    pageMode: false,
                    keepCanvas: true,
                    size: style,
                    toSVG: true
                }).then(annotation => ({ ...chainResults, ...annotation }));
            });
        }, Promise.resolve({}));

        const interactiveObjects = Object.keys(annotations).map(pageIndex => {
            const json = JSON.parse(annotations[pageIndex]).objects
                .map((obj) => Object.values(ExtraFieldsForEditor).reduce((acc, field) => {
                    acc[field] = obj[field];
                    return acc;
                }, {}));
            return {
                pageIndex: parseInt(pageIndex),
                json,
                svg: results[pageIndex]
            };
        });

        const canvasSVG = await convertJSONToSVG({
            keepCanvas: true
        });
        canvasDispatch({ type: types.CANVAS_IMPORT_SVG, canvasSVG });

        if (interactiveObjects) {
            const obj = {
                interactiveObjectId: annotationId,
                bookId,
                interactiveObjects
            };

            try {
                const response = await API.postJson(`${process.env.REACT_APP_API_DOMAIN}/submitInteractiveObjects`, {
                    ...obj,
                    token: { jwt: token },
                    interactiveMetaObjects: Object.keys(annotations).map(key => ({
                        pageIndex: parseInt(key),
                        annotation: annotations[key]
                    }))
                });
                if (response.status === 'success') {
                    await markAnnotationSynced({ id: annotationId, lastSyncedAt: response.content.updatedAt });
                }
            } catch (err) {
                console.log(`submitInteractiveObjects error caught`, err);
            }
        }
    }, [annotationId, bookId, canvasDispatch, canvasJSON, convertJSONToSVG, exportCanvasToJSON, markAnnotationSynced, style, token]);

    return exportInteractiveObjects;
};

export const useFlipBook = () => {
    const reducers = useStore();
    const [, canvasDispatch] = reducers[StoreTypes.canvas];
    const [{ isDoublePageMode }, readerDispatch] = reducers[StoreTypes.reader];
    const convertJSONToSVG = useConvertJSONToSVG();

    const doFlipBook = useCallback(async ({ convertToSVG, keepCanvas, pageIndex }) => {
        if (convertToSVG) {
            const canvasSVG = await convertJSONToSVG({ toSVG: true });
            canvasDispatch({
                type: types.CANVAS_IMPORT_SVG,
                canvasSVG
            });
        }
        await convertJSONToSVG({ keepCanvas, targetPage: pageIndex * (isDoublePageMode ? 2 : 1) });
        readerDispatch({
            type: types.SET_BOOK_PAGE_INDEX,
            pageIndex
        });
    }, [canvasDispatch, convertJSONToSVG, isDoublePageMode, readerDispatch])
    return doFlipBook;
}

export const useCreateMarkRect = () => {
    const reducers = useStore();
    const [{ canvas }] = reducers[StoreTypes.canvas];
    const [{ offsetX, offsetY, scale, fullWidthInfo }] = useStore(StoreTypes.reader);
    const createRect = useCallback((pos) => {
        let canvasObject = new fabric.Rect({
            left: ((pos.x - offsetX) / (fullWidthInfo.scale)) / scale + fullWidthInfo.offset,
            top: (pos.y - offsetY) / scale,
            width: (pos.width / fullWidthInfo.scale) / scale,
            height: pos.height / scale,
            fill: 'rgba(255, 255, 0, 0.4)'
        });
        canvas.add(canvasObject).requestRenderAll();
        EventBus.emit({ eventBusType: EventBusType.Default, event: CanvasEvent.CanvasFinishPaintingEvent });
    }, [canvas, fullWidthInfo.offset, fullWidthInfo.scale, offsetX, offsetY, scale])
    return createRect
}


export const useCreateCourseInteractiveItem = () => {
    const [{ isDoublePageMode }] = useStore(StoreTypes.reader);
    const [{ style: { width, height }, bookContent }] = useStore(StoreTypes.books);

    const getSVGIcon = useCallback(({ contentType }) => {
        switch (contentType) {
            case CanvasSVGObjectContentType.StickyNote:
                return 'assets/icon/page-stickynote.svg';
            case CanvasSVGObjectContentType.Whiteboard:
                return 'assets/icon/whiteboard.svg';
            default:
                console.error(`ContentType ${contentType} is not supported`);
                return null;
        }
    })

    const createCourseInteractiveItem = useCallback(({ id, contentType }) => {
        const icon = getSVGIcon({ contentType })
        if (!icon) return;
        fabric.loadSVGFromURL(icon, (objects, options) => {
            const bookWidth = bookContent[0].width;
            const bookHeight = bookContent[0].height;
            let scale = Math.min(width / (bookWidth * (isDoublePageMode ? 2 : 1)), height / bookHeight);
            const obj = fabric.util.groupSVGElements(objects, options);
            obj.set({
                id: `${id}`,
                left: width / 2 + Math.floor(Math.random() * 100) - 50,
                top: height / 2 + Math.floor(Math.random() * 100) - 50,
                originX: "center",
                originY: "center",
                scaleX: scale,
                scaleY: scale,
                hasControls: false,
                extra: {
                    type: contentType
                }
            });
            EventBus.emit({ eventBusType: EventBusType.Default, event: CanvasEvent.CreatedCanvasObjectEvent, payload: { object: obj } });
        })
    }, [getSVGIcon, bookContent, height, isDoublePageMode, width])
    return createCourseInteractiveItem
}

export const useRemoteAllCanvasObjects = () => {
    const reducers = useStore();
    const [{ canvas }] = reducers[StoreTypes.canvas];

    const remoteAllCanvasObjects = useCallback(({ eventBusType = EventBusType.Default }) => {
        //原本使用canvas.getActiveObjects()取不到物件，改用canvas.getObjects()
        const objectIds = canvas.getObjects().map((obj) => {
            return obj.id
        })
        EventBus.emit({ eventBusType, event: ReaderEvent.DeleteCanvasSVGObjectInfoEvent, payload: { objectIds } });
    }, [canvas])
    return remoteAllCanvasObjects
}
