
import React, { useCallback, useEffect, useState } from 'react';
import { useStore, StoreTypes } from 'context';
import { DeviceType } from 'constants/chimeTypes'
import styles from './index.module.scss';
import { Roles } from 'constants/role';
import ChatRoom from 'components/ChatRoom';
import classnames from 'classnames';
import SelectComponent from 'components/SelectComponent'
import Icon from 'components/Icon';
import { TeacherVideo } from 'components/SideToolContents/LiveStreamViewerPanel';
import { StudentVideo } from 'components/LiveStreamMasterPanel';
import { API } from 'api';
import DialogModule from 'components/Dialogs/'
import Button from '@material-ui/core/Button';
import {
    ReaderEvent,
  } from 'events/EventTypes';

import { EventBus } from 'events/EventBus';

import {
    useVideoInputs,
    useSelectVideoInputDevice,
    useAudioInputs,
    useAudioOutputs,
    useLocalVideo,
    useToggleLocalMute,
    useLocalAudioOutput,
    useMeetingManager,
} from 'amazon-chime-sdk-component-library-react';

const LiveStreamAdminPanel = ({ isShow, channelName, userId, role }) => {
    const [{ token }] = useStore(StoreTypes.user);
    const [{ courseId: roomId, rewardInfo, showfinishedButton ,isFullVideo}, courseDispatch] = useStore(StoreTypes.course);
    const { fireworks, good, like, trophy } = rewardInfo;

    const meetingManager = useMeetingManager();

    const { isVideoEnabled, toggleVideo } = useLocalVideo();
    const { devices: videoInputDevices } = useVideoInputs();
    const selectVideoInput = useSelectVideoInputDevice();

    const { devices: audioInputDevices } = useAudioInputs();
    const { devices: audioOutputDevices } = useAudioOutputs();

    const { muted, toggleMute } = useToggleLocalMute();
    const { isAudioOn, toggleAudio } = useLocalAudioOutput();

    const [dialogOpen, setdialogOpen] = useState(false);

    const formatDeviceValue = useCallback(devices => devices.map(item => (
        { label: item.label, value: item.deviceId }
    )
    ), [])

    useEffect(() => {
        if (!muted) {
            toggleMute();
        }

        if (!isAudioOn) {
            toggleAudio();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fullScreen = useCallback((role) => {
        EventBus.emit({ event: ReaderEvent.setFullScreenTargetEvent, payload: { fullScreenTarget: role } });
    }, [])

    const dialogText = <div
        id="dialogText"
    >確認要將此堂課設為結束嗎?</div>

    const dialogAction =
        <div className={styles.dialogAskButton}>
            <Button onClick={async () => {
                if (!roomId || !token) return;
                const options = { headers: { Authorization: token } }
                const url = `${process.env.REACT_APP_ONE_TUTOR_API_DOMAIN}/admin/tutorials/${roomId}/terminate`
                const tutorInfo = await API.cloudFuncPost2(url, options);
                setdialogOpen(false)
            }}
                color="primary"
                style={{
                    border: "solid",
                    color: "blue",
                    borderWidth: 2,
                    marginRight: 5
                }}
            >
                確定
            </Button>
            <Button
                onClick={() => {
                    setdialogOpen(false)
                }}
                color="primary"
                style={{
                    border: "solid",
                    color: "blue",
                    borderWidth: 2,
                    marginRight: 5
                }}
            >
                取消
            </Button>
        </div>;

    return (
        <div className={classnames(styles.discussionPanel, styles.wrapper)}>
            <div id="content" className={styles.chatContent}>
                <div className={styles.toolsControls}>
                    {
                        videoInputDevices.length > 0 &&
                        <div className={styles.localVideoSelect}>
                            <div onClick={toggleVideo}>
                                {
                                    isVideoEnabled ?
                                        <Icon name="OpenVideo" /> :
                                        <Icon name="CloseVideo" />
                                }
                            </div>
                            <SelectComponent
                                type={DeviceType.VideoDevice}
                                optionsList={formatDeviceValue(videoInputDevices)}
                                defaultSelectText={videoInputDevices[0].label}
                                onChange={selectVideoInput}
                            />
                        </div>
                    }
                    {
                        audioInputDevices.length > 0 &&
                        <div className={styles.localSound}>
                            <div onClick={toggleMute}>
                                {
                                    muted ?
                                        <Icon name="CloseAudioInput" /> :
                                        <Icon name="OpenAudioInput" />
                                }
                            </div>
                            <SelectComponent
                                type={DeviceType.AudioInputDevice}
                                optionsList={formatDeviceValue(audioInputDevices)}
                                defaultSelectText={audioInputDevices[0] && audioInputDevices[0].label}
                                onChange={meetingManager.selectAudioInputDevice}
                            />
                        </div>
                    }
                    {
                        audioOutputDevices.length > 0 &&
                        <div className={styles.localAudio}>
                            <div onClick={toggleAudio}>
                                {
                                    isAudioOn ?
                                        <Icon name="OpenAudioOutput" /> :
                                        <Icon name="CloseAudioOutput" />
                                }
                            </div>
                            <SelectComponent
                                type={DeviceType.AudioOutputDevice}
                                optionsList={formatDeviceValue(audioOutputDevices)}
                                defaultSelectText={audioOutputDevices[0] && audioOutputDevices[0].label}
                                onChange={meetingManager.selectAudioOutputDevice}
                            />
                        </div>
                    }
                    {
                        showfinishedButton && <div className={styles.offCourse} onClick={
                            () => setdialogOpen(true)
                        }>
                            <Icon name="OffCourse" />
                        </div>
                    }
                </div>
                <div className={styles.localVideo} onClick={() => fullScreen(Roles.TUTOR)}>
                    <TeacherVideo />
                    <div className={classnames(styles.fullVideoMessage, { [styles.fullVideoMessageHide]: !isFullVideo })} >
                        <Icon name="StudentVideoIsFull" />
                    </div>
                </div>
                <div className={styles.localVideo} onClick={() => fullScreen(Roles.TUTOR_USER)}>
                    <StudentVideo />
                    <div className={classnames(styles.fullVideoMessage, { [styles.fullVideoMessageHide]: !isFullVideo })} >
                        <Icon name="StudentVideoIsFull" />
                    </div>
                </div>
                {
                    (role === Roles.ADMIN) &&
                    <div className={styles.emojiCounts}>
                        <div className={styles.emojiCount}>
                            <div><Icon name="FireworksIcon" /></div>
                            <div className={styles.countText}>{fireworks && fireworks}</div>
                        </div>
                        <div className={styles.emojiCount}>
                            <div><Icon name="GoodIcon" /></div>
                            <div className={styles.countText}>{good && good}</div>
                        </div>
                        <div className={styles.emojiCount}>
                            <div><Icon name="LikeIcon" /></div>
                            <div className={styles.countText}>{like && like}</div>
                        </div>
                        <div className={styles.emojiCount}>
                            <div><Icon name="TrophyIcon" /></div>
                            <div className={styles.countText}>{trophy && trophy}</div>
                        </div>
                    </div>
                }
                <div className={classnames(styles.chatRoomWrapper, { [styles.tutoruser]: role === Roles.TUTOR_USER || role === Roles.ADMIN })}>
                    <ChatRoom />
                </div>
            </div>


            <DialogModule
                open={dialogOpen}
                setOpen={setdialogOpen}
                content={dialogText}
                action={dialogAction}
            />
        </div>
    )
}



export default LiveStreamAdminPanel;
