import React, { useCallback, useState, useEffect } from 'react';
import { DefaultPanelColors, DefaultPanelBackgroundColors } from 'constants/colors';
import ColorSelector from 'components/Painter/ColorSelector';
import Select from 'components/common/Select';
import Icon from 'components/Icon';
import { EventBus } from 'events/EventBus';
import { CanvasEvent } from 'events/EventTypes';
import classnames from 'classnames';
import styles from './index.module.scss';


const fontSizes = [12,16,20,24,28,32,36,48,72];

const fontStyleType = {
  normal:'normal',
  italic:'italic'
}

const fontWeightType = {
  normal:'normal',
  bold: 'bold'
}

const TextPanel = ({ eventBusType, activeCanvasObject }) => {
  const [isDirty,setIsDirty] = useState(false);

  useEffect(()=>{
    isDirty && setIsDirty(!isDirty)
  },[isDirty])

  const modifyCanvasObjectProperty = useCallback((propertyName, value )=>{
    if (activeCanvasObject) {
      EventBus.emit({
          eventBusType,
          event: CanvasEvent.ModifyCanvasObjectPropertyEvent,
          payload: { 
            object:activeCanvasObject, 
            property: propertyName, 
            value 
          }
      });
      setIsDirty(true)
    }
  },[activeCanvasObject, eventBusType])



  const fontSizeChangeHandler = useCallback(e => {
    modifyCanvasObjectProperty('fontSize',e.target.value)
  },[modifyCanvasObjectProperty])

  const toggleFontStyleHandler = useCallback(value => () => {
    modifyCanvasObjectProperty('fontStyle', value === fontStyleType.normal ? fontStyleType.italic : fontStyleType.normal)
  },[modifyCanvasObjectProperty])

  const toggleFontWeightHandler = useCallback(value => () =>{
    modifyCanvasObjectProperty('fontWeight', value === fontWeightType.normal ? fontWeightType.bold : fontWeightType.normal)
  },[modifyCanvasObjectProperty])

  const toggleUnderlineHandler = useCallback(value => () =>{
    modifyCanvasObjectProperty('underline', value ? false : true)
  },[modifyCanvasObjectProperty])

  const onColorChangeHandler = useCallback(value=>{
    modifyCanvasObjectProperty('fill', value)
  },[modifyCanvasObjectProperty])

  const onBgColorChangeHandler = useCallback(value=>{
    modifyCanvasObjectProperty('backgroundColor', value)
  },[modifyCanvasObjectProperty])

  return activeCanvasObject && (
    <div className={styles.textPanel}>
      <div className={styles.group}>
        <div className={styles.title}>文字顏色</div>
        <ColorSelector colors={DefaultPanelColors} value={activeCanvasObject.fill} onChange={onColorChangeHandler} />
      </div>
      <div className={styles.group}>
        <div className={styles.title}>文字樣式</div>
        <div className={styles.textStyles}>
          <Select className={styles.select} value={activeCanvasObject.fontSize} onChange={fontSizeChangeHandler}>
            {fontSizes.map(item=>(
              <option value={item}>{item}</option>
            ))}
          </Select>
          <span 
            className={classnames(styles.textStyle,{ [styles.active]: activeCanvasObject.fontWeight === fontWeightType.bold })} 
            onClick={toggleFontWeightHandler(activeCanvasObject.fontWeight)}
          >
            <Icon type="text" name="bold" />
          </span>
          <span 
            className={classnames(styles.textStyle,{ [styles.active]: activeCanvasObject.fontStyle === fontStyleType.italic })} 
            onClick={toggleFontStyleHandler(activeCanvasObject.fontStyle)}
          >
            <Icon type="text" name="italic" />
          </span>
          <span 
            className={classnames(styles.textStyle,{ [styles.active]: activeCanvasObject.underline })} 
            onClick={toggleUnderlineHandler(activeCanvasObject.underline)}
          >
            <Icon type="text" name="underline" />
          </span>
        </div>
      </div>
      <div className={styles.group}>
        <div className={styles.title}>文字底色</div>
        <ColorSelector 
          colors={DefaultPanelBackgroundColors} 
          value={activeCanvasObject.backgroundColor} 
          customizedColor={false} 
          transparentOption={true}
          onChange={onBgColorChangeHandler}
        />
      </div>
    </div>
  )
}

export default TextPanel;