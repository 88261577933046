import React, { useEffect } from 'react';
import styles from "./index.module.scss";
// import FillColorSelector from 'components/Tools/FillColorSelector';
import { useStore, StoreTypes } from 'context';
import Repository from 'repositories/Repository';
import useSetState from 'customHooks/setState';

const { ExtendedResourceRepository } = Repository;
export const ExtendedContentButton = props => {
    const html = props.el.props && props.el.props.html ? props.el.props.html : false
    const synopsisInfo = props.el.props && props.el.props.synopsisInfo ? props.el.props.synopsisInfo : false

    // to do...
    const styleVersion = props.el.props ? props.el.props.extendedResourceStyle : null;
    const schoolYear = styleVersion ? styleVersion.split("_").includes("108") : false
    const semester = styleVersion ? styleVersion.split("_").includes("2") : false



    const [{ bookId }] = useStore(StoreTypes.books);
    const { tab, list } = synopsisInfo ? synopsisInfo : {};
    const [{ synopsisList, tabIndex, template }, setState] = useSetState({
        template: '',
        synopsisList: [],
        tabIndex: tab - 1
    });
    const isAnswers = synopsisList.some(obj => obj.html.includes('ANSWERS'))


    useEffect(() => {
        if (synopsisInfo) {
            Promise.all(list.map(lists => ExtendedResourceRepository.getJSONContent({ bookId, pathName: lists.src }))).then((obj) => {
                setState({ synopsisList: obj.filter(item => item) });
            })
        }
    }, [bookId, list, setState, synopsisInfo]);


    useEffect(() => {
        if (html) {
            setState({ template: props.el.props.html })
        }

        if (synopsisInfo && synopsisList.length > 0) {
            setState({ template: synopsisList[tabIndex].html })
        }
    }, [html, props.el.props, setState, synopsisInfo, synopsisList, tabIndex])





    const hasClass = (el, cls) => {
        return (' ' + el.className + ' ').indexOf(' ' + cls + ' ') > -1;
    }

    const toggleShow = id => () => {
        const el = document.getElementById(id);
        if (el) {
            el.classList.toggle("show");
        }
    }

    const classToggleShow = () => {
        // 取得DOM上所有的ans class
        var cls = document.querySelectorAll('.ANSWERS');
        if (schoolYear === false && semester === false) {
            cls = document.querySelectorAll('.fill .ans');
        }
        // 所有被取得的class統一做事
        if (cls) {
            for (let i = 0; i < cls.length; ++i) {
                let hasNext = hasClass(cls[i], "next")
                if (hasNext === true) {
                    cls[i].classList.remove("show")
                    cls[i].classList.remove("next")
                } else {
                    cls[i].classList.add("show")
                    cls[i].classList.add("next")
                }
            }
        }
    }

    const fontSizeChange = size => () => {
        let el = [];
        const topic = document.getElementsByClassName("topic");
        const wordSection = document.getElementsByClassName("word_section");
        const plainText = document.getElementsByClassName("plain_text");
        const fill = document.getElementsByClassName("fill");
        const imageSet = document.getElementsByClassName("imageSet");
        const simple = document.getElementsByClassName("simple");

        // 判斷從哪個題型進來
        if (topic.length > 0) {
            el = topic;
        } else if (wordSection.length > 0) {
            el = wordSection;
        } else if (plainText.length > 0) {
            el = plainText;
        } else if (fill.length > 0) {
            el = fill;
        } else if (imageSet.length > 0) {
            el = imageSet;
        } else if (simple.length > 0) {
            el = simple;
        }

        for (let i = 0; i < el.length; i++) {
            if (el) {
                // 移除 class
                if (hasClass(el[i], "fontLarge")) {
                    el[i].classList.remove("fontLarge");
                } else if (hasClass(el[i], "fontMedium")) {
                    el[i].classList.remove("fontMedium");
                } else if (hasClass(el[i], "fontSmall")) {
                    el[i].classList.remove("fontSmall");
                }

                // 根據 size 加入class
                // eslint-disable-next-line default-case
                switch (size) {
                    case "L":
                        el[i].classList.add("fontLarge");
                        break;
                    case "M":
                        el[i].classList.add("fontMedium");
                        break;
                    case "S":
                        el[i].classList.add("fontSmall");
                        break;
                }

            }
        }

    }

    return (
        <>
            {
                html || synopsisInfo ? (
                    <div className={styles.extendedContentButton}>
                        <div className={styles.left}>
                            <div className={styles.button} onClick={fontSizeChange("L")}>大</div>
                            <div className={styles.button} onClick={fontSizeChange("M")}>中</div>
                            <div className={styles.button} onClick={fontSizeChange("S")}>小</div>
                        </div>
                        <div className={styles.right}>
                            {schoolYear === false && semester === false && template.indexOf("='fill'") > -1 ? <div className={styles.button} onClick={classToggleShow}>解答</div> : ""}
                            {template.indexOf("ANSWERS") > -1 || isAnswers === true ? <div className={styles.button} onClick={classToggleShow}>解答</div> : ""}
                            {template.indexOf("='answer'") > -1 ? <div className={styles.button} onClick={toggleShow("answer")}>答案</div> : ""}
                            {template.indexOf("analyze") > -1 ? <div className={styles.button} onClick={toggleShow("analyze")}>解析</div> : ""}
                        </div>
                    </div>
                )
                    : <></>
            }
        </>
    )
}
