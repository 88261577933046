import React from 'react';
import VideoPlayer from 'components/common/VideoPlayer';
import styles from './index.module.scss';
import Repository from 'repositories/Repository';
const { ExtendedResourceRepository } = Repository;

export const VideoModalComponent = ({ bookId, videoInfo }) => {
  return (
    <div className={styles.videoModalComponent} key={bookId}>
      <VideoPlayer
        key="VideoPlayer"
        bookId={bookId}
        src={ExtendedResourceRepository.getVideoSrc({
          bookId,
          filename: videoInfo.src
        })}
        seekList={videoInfo.seekList}
        subtitles={videoInfo.subtitles
          .filter(subtitle => subtitle.src)
          .sort(() => -1)
          .map(subtitle => ({
            ...subtitle,
            src: ExtendedResourceRepository.getSubtitleSrc({
              bookId,
              filename: subtitle.src
            })
          }))}
        questionList={videoInfo.questionList}
        control
      />
    </div>
  );
};
