import { useCallback, useRef } from 'react';
import { useStore, StoreTypes } from 'context';
import * as types from 'constants/actionTypes';
import firebase from 'firebase/app';
import 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/database';
import "firebase/storage";

export const useFirebase = () => {
  const [, courseDispatch] = useStore(StoreTypes.course);
  const isInitialized = useRef(false);
  
  const initialize = useCallback((config) => {
    if (!config || isInitialized.current) return;
    let admin = firebase.apps.length > 0 ? firebase.apps.find(app => app.name === config.appName) : null

    if (!admin) {
      admin = firebase.initializeApp(config, config.appName);
    }

    admin.auth().signInAnonymously()
      .then(() => {
          isInitialized.current = true;
          courseDispatch({ type: types.SET_COURSE_FIRESTORE, firestore: admin })
      })
      .catch(err => console.error('sign in anonymously error', err));
  }, [courseDispatch])


  return { initialize };
}