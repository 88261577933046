import React, { useEffect,useRef, useState } from 'react'
import { useAudioVideo } from 'amazon-chime-sdk-component-library-react';
import classnames from 'classnames';
import styles from './index.module.scss';

const draw = (width, height, canvasCtx, volume, maxVolume) => {
    canvasCtx.clearRect(0, 0, width, height)
    let volumeStep=volume/20
    for (let i = 0; i < 5; i++) {
        if(i<volumeStep){
            canvasCtx.fillStyle = '#0daa13'
        }else{
            canvasCtx.fillStyle = '#dbdbdb'
        }
        const x = width * i / 5
        const y = height * 0.6 - height * i * 0.15
        canvasCtx.fillRect(x, y, width / 6, height - y)
    }
}

const VolumeMeter = React.memo((props) => {
    const { width, height, attendeeId, className } = props;
    const canvasRef = useRef()
    const audioVideo = useAudioVideo();
    const [volume,setVolume] = useState(0);

   useEffect(()=>{
    if(!attendeeId || !audioVideo) return;
    const volumeHandler = (
        attendeeId,
        volume,
        muted,
        signalStrength
    ) => {
        setVolume(Math.round(volume * 100))
    }
    audioVideo.realtimeSubscribeToVolumeIndicator(attendeeId, volumeHandler)
   },[attendeeId, audioVideo])

    useEffect(() => {
        if (!canvasRef.current) return;
        const canvasCtx = canvasRef.current.getContext('2d')
        canvasCtx && draw(width, height, canvasCtx, volume, 100)
    }, [canvasRef, width, height, volume])

    return (
        <div className={classnames(styles.volumeMeter,className)}>
            <canvas
                ref={canvasRef}
                width={width}
                height={height}
            />
        </div>
    )
})

export default VolumeMeter