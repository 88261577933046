import { useCallback } from 'react';
import { useStore, StoreTypes } from 'context';
import { ReaderToolType } from 'constants/ReaderTools';
import { BaseReaderStrategy } from './BaseReaderStrategy';

const onClassBlackTools = [
    ReaderToolType.Activity,
    ReaderToolType.ExportInteractiveObject,
    ReaderToolType.SVG,
    ReaderToolType.RightSideBar,
    ReaderToolType.NumberPicker,
    ReaderToolType.StickyNote,
    ReaderToolType.Mark,
    ReaderToolType.Bookshelf,
    ReaderToolType.PageMode,
    ReaderToolType.ResetLastAnnatation
];

const offClassBlackTools = [
    ReaderToolType.Activity,
    ReaderToolType.ExportInteractiveObject,
    ReaderToolType.SVG,
    ReaderToolType.RightSideBar,
    ReaderToolType.NumberPicker,
    ReaderToolType.StickyNote,
    ReaderToolType.Mark,
    ReaderToolType.Bookshelf,
    ReaderToolType.PageMode
];

export const useOneTutorTeacherStrategy = () => {
    const [{ finishedBefore }] = useStore(StoreTypes.course);
    const [{ isActive }] = useStore(StoreTypes.canvas);

    const getReaderToolComponents = useCallback((readerToolComponents) => {
        return BaseReaderStrategy.getFilteredReaderToolComponents(readerToolComponents, finishedBefore > 0 ? offClassBlackTools : onClassBlackTools);
    }, [finishedBefore]);

    const isReaderSwipeable = useCallback(() => {
        return !isActive;
    }, [isActive]);

    const isReaderSwitchPageAvailable = useCallback(() => {
        return true;
    }, []);

    const syncAnnotation = useCallback((id) => {
        
    }, []);

    const isInteractiveObjectTriggerable = useCallback(({ contentType }) => {
        return true;
    }, []);

    return {
        name: 'OneTutorTeacherStrategy',
        getReaderToolComponents,
        isReaderSwipeable,
        isReaderSwitchPageAvailable,
        syncAnnotation,
        isInteractiveObjectTriggerable
    };
};
