import React, { useState, useRef, useCallback, useEffect } from 'react';
import styles from './index.module.scss';
import { useStore, StoreTypes } from 'context';
import './content.scss';
import classnames from 'classnames';
import MusicPlayer from 'components/common/MusicPlayer';
import Repository from 'repositories/Repository';
const { ExtendedResourceRepository } = Repository;

const ReadAloud = ({ src, readAloudInfo }) => {
  const [{ bookId }] = useStore(StoreTypes.books);
  const [trackList, setTrackList] = useState([]); // vtt清單
  const [activeCueTw, setActiveCue] = useState({
    id: '',
    text: ''
  }); // 單筆vtt
  const [scrollLeftCount, setScrollLeftCount] = useState();

  const trackListRef = useRef(null); // TODO: 抓dom，用於計算寬度
  const trackContentBoxRef = useRef(null); // TODO: 抓dom，用於計算寬度
  const trackContentRef = useRef(null); // TODO: 抓dom，用於計算寬度
  const playerRef = useRef(null); // TODO: 抓dom，用於計算寬度
  const [{ state, controls }, setMusicInfo] = useState({
    state: null
  }); // 控制音bar maybe

  useEffect(() => {
    if (!state || !activeCueTw || !activeCueTw.id) return;

    // 小視窗寬度
    const trackContentWidth = trackContentBoxRef.current && trackContentBoxRef.current.clientWidth
    // 當句寬度
    const list = document.getElementById('track' + activeCueTw.id);
    const listWidth = activeCueTw && activeCueTw.id && list && list.clientWidth;
    // 當句寬度總和
    const contentWidth = trackContentRef.current.clientWidth;

    let scrollLeft = 0
    if (trackList.length > 0 && document.getElementById('track' + parseInt(trackList[0].id))) {
      for (let i = 0; i < trackList.length; i++) {
        scrollLeft += document.getElementById(
          'track' + parseInt(trackList[i].id)
        ).clientWidth;
        if (activeCueTw.id === trackList[i].id) break;
      }
    }

    if (state.isPlaying) {
      // 設定捲軸公式 ＝ (當句寬度總和 - 小視窗寬度 / 2) - 當句寬度累加 + 當句寬度 / 2
      setScrollLeftCount((contentWidth - trackContentWidth / 2) - scrollLeft + listWidth / 2)
    }
  }, [activeCueTw, state, trackList])

  useEffect(() => {
    trackContentBoxRef && (trackContentBoxRef.current.scrollTo(scrollLeftCount, 0))
  }, [scrollLeftCount])

  const musicPlayerOnLoadHandler = useCallback(({ audio, state, controls, ref, activeCue, cues }) => {
    const activedCue = { ...{ id: '', text: '' }, ...activeCue['tw'] }
    activedCue && activedCue.id && activedCue.text && setActiveCue(activedCue);
    setTrackList(cues['tw'] ? cues['tw'] : []);
    setMusicInfo({ audio, state, controls, ref });
  },[]);

  const musicPlayerStopHandler = useCallback(() => {
    trackContentBoxRef.current.scrollLeft = trackContentRef.current.clientWidth;
    state.isPlaying = false;
    controls.seek(trackList[0].startTime);
  },[controls, state.isPlaying, trackList])

  return (
    <div className={styles.readAloud}>
      <div className={styles.listContent} ref={el => (trackContentBoxRef.current = el)}>
        <div className={styles.content} ref={el => (trackContentRef.current = el)}>
          {trackList.map((track, index) => {
            return (
              <div
                ref={el => (trackListRef.current = el)}
                key={index}
                id={'track' + track.id}
                className={classnames(
                  styles.track,
                  styles[track &&
                    activeCueTw.text === track.text &&
                    activeCueTw.id === track.id ? 'show' : null
                  ]
                )}
                onClick={() => {
                  controls.seek(track.startTime);
                  !state.isPlaying && controls.play();
                }}
              >
                <span className={styles.text} dangerouslySetInnerHTML={{ __html: track && track.text }}></span>
              </div>
            );
          })}
        </div>
      </div>
      <div className={styles.musicBar} ref={el => (playerRef.current = el)}>
        <MusicPlayer
          isShowAudioControlBar={true}
          src={ExtendedResourceRepository.getAudioSrc({
            bookId,
            filename: src
          })}
          subtitles={[
            {
              language: 'tw',
              src: ExtendedResourceRepository.getAudioVTTSrc({
                bookId,
                audioInfo: readAloudInfo
              })
            }
          ]}
          onLoad={musicPlayerOnLoadHandler}
          onStop={musicPlayerStopHandler}
          closeButton={false}
        />
      </div>
    </div>
  )
}

export default ReadAloud;